import { EmployeesKey } from '@/symbols';
import { ReconciliationTypes, ReconciliationStatus, ReconciliationNames } from '../enum';
import { computed, inject, onMounted, reactive, ref, watch } from '@vue/runtime-core';
import type { Reconciliation } from './../types';
import { getMonths } from '@/utils';
import { reconciliationStore, type ReconciliationStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { ShortMonths } from '@/enums';
import cloneDeep from 'lodash.clonedeep';
import useCompany from '@/core/modules/company/composables/use-company';
let companyId: string | undefined;

export default function useReconciliation() {
	const store = reconciliationStore();
	const response = inject(ResponseKey);
	const { company } = useCompany();
	const owingSummaries = computed(() => store.AMOUNT_OWING_SUMMARY);
	const salaryOwingSummary = computed(() => store.AMOUNT_OWING_SUMMARY(ReconciliationTypes.SALARY_PAYMENT));
	const owingTable = ref<Reconciliation.OwingTable>();
	const selectedYear = ref<number>(new Date().getFullYear());
	const tableLabels = ref<string[]>([]);
	const employees = inject(EmployeesKey);
	const employeeIds = ref<string[]>([]);
	const owedEmployees = ref<number>(0);
	const reconciliationNames = ref<Reconciliation.ReconciliationAttributes[]>(
		Object.values(ReconciliationNames).map((name) => ({
			name,
			type: name == ReconciliationNames.SALARY ? ReconciliationTypes.SALARY_PAYMENT : ReconciliationTypes.TAX_PAYMENT
		}))
	);

	function setup() {
		store.STREAM_RECONCILIATION({ companyId: companyId! });
	}

	function getOwedEmployees() {
		if (employees?.value && employees.value.length > 0 && store.reconciliation && store.reconciliation.payments) {
			employeeIds.value = employees.value.map((e) => e.id!);
			owedEmployees.value = store.NUMBER_OF_OWED_EMPLOYEES(employeeIds.value);
		}
	}

	watch(
		() => employees?.value,
		() => getOwedEmployees()
	);

	watch(
		() => owingTable.value,
		() => {
			const owing = cloneDeep(owingTable.value);

			if (owing && Object.keys(owing).length > 0) {
				tableLabels.value = Object.keys(owing[Object.keys(owing)[0] as ShortMonths] as ReconciliationTypes);
			}
		}
	);
	watch(
		() => company?.value,
		() => {
			if (company.value) companyId = company?.value.id;
			setup();
		}
	);

	watch(
		() => [selectedYear.value, store.reconciliation],
		(newValue, oldValue) => {
			owingTable.value = store.AMOUNT_OWING_SUMMARY_BY_YEAR(selectedYear.value.toString());
			if (newValue[1] != oldValue[1]) {
				getOwedEmployees();
			}
		}
	);
	onMounted(() => {
		owingTable.value = store.AMOUNT_OWING_SUMMARY_BY_YEAR(selectedYear.value.toString());
		setup();
	});
	watch(
		() => store.$state.response,
		() => {
			switch (store.$state.response.status) {
				case 'LOADING':
					response?.loading();
					break;
				case 'SUCCESS':
					response?.success(store.$state.response.message);
					break;
				case 'ERROR':
					response?.error(store.$state.response.message);
					break;
				case 'EMPTY':
					response?.empty();
					break;
				case 'IDLE':
					response?.idle();
					break;
			}
		}
	);

	return {
		...createRecord(store),
		owedEmployees,
		owingSummaries,
		selectedYear,
		owingTable,
		tableLabels,
		salaryOwingSummary,
		reconciliationNames,
		store
	};
}

const initCreateReconciliationPayload: Reconciliation.CreatePayment = {
	amount: '',
	name: '',
	duration: {
		month: getMonths()[new Date().getMonth()].monthAbbr,
		year: new Date().getFullYear().toString()
	},
	companyId: '',
	employeeId: '',
	type: ReconciliationTypes.TAX_PAYMENT,
	status: ReconciliationStatus.OWING
};

function createRecord(store: ReconciliationStore) {
	const createForm = reactive<Reconciliation.CreatePayment>({ ...initCreateReconciliationPayload });

	async function submitRecord(e: Event) {
		e.preventDefault();
		try {
			createForm.companyId = companyId!;
			if (createForm.type == ReconciliationTypes.SALARY_PAYMENT && createForm.employeeId == '') {
				store.response = {
					status: 'ERROR',
					message: new Error('Please select an employee')
				};
				return;
			}

			if (createForm.amount == '' || parseFloat(createForm.amount.toString()) <= 0) {
				store.response = {
					status: 'ERROR',
					message: new Error('Please enter an amount')
				};
				return;
			}
			store.response = {
				status: 'LOADING'
			};
			await store.ADD_RECONCILIATION(createForm);
			store.response = {
				status: 'SUCCESS',
				message: 'Reconciliation created successfully'
			};
			initCreateReconciliationPayload.type = createForm.type;
			Object.assign(createForm, initCreateReconciliationPayload);
		} catch (error) {
			store.response = {
				status: 'ERROR',
				message: error
			};
		}
	}

	return {
		createForm,
		submitRecord
	};
}
