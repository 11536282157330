
import { defineComponent, provide } from 'vue';
import AddPerformance from '../modules/perfomance-contract/components/modal/AddPerformance.vue';
import EmploymentContractSummary from '../modules/employment-contract/components/EmploymentContractSummary.vue';
import PerfomanceContractSummary from '../modules/perfomance-contract/components/PerfomanceContractSummary.vue';
import useCompany from '@/core/modules/company/composables/use-company';
import { CompanyKey } from '@/symbols';
export default defineComponent({
	components: {
		AddPerformance,
		EmploymentContractSummary,
		PerfomanceContractSummary
	},
	setup() {
		const { company } = useCompany();
		provide(CompanyKey, company);
	}
});
