
import { defineComponent, inject, onMounted, watch } from 'vue';
import { usePayday } from '../composables/use-company';
import { dayText } from '../utils';
import { dayFormat } from '@/utils';
import { CompanyKey } from '@/symbols';
export default defineComponent({
	setup() {
		const payday = usePayday();
		const company = inject(CompanyKey);

		watch(
			() => company?.value,
			() => {
				payday.setPaydays();
			}
		);

		onMounted(() => {
			payday.setPaydays();
		});
		return {
			...payday,
			dayText,
			dayFormat
		};
	}
});
