<template>
	<div class="basic-info">
		<form @submit.prevent="submitBasicInfo" @keypress.enter="submitBasicInfo($event)" autocomplete="off">
			<div class="row">
				<div class="col-sm-6">
					<TextField :placeholder="'Name'" :controller="basicForm.name" v-model="basicForm.name" />
				</div>
				<div class="col-sm-6">
					<TextField :placeholder="'Surname'" :controller="basicForm.surname" v-model="basicForm.surname" />
				</div>
				<div class="col-sm-12">
					<TextField
						:placeholder="'Email'"
						:type="'email'"
						:controller="basicForm.email"
						v-model="basicForm.email"
					/>
				</div>
				<div class="col-sm-12">
					<TextField
						:placeholder="'Password'"
						:type="'password'"
						:controller="basicForm.password"
						v-model="basicForm.password"
					/>
				</div>
				<div class="col-sm-12">
					<TextField
						:placeholder="'Confirm Password'"
						:type="'password'"
						:controller="basicForm.confirmPassword"
						v-model="basicForm.confirmPassword"
					/>
				</div>
				<div class="col-sm-12 mt-4">
					<button type="submit" class="main-btn w3-right">Next Step</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import TextField from '@/components/TextField.vue';
import { inject } from '@vue/runtime-core';
import { SubmitBasicInfoKey, BasicInfoKey } from '../../symbols';
export default {
	components: {
		TextField
	},
	setup() {
		const basicForm = inject(BasicInfoKey, {
			name: '',
			surname: '',
			password: '',
			confirmPassword: ''
		});
		const submitBasicInfo = inject(SubmitBasicInfoKey);
		return {
			basicForm,
			submitBasicInfo
		};
	}
};
</script>
