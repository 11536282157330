
import { computed, defineComponent, ref } from 'vue';
import { Cropper as VueCropper, Preview, Coordinates, CropperResult, ImageTransforms } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import DefaultModal from './DefaultModal.vue';
import { DialogType } from '@/enums';
import ImagePreview from '../ImagePreview.vue';
export default defineComponent({
	components: { DefaultModal, VueCropper, Preview, ImagePreview },
	props: {
		image: {
			type: String,
			required: true
		},
		action: {
			type: Function,
			required: true
		}
	},

	setup(props) {
		const image = computed(() => props.image);
		let canvas = ref<Element>();
		const croppedImage = ref<string>('');
		const coordinates = ref<Coordinates>();
		const action = props.action;
		function change({ coordinates: c, canvas, image, visibleArea }: CropperResult) {
			croppedImage.value = canvas?.toDataURL()!;
			coordinates.value = visibleArea;
		}

		function handleUpload() {
			if (croppedImage.value) action(croppedImage.value);
		}
		return {
			image,
			DialogType,
			change,
			croppedImage,
			handleUpload,
			coordinates,
			canvas
		};
	}
});
