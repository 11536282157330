<template>
	<div id="add_employees">
		<div class="content">
			<BackButton />
			<div class="employees-steps">
				<StepIndicator
					v-for="(step, index) in steps"
					:stepName="step.title"
					:stepNumber="step.step"
					:key="index"
					:class="step.isActive ? 'active-step' : currentStep > index ? 'completed-step' : ''"
				/>
			</div>
			<div class="form-part">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
import StepIndicator from '@/core/modules/user/components/StepIndicators.vue';
import BackButton from '@/components/BackButton.vue';
import BasicInfo from '../components/BasicInfo.vue';
import { computed, defineComponent, provide } from '@vue/runtime-core';
import { useEmployee } from '../composables/use-employee';
import { RequestResponse } from '@/enums';
import { BasicInfoFormKey, EmploymentInfoKey, NextStepKey, RemunerationFormKey } from '../symbols';
import { useStore } from '@/store';
export default defineComponent({
	components: {
		StepIndicator,
		BackButton,
		BasicInfo
	},
	setup() {
		const { basicInfoForm, employmentInfoForm, remunerationForm, nextStep, step: currentStep, steps } = useEmployee();
		provide(BasicInfoFormKey, basicInfoForm);
		provide(EmploymentInfoKey, employmentInfoForm);
		provide(RemunerationFormKey, remunerationForm);
		provide(NextStepKey, nextStep);

		return {
			steps,
			currentStep,

			RequestResponse
		};
	}
});
</script>
