import CompanyRepository from '@/core/modules/company/repositories/company-repository';
import UserRepository from '@/core/modules/user/repositories/user-repository';
import { CompanyType, PerfomanceTaskType } from '@/types';
import EmployeeRepository from '../../employee/repositories/employee-repository';
import PerfomanceTask from '../models/perfomance-task';
import { PerfomanceContractMutations } from '../types/store-type';
const userRepository = new UserRepository();
const companyRepository = new CompanyRepository();
const employeeRepository = new EmployeeRepository();

export const addPerfomanceTask = async (_: any, form: PerfomanceTask) => {
	try {
		const companyId = await userRepository.getCurrentUserCompanyId();
		await companyRepository.addPerfomanceTask(companyId, form);
	} catch (error) {
		throw error;
	}
};

export const getPerfomanceTasks = async ({ commit }: any, payload: CompanyType) => {
	try {
		let perfomanceTasks: Array<PerfomanceTaskType> | undefined = payload.perfomanceTasks;
		commit(PerfomanceContractMutations.SET_PERFOMANCE_TASKS, perfomanceTasks);
	} catch (error) {
		throw error;
	}
};

export const selectPerfomanceTask = ({ commit }: any, task: PerfomanceTaskType) => {
	commit(PerfomanceContractMutations.SELECT_PERFOMANCE_TASK, task);
};

export const updateTasks = async (
	_: any,
	{ employeeId, tasks }: { employeeId: string; tasks: PerfomanceTaskType[] }
) => {
	return await employeeRepository.updateEmployeeTasks(employeeId, tasks);
};
