import { EmployeeType, IStateStatus } from '@/types';

export type State = {
	employee: EmployeeType | null;
	employees: Array<EmployeeType>;
} & IStateStatus;

export const state: State = {
	status: 'LOADING',
	employee: null,
	employees: []
};
