import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { PerfomanceTaskType } from '@/types';
import { computed, inject, reactive } from 'vue';

const usePerfomanceTask = () => {
	const store = useStore();
	const perfomanceTasks = computed(() => store.state.perfomanceContract.perfomanceTasks);
	const form = reactive<PerfomanceTaskType>({
		name: ''
	});
	const response = inject(ResponseKey);
	async function submitForm() {
		try {
			if (form.name == null) return;
			let taskAlreadyExists: boolean = !perfomanceTasks.value
				? false
				: perfomanceTasks.value.filter((task) => {
						let oldTaskName = task.name?.trim();
						let newTaskName = form.name?.trim();

						return (
							oldTaskName == newTaskName || oldTaskName?.toLocaleLowerCase() == newTaskName?.toLocaleLowerCase()
						);
				  }).length > 0;
			if (taskAlreadyExists) {
				response?.error(new Error('Task already exists'));
				return;
			}
			response?.loading();
			await store.dispatch('perfomanceContract/addPerfomanceTask', form);
			form.name = undefined;
			response?.success('Task added successfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}
	return {
		form,
		submitForm
	};
};

export default usePerfomanceTask;
