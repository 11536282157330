<template>
	<div class="section-paragraph">
		<p :contenteditable="contentEditable" :data-placeholder="placeholder" @change.prevent="handleChange">
			{{ sectionParagraph }}
		</p>
	</div>
</template>
<script>
import { computed, ref } from 'vue';
export default {
	props: {
		contentEditable: {
			type: Boolean,
			default: true
		},
		sectionParagraph: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: 'Start typing here...'
		}
	},

	setup(props) {
		const sectionParagraph = computed(() => props.sectionParagraph);
		const placeholder = ref(props.placeholder);
		const contentEditable = ref(props.contentEditable);
		function handleChange(e) {}
		return {
			sectionParagraph,
			placeholder,
			contentEditable,
			handleChange
		};
	}
};
</script>
