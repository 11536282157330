import { EmployeeType, UserType } from '@/types';
import { BaseLocalStorageRepository } from './abstract/base-local-storage-repository';

export class LocalStorageRepository extends BaseLocalStorageRepository {
	storeData(key: string, data: UserType | EmployeeType): void {
		localStorage.setItem(key, JSON.stringify(data));
	}
	getData(key: string): UserType | EmployeeType | null {
		let data: any = localStorage.getItem(key);
		return data ? JSON.parse(data) : null;
	}

	deleteStorage(key: string): void {
		localStorage.removeItem(key);
	}
}
