
import { CompanyKey } from '@/symbols';
import { defineComponent, inject } from '@vue/runtime-core';
import EditDetails from '../components/modals/EditCompanyDetail.vue';

export default defineComponent({
	components: {
		EditDetails
	},
	setup() {
		const company = inject(CompanyKey);
		return {
			company
		};
	}
});
