
import { defineComponent, provide } from 'vue';
import WorkingHours from '../components/WorkingHours.vue';
import PayDays from '../components/PayDays.vue';
import LunchTimes from '../components/LunchTimes.vue';
import TeaTimes from '../components/TeaTimes.vue';
import { useCompanyTimes } from '../composables/use-company';
import { SaveTimesKey, WorkingHoursFormKey } from '../symbols';
export default defineComponent({
	components: {
		WorkingHours,
		PayDays,
		LunchTimes,
		TeaTimes
	},
	setup() {
		const { workingHoursForm, saveTimes } = useCompanyTimes();
		provide(WorkingHoursFormKey, workingHoursForm);
		provide(SaveTimesKey, saveTimes);
	}
});
