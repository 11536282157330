
import { defineComponent, inject } from 'vue';
import EmploymentContractCard from './EmploymentContractCard.vue';
import { CompanyKey } from '@/symbols';
export default defineComponent({
	components: { EmploymentContractCard },
	setup() {
		const company = inject(CompanyKey);
		return {
			company
		};
	}
});
