import router from '@/router';
import { State } from './state';

export const activePaydays = (state: State) => {
	if (state.company && state.company.paydays && state.company.paydays.length > 0)
		return state.company.paydays.filter((day) => day.isActive);
};

export const bills = (state: State) => {
	return state.company?.payments ?? [];
};

export const bill = (state: State) => {
	const paymentId = router.currentRoute.value.params['paymentId'];

	if (state.company && state.company.payments && state.company.payments.length > 0) {
		let p = state.company.payments.filter((payment) => payment.paymentId == paymentId);
		return p.length > 0 ? p[0] : null;
	}
	return null;
};
