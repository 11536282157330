import { LocalStorageKeys, UserRole } from '@/enums';
import { LocalStorageRepository } from '@/repositories/local-storage-repository';
import { UserType } from '@/types';
import { User } from '@firebase/auth';
import { DocumentData, DocumentSnapshot } from '@firebase/firestore';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecord } from 'vue-router';
import { getUserState } from '../core/modules/user/composables/use-auth';
import UserRepository from '../core/modules/user/repositories/user-repository';
const userRepository = new UserRepository();

let isAuthenticated: User | null;

enum RouteNames {
	REGISTER = 'Register',
	LOGIN = 'Login',
	REGISTER_COMPANY_INFO = 'RegisterCompanyInfo',
	REGISTER_BASIC_INFO = 'RegisterBasicInfo',
	REVIEW_REG = 'ReviewReg',
	REGISTER_PAYMENT = 'RegisterPayment',
	DASHBOARD = 'HomeScreen',
	EMPLOYEE_DASHBOARD = 'EmployeeDashboard'
}

const beforeEachGuard = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	let title: string | undefined = to.meta.title ? to.meta.title : to.name?.toString();
	document.title =
		(process.env.NODE_ENV == 'development' || process.env.VUE_APP_VARIANT == 'DEV'
			? title + `[${process.env.VUE_APP_VARIANT}]`
			: title) ?? 'Uknown page';
	const requiresAuth = to.matched.some((record: RouteRecord) => record.meta.requiresAuth);

	isAuthenticated = await getUserState();

	if (requiresAuth && !isAuthenticated) {
		next({ name: RouteNames.LOGIN });
	} else if (isAuthenticated) {
		if (from.path != to.path) nextRoute(to, next);
		else !requiresAuth ? next({ name: RouteNames.DASHBOARD }) : next();
	} else {
		next();
	}
};

const beforeResolveGuard = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	var user: UserType | null = await getUser();
	const requiresAuth = to.matched.some((record: RouteRecord) => record.meta.requiresAuth);

	if (requiresAuth && !isAuthenticated) {
		next({ name: RouteNames.LOGIN });
	} else if (isAuthenticated) {
		if (!requiresAuth && user) {
			if (user.role == UserRole.COMPANY_ADMIN) nextRoute(to, next);
			else if (user.role == UserRole.MANAGER || user.role == UserRole.STAFF || user.role == UserRole.SUPERVISOR)
				next({ name: RouteNames.EMPLOYEE_DASHBOARD });
		} else {
			next();
		}
	} else {
		next();
	}
};

async function getUser() {
	let localStorage = new LocalStorageRepository();
	var user: UserType | null = localStorage.getData(LocalStorageKeys.LOCAL_USER);
	if ((!user || !user.role) && isAuthenticated) {
		let userDoc: DocumentSnapshot<DocumentData> = await userRepository.getUser(isAuthenticated?.uid!);
		if (userDoc.exists()) {
			user = {
				id: userDoc.id,
				...userDoc.data()
			};
			localStorage.storeData(LocalStorageKeys.LOCAL_USER, user!);
		}
	}
	return isAuthenticated ? user : null;
}

async function nextRoute(to: RouteLocationNormalized, next: NavigationGuardNext) {
	let user: UserType | null = await getUser();
	const requiresAuth = to.matched.some((value) => value.meta.requiresAuth);
	if (user) {
		switch (user.completion) {
			case 100:
				if (!requiresAuth) {
					next({
						name: user.role == UserRole.COMPANY_ADMIN ? RouteNames.DASHBOARD : RouteNames.EMPLOYEE_DASHBOARD
					});
				} else {
					next();
				}

				break;
			case 75:
				if (
					(to.name == RouteNames.LOGIN && !requiresAuth) ||
					(to.name != RouteNames.LOGIN && to.name != RouteNames.REGISTER_PAYMENT && requiresAuth)
				)
					next({ name: RouteNames.REGISTER_PAYMENT });
				else next();
				break;
			case 50:
				if (
					(to.name == RouteNames.LOGIN && !requiresAuth) ||
					(to.name != RouteNames.LOGIN && to.name != RouteNames.REVIEW_REG && requiresAuth)
				)
					next({ name: RouteNames.REVIEW_REG });
				else next();
				break;
			case 25:
				if (
					(to.name == RouteNames.LOGIN && !requiresAuth) ||
					(to.name != RouteNames.LOGIN && to.name != RouteNames.REGISTER_COMPANY_INFO && requiresAuth)
				)
					next({ name: RouteNames.REGISTER_BASIC_INFO });
				else next();
				break;
			default:
				next({ name: RouteNames.REGISTER });
		}
	} else {
		next({ name: RouteNames.REGISTER });
	}
}

const toUserRole = (role: string) => {
	switch (role) {
		case UserRole.ADMIN:
			return UserRole.ADMIN;
		case UserRole.COMPANY_ADMIN:
			return UserRole.COMPANY_ADMIN;
		case UserRole.MANAGER:
			return UserRole.MANAGER;
		case UserRole.SUPERVISOR:
			return UserRole.SUPERVISOR;
		default:
			return UserRole.STAFF;
	}
};

export { beforeEachGuard, beforeResolveGuard };
