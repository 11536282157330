<template lang="">
        <div class="section-title">
                <h4>{{ sectionTitle }}</h4>
        </div>
</template>
<script>
import { ref } from 'vue';
export default {
        props: {
                sectionTitle: {
                        type: String,
                        required: true
                }
        },

        setup(props) {
                const sectionTitle = ref(props.sectionTitle);

                return {
                        sectionTitle
                };
        }
};
</script>
