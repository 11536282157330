import {
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	Firestore,
	getFirestore,
	Unsubscribe
} from 'firebase/firestore';
import { BillingModel } from 'kunokhar-acc-types';
import { genericConverter } from '@/utils/firebase-utils';

export abstract class BillingRepo {
	/** @property {CollectionReference<Partial<BillingModel>>} billingRef */
	protected billingRef: CollectionReference<BillingModel>;

	/** @property {Firestore} db */
	protected db: Firestore;

	readonly COLLECTION = 'billings';

	constructor() {
		this.db = getFirestore();
		this.billingRef = collection(this.db, this.COLLECTION).withConverter(genericConverter<BillingModel>());
	}

	docRef(billingId: string): DocumentReference<BillingModel> {
		return doc(this.db, this.COLLECTION, billingId).withConverter(genericConverter<BillingModel>());
	}
	/**
	 * Stream billings
	 *
	 * @param  {string} companyId
	 * @returns {Unsubscribe} Unsubscribe
	 */
	abstract streamBillings(companyId: string): Unsubscribe;

	/**
	 * Streams billing
	 * @param billingId
	 * @returns billing
	 */
	abstract streamBilling(billingId: string): Unsubscribe;
}
