<template>
    <div class="step-indicator">
        <span>{{stepNumber}}</span>
        <h4>{{stepName}}</h4>
    </div>
</template>
<script>
    import { ref } from 'vue'
    export default {
        props: {
            stepName: {
                type: String,
                required: true,
            },
            stepNumber: {
                type: Number,
                required: true,
            }
        },
        setup(props) {
            const stepName = ref(props.stepName)
            const stepNumber = ref(props.stepNumber)

            return {
                stepName,
                stepNumber,
            }
        }
    }
</script>