import { RequestResponse } from '@/enums';
import { UserType } from '@/types';
import { DocumentSnapshot, QuerySnapshot } from '@firebase/firestore';
import { State } from './state';

export const SET_USER = (state: State, snapshot: DocumentSnapshot) => {
	state.user = {
		id: snapshot.id,
		...snapshot.data()
	};
};

export const SET_USERS = (state: State, snapshot: QuerySnapshot) => {
	state.users = snapshot.docs.map((user) => {
		return {
			id: user.id,
			...user.data()
		};
	});
};

export const SET_USER_OF_LOCAL = (state: State, data: UserType | null) => {
	if (!data) state.response = RequestResponse.EMPTY;
	else if (data) state.response = RequestResponse.SUCCESS;
	state.user = data;
};

export const SET_CONTACT_PERSON = (state: State, snapshot: DocumentSnapshot) => {
	if (snapshot.exists()) {
		state.contactPerson = {
			id: snapshot.id,
			...snapshot.data()
		};
	}
};
