import {
	arrayUnion,
	arrayRemove,
	increment,
	QueryDocumentSnapshot,
	FirestoreDataConverter,
	PartialWithFieldValue
} from 'firebase/firestore';
export function addToArray<T>(args: T) {
	return arrayUnion(args);
}

export function removeFromArray<T>(args: T) {
	return arrayRemove(args);
}

export function incrementField(value: number) {
	return increment(value);
}

export function decrementField(value: number) {
	return increment(-value);
}

export const genericConverter = <T>(): FirestoreDataConverter<T> => ({
	toFirestore(data: PartialWithFieldValue<T>): Partial<T> {
		return data as Partial<T>;
	},
	fromFirestore(snapshot: QueryDocumentSnapshot): T {
		return { ...(snapshot.data() as T), id: snapshot.id };
	}
});
