import { LocalStorageKeys } from '@/enums';
import { UploadProfilePicModel } from '@/models';
import { LocalStorageRepository } from '@/repositories/local-storage-repository';
import { UserType } from '@/types';
import { DocumentData, DocumentSnapshot, QuerySnapshot } from '@firebase/firestore';
import UserRepository from '../repositories/user-repository';
import { UserMutations } from '../types/store-types';
const userRepository = new UserRepository();
const localStorageRepository = new LocalStorageRepository();

export const signIn = async (_: any, form: UserType) => await userRepository.signIn(form);

export const resetPassword = async (_: any, email: string) => await userRepository.resetPassword(email);

export const signUp = async (_: any, form: UserType) => await userRepository.signUp(form);

export const confirmDetails = async (_: any) => await userRepository.confirmDetails();

export const signOut = async () => {
	localStorageRepository.deleteStorage(LocalStorageKeys.LOCAL_USER);
	return await userRepository.signOut();
};

export const setUser = ({ commit }: any, snapshot: DocumentSnapshot) => {
	commit(UserMutations.SET_USER, snapshot);
};

export const setUsers = ({ commit }: any, docs: DocumentSnapshot[]) => {
	commit(UserMutations.SET_USER, docs);
};
export const streamUsers = ({ commit }: any) =>
	userRepository.streamUsers({
		next: (snapshot: QuerySnapshot<DocumentData>) => {
			commit(UserMutations.SET_USERS, snapshot);
		}
	});
export const mimicPayment = () => {
	return userRepository.mimiPayment();
};

export const storeUserLocal = async ({ commit }: any, data: { key: string; userId: string }) => {
	var user: UserType;
	if (data.userId) {
		const userData = await userRepository.getUser(data.userId);
		user = {
			id: userData.id,
			...userData.data()
		};
		localStorageRepository.storeData(data.key, user);
	}

	commit(UserMutations.SET_USER_OF_LOCAL, user!);
};

export const getUserLocal = ({ commit }: any, data: { key: string }) => {
	let user: UserType | null = localStorageRepository.getData(data.key);
	commit(UserMutations.SET_USER_OF_LOCAL, user);
};
export const clearLocalUser = () => {
	localStorageRepository.deleteStorage(LocalStorageKeys.LOCAL_USER);
};
export const streamUser = ({ commit }: any, userId: string) =>
	userRepository.streamUser(userId, {
		next: (snapshot) => {
			commit(UserMutations.SET_USER, snapshot);
			localStorageRepository.storeData(LocalStorageKeys.LOCAL_USER, { id: snapshot.id, ...snapshot.data() });
		},
		error: (e) => {}
	});

export const getContactPerson = ({ commit }: any, contactPersonId: string) => {
	return userRepository
		.getContactPerson(contactPersonId)
		.then((snapshot) => commit(UserMutations.SET_CONTACT_PERSON, snapshot));
};

export const uploadProfilePic = async (_: any, profile: UploadProfilePicModel) => {
	return await userRepository.uploadProfilePic(profile);
};
