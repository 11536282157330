import { RequestResponse } from '@/enums';
import { CommonState, IStateStatus, LeaveType } from '@/types';

export interface State extends CommonState {
	leave: LeaveType | null;
	leaves: Array<LeaveType>;
}

export const state: State = {
	leave: null,
	leaves: [],
	response: {
		status: RequestResponse.IDLE
	}
};
