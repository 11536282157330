
import { computed, defineComponent, PropType } from 'vue';
import { PerfomanceTaskType } from '@/types';

export default defineComponent({
	props: {
		task: {
			type: Object as PropType<PerfomanceTaskType>,
			required: true
		}
	},
	setup(props) {
		const task = computed(() => props.task);
		return { task };
	}
});
