import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "history-card" }
const _hoisted_2 = { class: "dates" }
const _hoisted_3 = { class: "leave-status" }
const _hoisted_4 = { class: "footer-part" }
const _hoisted_5 = { class: "leave-type" }
const _hoisted_6 = ["data-bs-target"]
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.Time.toDefaultString(_ctx.leave.duration?.start)) + " - " + _toDisplayString(_ctx.Time.toDefaultString(_ctx.leave.duration?.end)), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.totalDays == 1 ? `${_ctx.totalDays} day` : `${_ctx.totalDays} days`), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.leave.status?.toLowerCase())
      }, _toDisplayString(_ctx.leave.status), 3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.leave.type), 1)
      ]),
      (_ctx.leave.comment)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "view-reason",
            "data-bs-toggle": "collapse",
            "aria-expanded": "true",
            "aria-controls": "leave_reason",
            "data-bs-target": `#leave_reason__${_ctx.collapseKey}`
          }, " Leave Reason ", 8, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "collapse",
      id: `leave_reason__${_ctx.collapseKey}`
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.leave.comment), 1)
    ], 8, _hoisted_7)
  ]))
}