
import Cropper from '@/components/modal/Cropper.vue';
import { useStore } from '@/store';
import { CompanyKey, ResponseKey } from '@/symbols';
import { convertFileToBase64 } from '@/utils';
import { defineComponent, inject, onMounted, provide, watch } from '@vue/runtime-core';
import { ref } from 'vue';
import CompanyDetails from './CompanyDetails.vue';

import { CompanyActions } from '../types';
import { UserActions } from '../../user/types/store-types';
export default defineComponent({
	components: {
		CompanyDetails,
		Cropper
	},
	setup() {
		const image = ref<string>('');
		const store = useStore();
		const response = inject(ResponseKey);
		const company = inject(CompanyKey);
		function handleImage(e: any) {
			let file: File = e.target.files[0];
			convertFileToBase64(file, conversionString);
		}

		function conversionString(imageString: string) {
			image.value = imageString;
		}

		async function uploadImage(data: any) {
			try {
				response?.loading();
				await store.dispatch(CompanyActions.UPLOAD_LOGO, {
					imageString: data,
					progress: uploadProgress,
					status: uploadStatus,
					id: company?.value?.id
				});
			} catch (error) {
				response?.error(error as Error);
			}
		}

		function uploadProgress(progress: number) {}

		async function uploadStatus(success: boolean, data: string | null) {
			try {
				if (success) {
					await store.dispatch(CompanyActions.SAVE_LOGO_DOWNLOAD_LINK, {
						logo: data,
						companyId: company?.value?.id
					});
					response?.success('Company Logo updated');
					image.value = '';
				} else {
					throw new Error(data!);
				}
			} catch (error) {
				response?.error(error as Error);
			}
		}

		async function loadData() {
			if (company && company.value) {
				try {
					response?.loading();
					await store.dispatch(UserActions.GET_CONTACT_PERSON, company.value.createdBy);
					response?.success();
				} catch (error) {
					response?.error(error as Error);
				}
			}
		}
		watch(
			() => company?.value,
			() => loadData()
		);
		onMounted(() => loadData());
		return {
			company,
			handleImage,
			uploadImage,
			image
		};
	}
});
