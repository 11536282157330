import { actions, type Actions } from './actions';
import { state, type State } from './state';
import { type Getters, getters } from './getters';
import { defineStore } from 'pinia';

const reconciliationStore = defineStore<'reconciliation', State, Getters, Actions>('reconciliation', {
	state: () => state,
	actions,
	getters
});

type ReconciliationStore = ReturnType<typeof reconciliationStore>;

export { reconciliationStore, type ReconciliationStore };
