import { FirebaseStream } from './../../../../../models/firestore-stream.model';
import { RequestResponse, RequestStatus } from '@/enums';
import { EmployeeType, LeaveType, OnDocumentObserver, OnQueryObserver } from '@/types';
import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentReference,
	Firestore,
	getFirestore,
	QueryDocumentSnapshot,
	QuerySnapshot,
	Unsubscribe
} from 'firebase/firestore';
import EmployeeRepository from '../../employee/repositories/employee-repository';
import { leaveStore, LeaveStore } from '../store';
import { LeaveConstraints } from '../types';

export default abstract class LeaveRepo extends FirebaseStream<LeaveType> {
	protected db: Firestore;
	protected leavesRef: CollectionReference<LeaveType>;
	protected employeeRepo: EmployeeRepository;
	constructor() {
		super();
		this.db = getFirestore();
		this.leavesRef = collection(this.db, 'leaves');
		this.employeeRepo = new EmployeeRepository();
	}

	queryObserver(employeeId?: string): OnQueryObserver {
		const store = leaveStore();
		return {
			next: async (snapshot) => {
				if (!employeeId) {
					let leaves: Array<LeaveType> = [];
					let employees: Array<EmployeeType> = [];
					let docs = snapshot.docs;
					if (docs.length > 0) {
						for (let index = 0; index < docs.length; index++) {
							let leave: LeaveType = {
								id: docs[index].id,
								...docs[index].data()
							};
							let user = employees.filter((u) => u.id == leave.employeeId);

							if (user.length > 0) {
								leave.employee = user[0];
							} else {
								let u = await this.employeeRepo.getEmployee(leave.employeeId!);
								let emp: EmployeeType = {
									id: u.id,
									...u.data()
								};
								leave.employee = emp;
								employees.push(emp);
							}
							leaves.push(leave);
						}
						store.response = {
							status: RequestResponse.SUCCESS
						};
					} else {
						store.response = {
							status: RequestResponse.EMPTY
						};
					}
					store.leaves = leaves;
				} else {
					if (snapshot.docs.length > 0) {
						store.response = {
							status: RequestResponse.SUCCESS
						};
					} else {
						store.response = {
							status: RequestResponse.EMPTY
						};
					}
					store.leaves = this.fromListFirestore(snapshot);
				}
			},
			error: (error) => {
				store.response = {
					status: RequestResponse.ERROR,
					message: error.message
				};
			}
		};
	}

	documentObserver(): OnDocumentObserver {
		const store = leaveStore();
		return {
			next: (snapshot) => {
				if (snapshot.exists()) {
					store.response = {
						status: RequestResponse.SUCCESS
					};
				} else {
					store.response = {
						status: RequestResponse.EMPTY
					};
				}
				store.leave = this.fromDocumentFirestore(snapshot);
			},
			error: (error) => {
				store.response = {
					status: RequestResponse.ERROR,
					message: error.message
				};
			}
		};
	}
	protected leavesDocRef = (id: string): DocumentReference<LeaveType> => doc(this.db, 'leaves', id);
	abstract createLeave(leave: LeaveType): Promise<any>;
	abstract requestLeave(leave: LeaveType): Promise<any>;
	abstract respondToRequest(id: string, status: RequestStatus, comment?: string): Promise<any>;
	abstract streamLeaves(constraints: LeaveConstraints): Unsubscribe;
	abstract streamLeave(id: string): Unsubscribe;
	abstract approveLeave(id: string): Promise<void>;
	abstract declineLeave(id: string, comment: string): Promise<void>;
}
