import { useStore } from '@/store';
import { CompanyKey, ResponseKey } from '@/symbols';
import { CompanyTimes, DurationTime } from '@/types';
import { Unsubscribe } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { computed, inject, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { reAuthenticate } from '../../user/composables/use-auth';
import { CompanyActions } from '../types';
import { defaultPaydays, defaultWorkingHours, orderPaydays, payDaysGenerator } from '../utils';

export default function useCompany() {
	const store = useStore();
	const company = computed(() => store.state.company.company);
	const { workingHoursForm } = useCompanyTimes();
	const user = computed(() => store.state.user.user);
	let unsubscribe: Unsubscribe;
	const response = inject(ResponseKey);

	onMounted(async () => {
		try {
			reAuthenticate();
			// const user = await getUserState();
			// const {
			//         companyId
			// } = ((await user?.getIdTokenResult()) as IdTokenResult).claims;
			//
			//         ((await user?.getIdTokenResult()) as IdTokenResult)
			//                 .claims
			// );
			response?.loading();
			unsubscribe = await store.dispatch(CompanyActions.STREAM_COMPANY, user.value?.companyId);
			setTimeout(() => {
				response?.success();
			}, 1000);
		} catch (error) {
			response?.error(error as Error);
		}
	});

	function cancelSubscription() {
		if (typeof unsubscribe == 'function') unsubscribe();
	}

	onUnmounted(() => {
		cancelSubscription();
	});
	return {
		company,
		cancelSubscription
	};
}

export const useCompanyTimes = () => {
	const workingHoursForm = reactive<Array<CompanyTimes.WorkingHours>>(defaultWorkingHours());
	const company = inject(CompanyKey);
	const response = inject(ResponseKey);
	const store = useStore();
	async function saveTimes(companyId: number) {
		try {
			response?.loading();
			await store.dispatch(CompanyActions.SAVE_WORKING_HOURS, {
				companyId,
				hoursOfWork: cloneDeep(workingHoursForm)
			});
			response?.success('Working hours saved');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	onMounted(() => {
		if (company && company.value) {
			let workingHours: CompanyTimes.WorkingHours[] = company.value.hoursOfWork as CompanyTimes.WorkingHours[];
			for (let index = 0; index < workingHours.length; index++) {
				workingHoursForm[index] = workingHours[index];
			}
		}
	});

	return { workingHoursForm, saveTimes };
};

export const usePayday = () => {
	const paydayForm = reactive<CompanyTimes.PayDay>({ isActive: true, day: '' });
	const generatedDays = ref(payDaysGenerator());
	const selectableDays = computed(() => generatedDays.value);
	const days = ref<Array<CompanyTimes.PayDay>>(defaultPaydays());
	const paydays = computed(() => days.value);
	const company = inject(CompanyKey);
	const addedDays = ref<CompanyTimes.PayDay[]>([]);
	const response = inject(ResponseKey);
	const store = useStore();

	function addDay() {
		days.value.push(cloneDeep(paydayForm));
		generatedDays.value = generatedDays.value.filter((day) => day != paydayForm.day);
		addedDays.value.push(cloneDeep(paydayForm));
		let orderedPaydays = orderPaydays(days.value);
		days.value = orderedPaydays == null ? days.value : orderedPaydays;

		paydayForm.day = '';
	}

	function setPaydays() {
		if (company && company.value) {
			if (company.value.paydays && company.value.paydays.length > 0) {
				let orderedPaydays = orderPaydays(company.value.paydays);
				days.value = orderedPaydays == null ? company.value.paydays : orderedPaydays;
			}
		}
	}

	async function saveAddedDays() {
		try {
			response?.loading();
			await store.dispatch(CompanyActions.SAVE_PAYDAYS, {
				companyId: company?.value.id,
				paydays: cloneDeep(paydays.value)
			});
			response?.success('Payday changes saved 🚀🚀🚀');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	async function checkPayday(index: number) {
		days.value[index].isActive = !days.value[index].isActive;
	}

	onMounted(() => {
		if (company && company.value) {
		}
	});
	return {
		paydayForm,
		paydays,
		selectableDays,
		addDay,
		saveAddedDays,
		setPaydays,
		checkPayday
	};
};

export const useLunchTimes = () => {
	const form = reactive<DurationTime>({ start: '', end: '' });
	const response = inject(ResponseKey);
	const times = ref<DurationTime[]>([]);
	const lunchTimes = computed(() => times.value);
	const company = inject(CompanyKey);
	const store = useStore();

	watch(
		() => company?.value,
		() => {
			setLunchTimes();
		}
	);

	async function saveLunchTime() {
		try {
			let alreadyExists: boolean =
				times.value.filter((time) => form.start == time.start && form.end == time.end).length > 0;

			if (!form.end || !form.start) throw new Error('Please select the duration of lunch');

			if (alreadyExists) throw new Error('This duration is already stored');
			response?.loading();
			times.value.push(cloneDeep(form));
			await store.dispatch(CompanyActions.SAVE_LUNCH_TIMES, {
				companyId: company?.value.id,
				lunchTimes: times.value
			});
			response?.success('Duration added succesfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	function setLunchTimes() {
		if (company && company.value && company.value.lunchTimes && company.value.lunchTimes.length > 0) {
			times.value = company.value.lunchTimes;
		}
	}

	onMounted(() => {
		setLunchTimes();
	});
	return {
		form,
		lunchTimes,
		saveLunchTime
	};
};
export const useTeaTimes = () => {
	const form = reactive<DurationTime>({ start: '', end: '' });
	const response = inject(ResponseKey);
	const times = ref<DurationTime[]>([]);
	const teaTimes = computed(() => times.value);
	const company = inject(CompanyKey);
	const store = useStore();

	watch(
		() => company?.value,
		() => {
			setTeaTimes();
		}
	);

	async function saveTeaTime() {
		try {
			let alreadyExists: boolean =
				times.value.filter((time) => form.start == time.start && form.end == time.end).length > 0;

			if (!form.end || !form.start) throw new Error('Please select the duration of lunch');

			if (alreadyExists) throw new Error('This duration is already stored');
			response?.loading();
			times.value.push(cloneDeep(form));
			await store.dispatch(CompanyActions.SAVE_TEA_TIMES, {
				companyId: company?.value.id,
				teaTimes: times.value
			});
			response?.success('Duration added succesfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	function setTeaTimes() {
		if (company && company.value && company.value.teaTimes && company.value.teaTimes.length > 0) {
			times.value = company.value.teaTimes;
		}
	}

	onMounted(() => {
		setTeaTimes();
	});
	return {
		form,
		teaTimes,
		saveTeaTime
	};
};
