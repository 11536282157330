import { EmployeeType, OnDocumentObserver, OnQueryObserver } from '@/types';
import { DocumentSnapshot, Unsubscribe } from 'firebase/firestore';

export default abstract class BaseEmployeeRepository {
	abstract addEmployeeBasicInfo(form: EmployeeType): Promise<string | Error>;
	abstract addEmployeeEmploymentInfo(form: EmployeeType): Promise<void>;
	abstract addEmployeeRemunerationInfo(form: EmployeeType): Promise<void>;
	abstract updateEmployee(): Promise<void>;
	abstract updateJobData(payload: EmployeeType): Promise<void>;
	abstract updatedAccruedLeave(employeeId: string): Promise<void>;
	abstract deleteEmployee(id: string): Promise<void>;
	abstract getEmployee(id: string): Promise<DocumentSnapshot>;
	abstract streamEmployees(companyId: string, observer: OnQueryObserver): Unsubscribe;
	abstract streamEmployee(employeeId: string, observer: OnDocumentObserver): Unsubscribe;
}
