import { ReconciliationTypes } from './../enum/index';
import modalText from '../data/modal_text.json';
import { ReconciliationStatus } from '../enum';
export class ReconcileModalModel {
	title?: string;
	heading?: string;
	description?: string;

	static fromJson(
		button: ReconciliationStatus,
		type: ReconciliationTypes = ReconciliationTypes.TAX_PAYMENT
	): ReconcileModalModel {
		return modalText[type][button];
	}
}
