import Billing from '@/core/modules/billing/views/Billing.vue';
import ViewInvoice from '@/core/modules/billing/views/ViewInvoice.vue';
import CompanyContact from '@/core/modules/company/views/CompanyContact.vue';
import CompanyDetails from '@/core/modules/company/views/CompanyDetails.vue';
import CompanyInfo from '@/core/modules/company/views/CompanyInfo.vue';
import CompanyTimes from '@/core/modules/company/views/CompanyTimes.vue';
import { UserRole } from '@/enums';
import { RouteRecordRaw } from 'vue-router';
import Clock from '../modules/clock/views/Clock.vue';
import EmployeeClocking from '../modules/clock/views/EmployeeClocking.vue';
import BasicInfo from '../modules/employee/components/BasicInfo.vue';
import EmploymentInfo from '../modules/employee/components/EmploymentInfo.vue';
import RemunerationInfo from '../modules/employee/components/Remuneration.vue';
import AddEmployee from '../modules/employee/views/AddEmployee.vue';
import Employees from '../modules/employee/views/Employees.vue';
import ViewSingleEmployee from '../modules/employee/views/ViewEmployee.vue';
import EmploymentContract from '../modules/employment-contract/views/EmploymentContract.vue';
import EmployeeLeaves from '../modules/leave/views/EmployeeLeave.vue';
import ViewLeaves from '../modules/leave/views/Leave.vue';
import PayslipHome from '../modules/payslip/views/EmployeePayslip.vue';
import ViewPayslip from '../modules/payslip/views/Payslip.vue';
import Contracts from '../views/Contracts.vue';
import Dashboard from '../views/Dashboard.vue';
import EmployeeDashboard from '../views/EmployeeHome.vue';
import DashboardHome from '../views/Home.vue';
import Reconciliation from '../modules/reconciliation/views/Reconciliation.vue';

const routes: RouteRecordRaw[] = [
	{
		path: '/payroll-accounting',
		name: 'PayrollAccounting',
		component: Dashboard,
		meta: {
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'HomeScreen',
				component: DashboardHome,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Dashboard'
				}
			},
			{
				path: 'contracts',
				name: 'Contracts',
				component: Contracts,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Contracts'
				}
			},
			{
				path: 'company',
				name: 'Company',
				component: CompanyInfo,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Company Info'
				},
				children: [
					{
						path: 'info',
						name: 'CompanyInfo',
						component: CompanyDetails,
						meta: {
							requiresAuth: true,
							authorizedRoles: [UserRole.COMPANY_ADMIN],
							title: 'Company Info'
						}
					},
					{
						path: 'contact-person',
						name: 'CompanyContactPerson',
						component: CompanyContact,
						meta: {
							requiresAuth: true,
							authorizedRoles: [UserRole.COMPANY_ADMIN],
							title: 'Company Contact Person'
						}
					}
				]
			},
			{
				path: 'employees',
				name: 'Employees',
				component: Employees,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Employees'
				}
			},
			{
				path: 'company-times',
				name: 'CompanyTimes',
				component: CompanyTimes,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Company Times'
				}
			},
			{
				path: 'leaves',
				name: 'Leaves',
				component: ViewLeaves,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Leaves'
				}
			},
			{
				path: 'clock',
				name: 'Clockings',
				component: Clock,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Clockings'
				}
			},
			{
				path: 'billing',
				name: 'Billing',
				component: Billing,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Billing Information'
				}
			},
			{
				path: 'dashboard',
				name: 'EmployeeDashboard',
				component: EmployeeDashboard,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.MANAGER, UserRole.STAFF, UserRole.SUPERVISOR],
					title: 'Dashboard'
				}
			},
			{
				path: 'payslip-home',
				name: 'PayslipHome',
				component: PayslipHome,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.MANAGER, UserRole.STAFF, UserRole.SUPERVISOR],
					title: 'Payslip Home'
				}
			},
			{
				path: 'employee-clocking',
				name: 'EmployeeClocking',
				component: EmployeeClocking,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.MANAGER, UserRole.STAFF, UserRole.SUPERVISOR],
					title: 'Employee Clocking'
				}
			},
			{
				path: 'employee-leaves',
				name: 'EmployeeLeavePage',
				component: EmployeeLeaves,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.MANAGER, UserRole.STAFF, UserRole.SUPERVISOR],
					title: 'Employee Leaves'
				}
			},
			{
				path: 'reconcile',
				name: 'ReconcilePage',
				component: Reconciliation,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Reconciliation'
				}
			}
		]
	},
	{
		path: '/employment-contract',
		name: 'EmplomentContract',
		component: EmploymentContract,
		meta: {
			requiresAuth: true,
			authorizedRoles: [UserRole.COMPANY_ADMIN],
			title: 'Employment Contract'
		}
	},
	{
		path: '/view-invoice/:paymentId',
		name: 'ViewingInvoice',
		component: ViewInvoice,
		meta: {
			requiresAuth: true,
			authorizedRoles: [UserRole.COMPANY_ADMIN],
			title: 'View Invoice'
		}
	},
	{
		path: '/add-employee',
		name: 'AddEmployee',
		component: AddEmployee,
		meta: {
			requiresAuth: true,
			authorizedRoles: [UserRole.COMPANY_ADMIN],
			title: 'Add Employee'
		},
		children: [
			{
				path: 'basic-info',
				name: 'EmployeeBasicInfo',
				component: BasicInfo,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Employee Basic Info'
				}
			},
			{
				path: 'employment-info/:employeeId',
				name: 'EmployeeEmploymentInfo',
				component: EmploymentInfo,

				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Employee Employment Info'
				}
			},
			{
				path: 'remuneration-info/:employeeId',
				name: 'EmployeeRemunerationInfo',
				component: RemunerationInfo,
				meta: {
					requiresAuth: true,
					authorizedRoles: [UserRole.COMPANY_ADMIN],
					title: 'Employee Remuneration Info'
				}
			}
		]
	},
	{
		path: '/viewing-employee/:employeeId',
		name: 'ViewSingleEmployee',
		component: ViewSingleEmployee,
		meta: {
			requiresAuth: true,
			authorizedRoles: [UserRole.COMPANY_ADMIN],
			title: 'Viewing Single Employee'
		}
	},
	{
		path: '/view-payslip/:employeeId',
		name: 'EmployeePayslip',
		component: ViewPayslip,
		props: true,
		meta: {
			requiresAuth: true,
			title: 'Payslip'
		}
	}
];

export default routes;
