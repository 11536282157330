import router from './routes';
import { store as clock, State as ClockState } from './modules/clock/store';
import { store as employmentContract, State as EmploymentContractState } from './modules/employment-contract/store';
import { store as perfomanceContract, State as PerfomanceContractState } from './modules/perfomance-contract/store';
import { store as payslip, State as PayslipState } from './modules/payslip/store';
import { store as employee, State as EmployeeState } from './modules/employee/store';

export const payrollAccountingModules = {
	clock,
	employmentContract,
	perfomanceContract,
	payslip,
	employee
};

export type { ClockState, EmploymentContractState, PerfomanceContractState, PayslipState, EmployeeState };
export { router };
