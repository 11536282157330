import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { CompanyType, UserType } from '@/types';
import { inject, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { CompanyActions } from '../../company/types';
import { useAuth } from './use-auth';

export default function useRegister() {
	const store = useStore();
	const { user } = useAuth();
	const router = useRouter();
	const basicForm = reactive<UserType>({
		name: '',
		surname: '',
		email: '',
		password: '',
		confirmPassword: ''
	});

	const companyForm = reactive<CompanyType>({
		name: '',
		address: '',
		emp201: '',
		uif: '',
		sdl: '',
		registrationNumber: '',
		vatNumber: '',
		numberOfEmployees: ''
	});
	const steps = ref([
		{
			name: 'Basic Info',
			step: 1,
			isActive: true,
			path: '/register'
		},
		{
			name: 'Company Info',
			step: 2,
			isActive: false,
			path: '/register/company-info'
		},
		{
			name: 'Review',
			step: 3,
			isActive: false,
			path: '/register/review-reg'
		},
		{
			name: 'Payment',
			step: 4,
			isActive: false,
			path: '/register/payment'
		}
	]);
	const response = inject(ResponseKey);

	const step = ref(1);
	async function submitBasicInfo(e: Event) {
		try {
			e.preventDefault();
			response?.loading();
			await store.dispatch('user/signUp', basicForm);
			response?.success();
			next();
		} catch (err) {
			response?.error(err as Error);
		}
	}

	async function submitCompanyInfo(e: Event) {
		try {
			e.preventDefault();
			response?.loading();
			companyForm.createdBy = user.value?.uid;
			await store.dispatch(CompanyActions.ADD_COMPANY_INFO, companyForm);
			response?.success();
			next();
		} catch (error) {
			response?.error(error as Error);
		}
	}

	async function navigateToPayment() {
		try {
			next();
			await store.dispatch('user/mimicPayment');
			router.push('/payroll-accounting');
		} catch (error) {}
	}

	onMounted(() => {
		for (let i = 0; i < steps.value.length; i++) {
			steps.value[i].isActive = steps.value[i].path == router.currentRoute.value.path;
			if (steps.value[i].path == router.currentRoute.value.path) step.value = steps.value[i].step;
		}
	});

	function next() {
		steps.value[step.value - 1].isActive = false;
		steps.value[step.value].isActive = true;
		step.value += 1;
		router.push(steps.value[step.value - 1].path);
	}

	return {
		basicForm,
		companyForm,
		step,
		steps,
		submitBasicInfo,
		submitCompanyInfo,
		navigateToPayment
	};
}
