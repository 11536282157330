import { CreateEmployeeType } from '../../../../../types/payroll-accounting-types';
import { CloudFunctionNames, LocalStorageKeys } from '@/enums';
import { LocalStorageRepository } from '@/repositories/local-storage-repository';
import {
	EmployeeType,
	OnDocumentObserver,
	OnQueryObserver,
	PerfomanceTaskType,
	StatusType,
	UserType,
	SalaryType
} from '@/types';
import {
	CollectionReference,
	doc,
	DocumentSnapshot,
	Firestore,
	getDoc,
	getFirestore,
	query,
	where
} from '@firebase/firestore';
import { getFunctions, httpsCallable, HttpsCallableResult } from '@firebase/functions';
import { Unsubscribe } from 'firebase/auth';
import { collection, onSnapshot, orderBy, setDoc, updateDoc, DocumentReference } from 'firebase/firestore';
import BaseEmployeeRepository from './base-employee-repository';
import { v4 } from 'uuid';
import { ErrorUtil } from 'somecode-error-handling';
import { incrementCounter, Time, addToArray } from '@/utils';
import { singleton } from 'tsyringe';

@singleton()
export default class EmployeeRepository extends BaseEmployeeRepository {
	db: Firestore;
	userRef: CollectionReference<EmployeeType>;

	constructor() {
		super();
		this.db = getFirestore();
		this.userRef = collection(this.db, 'users');
	}

	userDocRef = (id: string): DocumentReference<EmployeeType> => doc(this.db, 'users', id);
	async updatedAccruedLeave(employeeId: string): Promise<void> {
		try {
			const data = await this.getEmployee(employeeId);
			const employee = data.data() as EmployeeType;
			const accruedLeave = employee?.accruedAnnualLeaves;
			const leaveAccrued = 0.1;
			if (accruedLeave && accruedLeave.length > 0) {
				const id = Time.dateWithSlashes(new Date());
				const leaves = accruedLeave.filter((leave) => leave.id == id);
				if (leaves.length == 0) {
					await updateDoc(this.userDocRef(employeeId!), {
						accruedAnnualLeaves: addToArray({
							id: Time.dateWithSlashes(new Date()),
							createdAt: new Date(),
							days: leaveAccrued
						}),
						annualLeaveDays: incrementCounter(leaveAccrued)
					});
				}
			} else {
				await updateDoc(this.userDocRef(employeeId), {
					accruedAnnualLeaves: [
						{
							id: Time.dateWithSlashes(new Date()),
							createdAt: new Date(),
							days: leaveAccrued
						}
					],
					annualLeaveDays: employee?.annualLeaveDays ? incrementCounter(leaveAccrued) : leaveAccrued
				});
			}
		} catch (error) {
			console.log(error);

			throw ErrorUtil.throwError(error);
		}
	}
	async addEmployeeBasicInfo(form: EmployeeType): Promise<string | Error> {
		try {
			const localStorageRepository: LocalStorageRepository = new LocalStorageRepository();
			const user: UserType | null = localStorageRepository.getData(LocalStorageKeys.LOCAL_USER);
			var companyId: string;

			if (user) {
				companyId = user.companyId!;
				const addStaff = httpsCallable<EmployeeType>(getFunctions(), CloudFunctionNames.ADD_STAFF);
				let rawData = (await addStaff(form)) as HttpsCallableResult<StatusType>;
				const { success, data: uid } = rawData.data;
				if (success) {
					await setDoc(this.userDocRef(uid), {
						name: form.name,
						surname: form.surname,
						email: form.email,
						contactNumber: form.contactNumber?.toString(),
						staffNumber: form.staffNumber,
						idNumber: form.idNumber?.toString(),
						gender: form.gender,
						title: form.title,
						companyId,
						completion: 50,
						maritalStatus: form.maritalStatus,
						address: form.address,
						role: form.role,
						position: form.position,
						disabled: false
					});
					return uid;
				} else {
					throw new Error(uid);
				}
			} else {
				throw new Error('Please refresh browser before adding a person');
			}
		} catch (e) {
			let error = (e as Error).message;

			throw e;
		}
	}
	async addEmployeeEmploymentInfo(form: EmployeeType) {
		try {
			const emp: EmployeeType = form;

			if (emp.teaTime?.start && emp.teaTime?.end) {
				emp.teaTime = form.teaTime;
			} else {
				delete emp.teaTime;
			}
			await updateDoc(this.userDocRef(form.id!), {
				...emp,
				completion: 75
			});
		} catch (error) {
			throw error;
		}
	}
	async addEmployeeRemunerationInfo(form: CreateEmployeeType) {
		try {
			await updateDoc<EmployeeType>(this.userDocRef(form.id!), {
				salaries: [{ id: v4(), createdAt: new Date(), amount: parseFloat(form.salary!) }],
				paymentInterval: form.paymentInterval,
				completion: 100
			});
		} catch (error) {
			throw error;
		}
	}

	async updateEmployeeTasks(employeeId: string, tasks: PerfomanceTaskType[]) {
		try {
			const preparedTasks = tasks.map((task) => {
				const { isSelected, ...other } = task;
				return other;
			});

			await updateDoc(this.userDocRef(employeeId), {
				tasks: preparedTasks
			});
		} catch (error) {
			throw error;
		}
	}

	async updateJobData(payload: EmployeeType): Promise<void> {
		try {
			await updateDoc(this.userDocRef(payload.id!), {
				jobTitle: payload.jobTitle,
				jobDescription: payload.jobDescription
			});
		} catch (error) {
			throw error;
		}
	}

	async updateEmployee() {
		throw new Error('Method not implemented.');
	}
	async deleteEmployee(id: string) {
		throw new Error('Method not implemented.');
	}

	async getEmployee(id: string): Promise<DocumentSnapshot> {
		return await getDoc(this.userDocRef(id));
	}

	streamEmployees(companyId: string, observer: OnQueryObserver): Unsubscribe {
		let q = query(this.userRef, where('companyId', '==', companyId), orderBy('name', 'asc'));
		return onSnapshot(q, observer);
	}
	streamEmployee(employeeId: string, observer: OnDocumentObserver): Unsubscribe {
		return onSnapshot(this.userDocRef(employeeId), observer);
	}
}
