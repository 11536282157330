import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

import {  RootState } from '@/store'
import type { State } from './state'
export { State }
import { state } from './state'
import { Module } from 'vuex'

export const store: Module<State, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
