import EmploymentContract from '@/modules/payroll-accounting/modules/employment-contract/models/employment-contract';
import PerfomanceTask from '@/modules/payroll-accounting/modules/perfomance-contract/models/perfomance-task';

import {
        DocumentSnapshot,
        FieldValue,
        serverTimestamp,
        Timestamp
} from 'firebase/firestore';
import { CompanyType, Payment } from '@/types';

export default class Company {
        id?: string;
        name?: string;
        address?: string;
        emp201?: string;
        uif?: string;
        sdl?: string;
        registrationNumber?: string;
        vatNumber?: string;
        numberOfEmployees?: string;
        createdAt?: Timestamp | FieldValue | Date;
        createdBy?: string;
        perfomanceTasks?: Array<PerfomanceTask>;
        employmentContract?: EmploymentContract;
        payments?: Array<Payment>;
        constructor(
                id?: string,
                name?: string,
                address?: string,
                emp201?: string,
                uif?: string,
                sdl?: string,
                registrationNumber?: string,
                vatNumber?: string,
                numberOfEmployees?: string,
                createdAt?: Timestamp | FieldValue | Date,
                createdBy?: string,
                employmentContract?: EmploymentContract,
                perfomanceTasks: Array<PerfomanceTask> = [],
                payments: Array<Payment> = []
        ) {
                this.id = id;
                this.address = address;
                this.createdAt = createdAt;
                this.name = name;
                this.emp201 = emp201;
                this.uif = uif;
                this.sdl = sdl;
                this.registrationNumber = registrationNumber;
                this.vatNumber = vatNumber;
                this.numberOfEmployees = numberOfEmployees;
                this.createdBy = createdBy;
                this.employmentContract = employmentContract;
                this.perfomanceTasks = perfomanceTasks;
                this.payments = payments;
        }

        toFirestore(): CompanyType {
                this.createdAt = serverTimestamp();
                return {
                        name: this.name,
                        address: this.address,
                        createdAt: this.createdAt,
                        emp201: this.emp201,
                        uif: this.uif,
                        sdl: this.sdl,
                        registrationNumber: this.registrationNumber,
                        vatNumber: this.vatNumber,
                        numberOfEmployees: this.numberOfEmployees,
                        createdBy: this.createdBy
                };
        }

        fromFirebase(snapshot: DocumentSnapshot): Company {
                this.id = snapshot.id;
                let data = snapshot.data();
                return new Company(
                        this.id,
                        data?.name,
                        data?.address,
                        data?.emp201,
                        data?.uif,
                        data?.sdl,
                        data?.registrationNumber,
                        data?.vatNumber,
                        data?.numberOfEmployees,
                        data?.createdAt,
                        data?.createdBy,
                        data?.perfomanceContract,
                        data?.perfomanceTasks,
                        data?.payments
                );
        }
}
