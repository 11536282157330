import { ClockType } from '@/types';
import { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import { State } from './state';

export const SET_CLOCKS = (state: State, documents: QueryDocumentSnapshot[]) => {
	state.clocks = documents.length > 0 ? documents.map((doc): ClockType => ({ id: doc.id, ...doc.data() })) : [];
	state.status = documents.length > 0 ? 'SUCCESS' : 'EMPTY';
};
export const SET_CLOCK = (state: State, document: DocumentSnapshot) => {
	state.clock = document.exists() ? { id: document.id, ...document.data() } : {};
	state.status = document.exists() ? 'SUCCESS' : 'EMPTY';
};
