
import LoadingOrEmpty from '@/components/LoadingOrEmpty.vue';
import { Enum, RequestStatus, UserRole } from '@/enums';
import { CompanyKey, UserTypeKey } from '@/symbols';
import { Time } from '@/utils';
import { defineComponent, inject, provide, ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import PayslipRequest from '../components/PayslipRequest.vue';
import Request from '../components/Request.vue';
import Warning from '../components/Warning.vue';
import { useEmployeesAction } from '../modules/employee/composables/use-employee';
import useLeave from '../modules/leave/composables/use-leave';
import { usePayslip } from '../modules/payslip/composables/use-payslip';
import { VerifyPayslipKey } from '../modules/payslip/symbols';

export default defineComponent({
	components: {
		Warning,
		Request,
		PayslipRequest,
		VueApexCharts,
		LoadingOrEmpty
	},
	setup() {
		const { leaves, leavesLoadingStatus } = useLeave({ status: RequestStatus.PENDING });
		const { payslips, payslipsLoadingStatus, verifyPayslip } = usePayslip({ streamType: 'BULK', verified: true });
		provide(VerifyPayslipKey, verifyPayslip);
		const { employees } = useEmployeesAction();
		const { currentDate } = Time;
		const company = inject(CompanyKey);
		const user = inject(UserTypeKey);

		const chartOptions = ref({
			chart: {
				id: 'Stats of attendence'
			},
			xaxis: {
				categories: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']
			},
			stroke: {
				curve: 'smooth'
			},
			dataLabels: {
				enabled: false
			}
		});

		const chartSeries = ref([
			{
				name: 'Absent',
				data: [10, 0, 2, 1, 4, 20, 20]
			},
			{
				name: 'Present',
				data: [10, 20, 18, 19, 16, 0, 0]
			}
		]);

		return {
			user,
			leaves,
			leavesLoadingStatus,
			payslips,
			payslipsLoadingStatus,
			employees,
			company,
			currentDate,
			Enum,
			chartOptions,
			chartSeries,
			UserRole
		};
	}
});
