import { UploadFolder } from '@/enums';

export default abstract class BaseUploadRepository {
	abstract uploadPdf(
		base64string: string,
		uploadProgress: Function,
		status: Function,
		id: string,
		folder: UploadFolder
	): Promise<void>;
	abstract downloadFileFromFirebaseStorage(id: string, folder: UploadFolder): Promise<any>;
	abstract uploadAvatar(image: string, uploadProgress: Function, status: Function, id: string): Promise<void>;
}
