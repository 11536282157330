
import { computed, defineComponent, inject, onBeforeMount, provide, watch } from 'vue';
import TextField from '@/components/TextField.vue';
import AddDutyModal from './modals/Duties.vue';
import { EmploymentInfoKey, NextStepKey, PerfomanceTasksKey } from '../symbols';
import { useStore } from '@/store';
import { PerfomanceContractActions } from '../../perfomance-contract/types/store-type';
import EmploymentInfoDuty from './EmploymentInfoDuty.vue';
import { CompanyKey } from '@/symbols';
import { cloneDeep } from 'lodash';
import useCompany from '@/core/modules/company/composables/use-company';
import { CompanyGetters } from '@/core/modules/company/types';
import { CompanyTimes } from '@/types';
export default defineComponent({
	components: {
		TextField,
		AddDutyModal,
		EmploymentInfoDuty
	},
	setup() {
		const store = useStore();
		const form = inject(EmploymentInfoKey, {
			jobTitle: '',
			jobDescription: ''
		});
		const selectedTasks = computed(() => store.state.perfomanceContract.selectedTasks);
		const nextStep = inject(NextStepKey);
		const perfomanceTasks = computed(() => store.state.perfomanceContract.perfomanceTasks);
		const paydays = computed<CompanyTimes.PayDay[]>(() => store.getters[CompanyGetters.ACTIVE_PAYDAYS]);
		const { company } = useCompany();

		function getTasks() {
			if (company.value && company.value.perfomanceTasks && company.value.perfomanceTasks.length > 0) {
				store.dispatch(PerfomanceContractActions.getPerfomanceTasks, cloneDeep(company.value));
			}
		}

		watch(
			() => company?.value,
			() => {
				getTasks();
			}
		);
		onBeforeMount(() => {
			getTasks();
		});

		return {
			form,
			nextStep,
			perfomanceTasks,
			selectedTasks,
			company,
			paydays
		};
	}
});
