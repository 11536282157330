export const convertFileToBase64 = async (imageFile: File, base64String: Function) => {
	let reader = new FileReader();
	reader.onload = function() {
		let str = reader.result;
		base64String(str);
	};
	reader.readAsDataURL(imageFile);
};

export const getBase64FromUrl = async (url: string) => {
	const data = await fetch(url);

	const blob = await data.blob();

	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
};

export const compressImage = async (image: File) => {};
