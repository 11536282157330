import { CompanyType } from '@/types';
import { DocumentSnapshot } from '@firebase/firestore';
import { defaultWorkingHours } from '../utils';
import { State } from './state';

export const SET_COMPANY = (state: State, snapshot: DocumentSnapshot) => {
	let data = snapshot.data();
	let workingHours = [];
	let company: CompanyType = {
		id: snapshot.id,
		...data
	};

	if (!company.hoursOfWork) {
		company.hoursOfWork = defaultWorkingHours();
	}
	state.company = company;
};
