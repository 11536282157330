
	import TextField from '@/components/TextField.vue';
	import useLogin from '../composables/use-login';
	import BackButton from '../../../../components/BackButton.vue';
	import { defineComponent } from '@vue/runtime-core';
	import ForgetPassword from '../components/modals/ForgetPassword.vue'
	export default defineComponent({
		components: { TextField, BackButton, ForgetPassword },
		setup() {
			const { form, submitForm } = useLogin();
			return {
				form,
				submitForm
			};
		}
	});
