import { arrayRemove, arrayUnion, increment } from 'firebase/firestore';

export const addToArray = <T>(element: T) => arrayUnion(element);
export const removeFromArray = <T>(element: T) => arrayRemove(element);
export const incrementCounter = (value: number) => increment(value);
export const decrementCounter = (value: number) => increment(-value);
export const removeStringFromNumber = (str: string): number => {
	let char: any = parseInt(str.charAt(str.length - 1));

	if (isNaN(char) && str.length > 0) {
		str = str.substring(0, str.length - 1);

		return parseInt(str);
	} else if (!isNaN(char)) {
		return parseInt(str);
	}
	// else if(typeof str.charAt(str.length-1) != 'number' && length > 0) {
	//     return st
	// }
	return 0;
};
export const addressWithLineBreak = (address?: string) =>
	address?.includes(',') ? address.replace(/,/g, '<br>') : address;

export const avatarMaker = (firstName?: string, lastName?: string) =>
	(firstName!.substring(0, 1) + lastName!.substring(0, 1)).toUpperCase();
