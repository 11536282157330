
import { EmployeeKey } from '@/modules/payroll-accounting/modules/employee/symbols';
import { defineComponent, inject } from 'vue';
import { Time } from '@/utils';

export default defineComponent({
	setup() {
		const employee = inject(EmployeeKey);
		return { employee, ...Time };
	}
});
