
import LoadingOrEmpty from '@/components/LoadingOrEmpty.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';
import { Enum } from '@/enums';
import { CompanyKey } from '@/symbols';
import { CompanyType, EmployeeType } from '@/types';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, inject, provide, ref, watch } from 'vue';
import { useEmployeesAction } from '../../employee/composables/use-employee';
import { EmployeeKey } from '../../employee/symbols';
import ClockHistoryCard from '../components/ClockHistoryCard.vue';
import useClock from '../composable/use-clock';

export default defineComponent({
	components: {
		UnderConstruction,
		ClockHistoryCard,
		LoadingOrEmpty
	},
	setup() {
		const { employees } = useEmployeesAction();
		const company = inject(CompanyKey);
		const { constraints, groupedClocks, clockStatus, setupAll, clocks, setupGroupedClocks } = useClock({
			dataStream: Enum.DataStream.BULK_EMPLOYEE,
			employeeId: '',
			companyId: ''
		});
		const employee = ref<EmployeeType | null>(null);
		const selectedEmployee = computed(() => employee.value);
		provide(EmployeeKey, selectedEmployee);
		watch(
			() => employees.value,
			(newValue, oldValue) => {
				if (newValue.length != oldValue.length && newValue.length > 0 && oldValue.length == 0) {
					setTimeout(() => {
						let emp = cloneDeep(employees.value)[0];
						constraints.value.employeeId = emp.id!;
					}, 400);
				}
			}
		);

		watch(
			() => company?.value,
			() => {
				constraints.value.companyId = company?.value.id;
			}
		);

		watch(
			() => constraints.value.employeeId,
			() => {
				if (employees.value.length > 0) {
					let emp = cloneDeep(employees.value).filter((emp) => emp.id == constraints.value.employeeId);
					if (emp.length > 0) {
						employee.value = emp[0];
					}
				}

				setupAll();
			}
		);

		watch(
			() => clocks.value,
			() => setupGroupedClocks()
		);

		return {
			employees,
			groupedClocks,
			constraints,
			clocks,
			clockStatus
		};
	}
});
