
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		status: {
			type: String,
			default: 'LOADING'
		},
		message: {
			type: String,
			default: 'The is currently nothing to show.'
		},
		justifyPosition: {
			type: String,
			default: 'center'
		},
		parentHeight: {
			type: String,
			default: '15vh'
		}
	},
	setup() {}
});
