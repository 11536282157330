import { ReconciliationNames } from './../modules/payroll-accounting/modules/reconciliation/enum/index';
import { CompanyType, EmployeeType, Response, UserType } from '@/types';
import { User } from 'firebase/auth';
import { ComputedRef, InjectionKey } from 'vue';

export const UserKey: InjectionKey<User | null> = Symbol('userKey');
export const CropperFileKey: InjectionKey<ComputedRef<File>> = Symbol();
export const CompanyKey: InjectionKey<ComputedRef<CompanyType>> = Symbol();
export const ResponseKey: InjectionKey<Response> = Symbol();
export const FunctionKey: InjectionKey<Function> = Symbol();
export const UserTypeKey: InjectionKey<ComputedRef<UserType | null>> = Symbol();
export const ReconciliationTableLabelsKey: InjectionKey<ComputedRef<ReconciliationNames[]>> = Symbol();
export const EmployeesKey: InjectionKey<ComputedRef<EmployeeType[]>> = Symbol();
