
import useCompany from '@/core/modules/company/composables/use-company';
import { useStore } from '@/store';
import { CompanyKey, ResponseKey } from '@/symbols';
import { PerfomanceTaskType } from '@/types';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { PerfomanceContractActions } from '../../../perfomance-contract/types/store-type';
export default defineComponent({
	setup() {
		const tasks = ref<PerfomanceTaskType[]>([]);

		const { company } = useCompany();
		const store = useStore();
		const employee = computed(() => store.state.employee.employee);
		const response = inject(ResponseKey);

		watch(
			() => [company?.value, employee.value],
			() => loadTasks()
		);

		function loadTasks() {
			if (company.value) {
				tasks.value = company?.value.perfomanceTasks ? company?.value.perfomanceTasks : [];
				tasks.value = company?.value.perfomanceTasks?.map((task) => {
					const t: PerfomanceTaskType = { ...task, isSelected: false };
					return t;
				})!;

				if (employee.value && employee.value.tasks && employee.value.tasks.length > 0) {
					let employeeTaskIds: string[] = employee.value.tasks.map((t) => t.id) as string[];
					tasks.value = tasks.value.map((task) => {
						if (employeeTaskIds.includes(task?.id!)) {
							return {
								...task,
								isSelected: true
							};
						}
						return task;
					});
				}
			}
		}

		function selectTask(index: number) {
			tasks.value[index].isSelected = !tasks.value[index].isSelected;
		}

		async function saveTasks() {
			try {
				const selectedTasks = cloneDeep(tasks.value).filter((task) => task.isSelected == true);

				response?.loading();
				await store.dispatch(PerfomanceContractActions.updateTasks, {
					employeeId: employee.value?.id,
					tasks: selectedTasks
				});
				response?.success('Tasks updated successfully');
			} catch (error) {
				response?.error(error as Error);
			}
		}

		return { tasks, selectTask, saveTasks };
	}
});
