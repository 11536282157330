import { router } from './routes';
import { store as company, State as CompanyState } from '@/core/modules/company/store';
import { store as user, State as UserState } from '@/core/modules/user/store';

export const coreModules = {
	user,
	company
};

export { type CompanyState, type UserState, router };
