import { EmployeeType, LeaveType } from '@/types';
import { ComputedRef, InjectionKey } from 'vue';

export const SelectedEmployeeKey: InjectionKey<ComputedRef<EmployeeType>> = Symbol();
export const SelectedLeaveKey: InjectionKey<ComputedRef<LeaveType>> = Symbol();
export const ViewLeaveModalKey: InjectionKey<Function> = Symbol();
export const ViewDeclineLeaveModalKey: InjectionKey<Function> = Symbol();
export const LeaveFormKey: InjectionKey<LeaveType> = Symbol('form');
export const SubmitFormKey: InjectionKey<Function> = Symbol();
export const RequestLeaveSubmitKey: InjectionKey<Function> = Symbol();
export const ApproveLeaveKey: InjectionKey<Function> = Symbol();
export const DeclineLeaveKey: InjectionKey<Function> = Symbol();
