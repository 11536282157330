import { PerfomanceTaskType } from '@/types';
import { State } from '.';

export const SET_PERFOMANCE_TASKS = (state: State, perfomanceTasks: Array<PerfomanceTaskType>) => {
	state.status = perfomanceTasks && perfomanceTasks.length > 0 ? 'SUCCESS' : 'EMPTY';
	state.perfomanceTasks = perfomanceTasks;
};

export const SELECT_PERFOMANCE_TASK = (state: State, task: PerfomanceTaskType) => {
	let containsTask: boolean = state.selectedTasks.filter((t) => t.id == task.id).length > 0;

	if (containsTask) {
		state.perfomanceTasks.push(task);
		state.selectedTasks = state.selectedTasks.filter((t) => t.id != task.id);
	} else {
		state.perfomanceTasks = state.perfomanceTasks.filter((t) => t.id != task.id);
		state.selectedTasks.push(task);
	}

	state.perfomanceTasks.sort((a, b) => parseInt(a.id!) - parseInt(b.id!));
};
