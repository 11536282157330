import { onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { BillingRepo } from './billing-repo';
import { billingStore } from '@/store';
import { BillingModel } from 'kunokhar-acc-types';
import { singleton } from 'tsyringe';

@singleton()
export class BillingRepoImpl extends BillingRepo {
	streamBillings(companyId: string): Unsubscribe {
		const store = billingStore();
		store.streamResponse = {
			status: 'LOADING'
		};

		const q = query(this.billingRef, where('companyId', '==', companyId));

		return onSnapshot(q, {
			next: (snapshot) => {
				if (snapshot.empty) {
					console.log('No matching documents.');
					store.streamResponse = {
						status: 'EMPTY',
						message: 'Never payed'
					};
					return;
				}

				store.billings = snapshot.docs.map(
					(doc): BillingModel => ({
						...doc.data(),
						id: doc.id
					})
				);

				store.streamResponse = {
					status: 'SUCCESS'
				};
			},
			error: (error) => {
				console.log(error);
				store.streamResponse = {
					status: 'ERROR',
					message: error.message
				};
			}
		});
	}
	streamBilling(billingId: string): Unsubscribe {
		const store = billingStore();
		store.streamResponse = {
			status: 'LOADING'
		};
		return onSnapshot(this.docRef(billingId), {
			next: (snapshot) => {
				if (!snapshot.exists()) {
					console.log('No matching documents.');
					store.streamResponse = {
						status: 'EMPTY',
						message: 'Record not found'
					};
					return;
				}

				store.billing = {
					...snapshot.data(),
					id: snapshot.id
				};

				store.streamResponse = {
					status: 'SUCCESS'
				};
			},
			error: (error) => {
				store.streamResponse = {
					status: 'ERROR',
					message: error.message
				};
				console.log(error);
			}
		});
	}
}
