import { IStateStatus, PerfomanceTaskType } from '@/types';

export type State = {
	perfomanceTask: PerfomanceTaskType | null;
	perfomanceTasks: Array<PerfomanceTaskType>;
	selectedTasks: Array<PerfomanceTaskType>;
} & IStateStatus;

export const state: State = {
	status: 'LOADING',
	perfomanceTask: null,
	perfomanceTasks: [],
	selectedTasks: []
};
