import { ClockType, OnDocumentObserver, OnQueryObserver } from '@/types';
import {
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	Firestore,
	getFirestore,
	Unsubscribe
} from 'firebase/firestore';
import { ClockQueryConstraints } from '../types';

export default abstract class BaseClockRepository {
	protected clockingsRef: CollectionReference<ClockType>;
	protected db: Firestore;
	constructor() {
		this.db = getFirestore();
		this.clockingsRef = collection(this.db, 'clockings');
	}
	protected clockingDocRef = (id: string): DocumentReference<ClockType> => doc(this.db, 'clockings', id);
	abstract registerTime(timeEntry: ClockType): Promise<void>;
	abstract getTodaysEntries(queryConstraints: ClockQueryConstraints): Promise<ClockType[]>;
	abstract streamClockEntries(queryConstraints: ClockQueryConstraints, observer: OnQueryObserver): Unsubscribe;
	abstract streamClockEntryById(id: string, observer: OnDocumentObserver): Unsubscribe;
}
