import { employeeRepo, locationRepo } from '@/di';
import { Enum } from '@/enums';
import { ClockHistory, ClockType, OnDocumentObserver, OnQueryObserver } from '@/types';
import { addToArray, Time } from '@/utils';
import {
	addDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	QueryConstraint,
	serverTimestamp,
	Unsubscribe,
	updateDoc,
	where
} from 'firebase/firestore';
import moment from 'moment';
import { ClockQueryConstraints } from '../types';
import BaseClockRepository from './base-clock-repository';
import { v4 } from 'uuid';

export class ClockRepository extends BaseClockRepository {
	async registerTime(timeEntry: ClockType): Promise<void> {
		try {
			const clockings = await this.getTodaysEntries({
				companyId: timeEntry.companyId,
				employeeId: timeEntry.employeeId
			});
			await locationRepo.getCoordinates();

			const history: ClockHistory = {
				id: v4(),
				address: await locationRepo.getAddress(),
				createdAt: new Date()
			};

			if (clockings.length > 0) {
				const valueToInsertCheck = clockings.filter((clock) => clock.type == timeEntry.type);

				if (valueToInsertCheck.length > 0) {
					await updateDoc(this.clockingDocRef(valueToInsertCheck[0].id!), {
						'duration.end': Time.getCurrentHours(),
						updatedAt: serverTimestamp(),
						history: addToArray(history)
					});
					if (timeEntry.type == Enum.ClockType.ON_DUTY) {
						await employeeRepo.updatedAccruedLeave(timeEntry.employeeId!);
					}
				} else {
					await this.newTimeRecord(timeEntry, history);
				}
			} else {
				if (timeEntry.type == Enum.ClockType.ON_DUTY) {
					await this.newTimeRecord(timeEntry, history);
				} else {
					throw new Error('Invalid selection');
				}
			}
		} catch (error) {
			throw error;
		}
	}

	private async newTimeRecord(timeEntry: ClockType, history: ClockHistory): Promise<void> {
		await addDoc(this.clockingsRef, {
			employeeId: timeEntry.employeeId,
			companyId: timeEntry.companyId,
			type: timeEntry.type,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			duration: {
				start: Time.getCurrentHours(),
				end: ''
			},
			history: [history]
		});
	}

	async getTodaysEntries(queryConstraints: ClockQueryConstraints): Promise<ClockType[]> {
		try {
			const constraints: QueryConstraint[] = [
				where('companyId', '==', queryConstraints.companyId),
				where('createdAt', '>=', new Date(Time.getCurrentFormatedFirebaseTime())),
				where('createdAt', '<', new Date(Time.getCurrentFormatedFirebaseTime(moment().add(1, 'd').toDate())))
			];
			if (queryConstraints.employeeId) {
				constraints.push(where('employeeId', '==', queryConstraints.employeeId));
			}
			if (queryConstraints.type) {
				constraints.push(where('type', '==', queryConstraints.type));
			}

			const qSnapshot = await getDocs(query(this.clockingsRef, ...constraints));

			if (qSnapshot.docs.length > 0) {
				return qSnapshot.docs.map((doc): ClockType => ({ id: doc.id, ...doc.data() }));
			} else {
				return [];
			}
		} catch (error) {
			console.log(error);

			throw error;
		}
	}
	streamClockEntries(queryConstraints: ClockQueryConstraints, observer: OnQueryObserver): Unsubscribe {
		const constraints: QueryConstraint[] = [where('companyId', '==', queryConstraints.companyId)];

		if (queryConstraints.employeeId) {
			constraints.push(where('employeeId', '==', queryConstraints.employeeId));
		}

		if (queryConstraints.type) {
			constraints.push(where('type', '==', queryConstraints.type));
		}

		return onSnapshot(query(this.clockingsRef, ...constraints, orderBy('createdAt', 'desc')), observer);
	}

	streamClockEntryById(id: string, observer: OnDocumentObserver): Unsubscribe {
		return onSnapshot(this.clockingDocRef(id), observer);
	}
}
