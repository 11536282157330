import { Reconciliation } from './../types';
import { reconciliationRepo } from '@/di';
import { _ActionsTree } from 'pinia';
import { ReconciliationActions } from '../enum';
import { Unsubscribe } from '@firebase/firestore';

export interface Actions extends _ActionsTree {
	[ReconciliationActions.ADD_RECONCILIATION](payload: Reconciliation.CreatePayment): Promise<void>;
	[ReconciliationActions.GET_RECONCILIATIONS](companyId: string): Promise<void>;
	[ReconciliationActions.GET_RECONCILIATION](id: string): Promise<void>;
	[ReconciliationActions.STREAM_RECONCILIATION](payload: Reconciliation.StreamPayload): Unsubscribe | undefined;
}

export const actions: Actions = {
	ADD_RECONCILIATION: async function (payload: Reconciliation.CreatePayment): Promise<void> {
		await reconciliationRepo.addReconciliation(payload);
	},
	GET_RECONCILIATIONS: function (companyId: string): Promise<void> {
		throw new Error('Function not implemented.');
	},
	GET_RECONCILIATION: async function (id: string): Promise<void> {
		await reconciliationRepo.getReconciliation(id);
	},
	STREAM_RECONCILIATION: (payload: Reconciliation.StreamPayload): Unsubscribe | undefined =>
		reconciliationRepo.streamReconciliation(payload)
};
