import { CompanyState, coreModules, UserState } from '@/core';
import {
	ClockState,
	EmployeeState,
	EmploymentContractState,
	payrollAccountingModules,
	PayslipState,
	PerfomanceContractState
} from '@/modules/payroll-accounting/';
import { InjectionKey } from 'vue';
import { createLogger, createStore, Store, useStore as baseUseStore } from 'vuex';
import { State as ResponseState, store as ResponseModule } from './responseModule';

export type RootState = {
	response: ResponseState;
	user: UserState;
	company: CompanyState;
	employee: EmployeeState;
	clock: ClockState;
	payslip: PayslipState;
	perfomanceContract: PerfomanceContractState;
	employmentContract: EmploymentContractState;
};

export const key: InjectionKey<Store<RootState>> = Symbol();
export const store = createStore<RootState>({
	modules: {
		response: ResponseModule,
		...coreModules,
		...payrollAccountingModules
	},
	plugins: process.env.NODE_ENV != 'production' ? [createLogger({ collapsed: true })] : []
});

export function useStore() {
	return baseUseStore(key);
}

export * from '@/modules/payroll-accounting/modules/reconciliation/store';
export * from '@/modules/payroll-accounting/modules/leave/store';
export * from '@/core/modules/billing/store';
