import { DOB, Duration, DurationTime, ITime } from '@/types';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

export namespace Time {
	export const toDefaultString = (rawDate?: Date | Timestamp | string) => moment(rawDate).format('ll');

	export const durationInDays = (start?: Date | Timestamp, end?: Date | Timestamp) => moment(end).diff(start, 'days');
	export const timeAgo = (rawDate?: Timestamp) => moment(rawDate?.toDate()).fromNow();

	export const currentDate = () => moment(new Date()).format('MMMM DD, YYYY');

	export const stringDate = (rawDate?: Timestamp | Date | string) => moment(rawDate).format('MMMM DD, YYYY');

	export const dateWithSlashes = (rawDate?: Timestamp | Date | string) => {
		let date: Date;
		if (rawDate instanceof Date) date = rawDate;
		else if (rawDate instanceof Timestamp) date = rawDate.toDate();
		else if (typeof rawDate === 'string') date = new Date(rawDate);
		else return rawDate;
		return moment(date).format('MM/DD/YYYY');
	};

	export const getCurrentHours = () => moment().format('HH:mm');

	export const getCurrentFormatedFirebaseTime = (date: Date | Timestamp = new Date()) =>
		moment(date).format('YYYY-MM-DD');

	export const timeStampIsToday = (date?: Timestamp) => {
		return moment(date?.toDate()).diff(moment(), 'days') == 0;
	};

	export const getTimeDifferenceFromNow = (date?: Date | Timestamp) => {
		return moment(date).diff(moment(), 'days');
	};
	export const getTimeDifferenceFromTimestamp = (date?: Date | Timestamp) => {
		return moment((date as Timestamp).toDate()).diff(moment(), 'days');
	};

	export const niceDate = (date?: Timestamp) => {
		const now = new Date();
		const fixedDate = date?.toDate();
		let dateDiff = moment(fixedDate).diff(moment(now), 'days');

		let momentDate = moment(fixedDate);

		if (dateDiff == 0 && now.getDate() == fixedDate?.getDate()) return `Today, ${momentDate.format('MMMM DD')}`;
		else if (dateDiff == 1 || (dateDiff == 0 && now.getDate() != fixedDate?.getDate()))
			return `Yesterday, ${momentDate.format('MMMM DD')}`;
		else if (now.getFullYear() == momentDate.toDate().getFullYear())
			return `${getDayOfTheWeek(momentDate.day())}, ${momentDate.format('MMMM DD')}`;
		else return `${momentDate.format('MMMM DD, YYYY')}`;
	};

	export function totalMinutes(start: ITime, end: ITime): number {
		return end.hours * 60 + end.minutes - (start.hours * 60 + start.minutes);
	}

	export function stringToTime(stringTime: string) {
		let arrayString = stringTime.split(':');
		return <ITime>{
			hours: parseInt(arrayString[0]),
			minutes: parseInt(arrayString[1])
		};
	}

	export function calculateTotalMinutes(duration: DurationTime) {
		let start = Time.stringToTime(duration?.start ? duration.start : Time.getCurrentHours());
		let end = Time.stringToTime(duration?.end ? duration.end : Time.getCurrentHours());
		return Time.totalMinutes(start, end);
	}

	export function minuteToITime(minutes: number) {
		return <ITime>{
			hours: Math.floor(minutes / 60),
			minutes: minutes % 60
		};
	}
}

function getDayOfTheWeek(day: number) {
	switch (day) {
		case 0:
			return 'Sun';
		case 1:
			return 'Mon';
		case 2:
			return 'Tue';
		case 3:
			return 'Wed';
		case 4:
			return 'Thurs';
		case 5:
			return 'Fri';
		case 6:
			return 'Sat';
	}
}

export const strDate = (rawDate?: Date | Timestamp) => moment(rawDate!).format('MMM Do, YYYY');
export const formatDate = (rawDate: Date | Timestamp) => moment(rawDate).format('DD-MM-YYYY');
export const formatDateFromString = (dateString: string) => moment(dateString).format('DD MMM YYYY');
export const formatDateToTime = (rawDate: Timestamp) => moment(rawDate.toDate()).format('HH:mm');

export const getDOBFromIDNumber = (idNumber: string) => {
	let dobSegment = idNumber.toString().substring(0, 6);

	let dob: DOB = {
		day: parseInt(dobSegment.substring(4, 6)),
		month: parseInt(dobSegment.substring(2, 4)),
		year: getFullYear(parseInt(dobSegment.substring(0, 2)))
	};
	return dob;
};

const getFullYear = (year: number) => {
	if (year < 9) return parseInt(`200${year}`);
	else if (year > 10 && year <= parseInt(moment().format('YY'))) return parseInt(`20${year}`);
	else return parseInt(`19${year}`);
};

export const actualAge = (idNumber: string) => {
	let dob: DOB = getDOBFromIDNumber(idNumber);
	let now = new Date();
	let dateDifference: number = moment(`${now.getFullYear()}-${dob.month}-${dob.day}`, 'YYYY-MM-DD')
		.add(1, 'day')
		.diff(moment(now), 'days');
	if (dateDifference <= 0) return now.getFullYear() - dob.year;
	else return now.getFullYear() - dob.year - 1;
};

export const accountingYearAge = (idNumber: string) => {
	let dob: DOB = getDOBFromIDNumber(idNumber);
	let now = new Date();
	return now.getFullYear() - dob.year;
};

export const numberOfDays = (duration?: Duration) =>
	moment(duration!.start).add(1, 'day').diff(moment(duration!.end), 'days');

export const timeAgo = (date?: Date | Timestamp) => moment(date).fromNow();
