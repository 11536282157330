import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';
import { OnDocumentObserver, OnQueryObserver } from '@/types';

export abstract class FirebaseStream<T> {
	abstract queryObserver(id?: string): OnQueryObserver;
	abstract documentObserver(id?: string): OnDocumentObserver;
	protected fromListFirestore = (querySnapshot: QuerySnapshot): Array<T> => {
		if (querySnapshot.empty) {
			return [];
		}
		return querySnapshot.docs.map((doc) => {
			const data: T = doc.data() as T;
			return {
				id: doc.id,
				...data
			};
		});
	};

	protected fromDocumentFirestore = (docSnapshot: DocumentSnapshot): T | null => {
		if (!docSnapshot.exists()) {
			return null;
		}
		const data: T = docSnapshot.data() as T;
		return {
			id: docSnapshot.id,
			...data
		};
	};
}
