<template>
    <ul class="section-list">
        <li contenteditable="true">This is list item 1</li>
        <li contenteditable="true">This is list item 2</li>
        <li contenteditable="true">This is list item 3</li>
        <li contenteditable="true">This is list item 4</li>
    </ul>
</template>
<script>
    export default {

    }
</script>