import { Enum, UserRole } from '@/enums';
import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { UserType } from '@/types';
import { computed, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { emailError, passwordError } from '../utils/validate';

export default function useLogin() {
	const form = reactive<UserType>({
		email: '',
		password: ''
	});
	const router = useRouter();
	const store = useStore();
	const user = computed(() => store.state.user.user);
	const response = inject(ResponseKey);
	async function submitForm() {
		if (!formError()) {
			try {
				response?.loading();
				await store.dispatch('user/signIn', form);
				response?.success();
				if (user.value) {
					switch (user.value.completion) {
						case 100:
							await router.replace({
								name:
									user.value.role == UserRole.COMPANY_ADMIN
										? Enum.RouteNames.DASHBOARD
										: Enum.RouteNames.EMPLOYEE_DASHBOARD
							});
							break;
						case 75:
							await router.replace({ name: Enum.RouteNames.REGISTER_PAYMENT });
							break;
						case 50:
							await router.replace({ name: Enum.RouteNames.REVIEW_REG });
							break;
						case 25:
							await router.replace({ name: Enum.RouteNames.REGISTER_COMPANY_INFO });
							break;
					}
				}
			} catch (error) {
				response?.error(error as Error);
			}
		}
	}
	function formError() {
		if (emailError(form.email! as string)) {
			return emailError(form.email! as string);
		}
		if (passwordError(form.password! as string)) {
			return passwordError(form.password! as string);
		}
		return null;
	}

	return {
		form,
		submitForm
	};
}
