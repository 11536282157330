import { PaymentInterval } from '@/enums';

import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { CreateEmployeeType, EmployeeType } from '@/types';
import { Unsubscribe } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { computed, inject, onBeforeMount, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import StepType from '../types/step-type';
import { EmployeeActions, EmployeeGetters } from '../types/store-types';

const useEmployee = () => {
	const basicInfoForm = reactive<EmployeeType>({
		title: '',
		name: '',
		surname: '',
		email: '',
		gender: '',
		role: '',
		contactNumber: '',
		position: '',
		idNumber: '',
		staffNumber: '',
		maritalStatus: ''
	});
	const employmentInfoForm = reactive<EmployeeType>({
		jobTitle: '',
		employmentDate: new Date('yyyy/mm/dd'),
		jobDescription: '',
		payday: '',
		lunchTime: { start: '', end: '' },
		teaTime: { start: '', end: '' },
		tasks: []
	});

	const remunerationForm = reactive<CreateEmployeeType>({
		salary: '',
		paymentInterval: PaymentInterval.MONTHLY
	});
	const employeeId = ref<string | null>(null);
	const store = useStore();
	const selectedTasks = computed(() => store.state.perfomanceContract.selectedTasks);
	const step = ref(0);
	const isLoading = ref(false);
	const response = inject(ResponseKey);
	const steps = ref<Array<StepType>>([
		{
			step: 1,
			routeName: 'EmployeeBasicInfo',
			title: 'Basic info',
			isActive: true
		},
		{
			step: 2,
			routeName: 'EmployeeEmploymentInfo',
			title: 'Employment info',
			isActive: false
		},
		{
			step: 3,
			routeName: 'EmployeeRemunerationInfo',
			title: 'Remuneration info',
			isActive: false
		}
	]);

	const router = useRouter();

	async function nextStep() {
		response?.loading();
		try {
			switch (step.value) {
				case 0:
					const userId = await store.dispatch('employee/addEmployeeBasicInfo', cloneDeep(basicInfoForm));
					employeeId.value = userId;
					break;
				case 1:
					employmentInfoForm.id = employeeId.value!;
					employmentInfoForm.id = router.currentRoute.value.params.employeeId as string;
					employmentInfoForm.tasks = selectedTasks.value;
					await store.dispatch('employee/addEmployeeEmploymentInfo', cloneDeep(employmentInfoForm));
					break;
				case 2:
					remunerationForm.id = router.currentRoute.value.params.employeeId as string;
					await store.dispatch('employee/addEmployeeRemunerationInfo', cloneDeep(remunerationForm));
					break;
			}

			response?.success('Employee added successfully');
			step.value++;
			steps.value[step.value - 1].isActive = false;
			if (step.value > 2) {
				step.value = 0;
				setTimeout(() => {
					location.reload();
				}, 1000);
			}
		} catch (error) {
			response?.error(error as Error);
		}
	}
	function changeRoute() {
		steps.value[step.value].isActive = true;
		if (step.value == 0)
			router.push({
				name: steps.value[step.value].routeName
			});
		else
			router.push({
				name: steps.value[step.value].routeName,
				params: {
					employeeId: employeeId.value ?? router.currentRoute.value.params.employeeId
				}
			});
	}

	watch(step, () => {
		changeRoute();
	});

	onMounted(() => {
		const routerName = router.currentRoute.value.name;
		employeeId.value = step.value > 0 ? router.currentRoute.value.params?.employeeId.toString() : null;
		steps.value.forEach((value, index) => {
			if (routerName == value.routeName) {
				steps.value[0].isActive = false;
				steps.value[index].isActive = true;
				step.value = index;
			}
		});
	});

	return {
		basicInfoForm,
		employmentInfoForm,
		remunerationForm,
		step,
		steps,
		nextStep,
		isLoading
	};
};

const useEmployeesAction = () => {
	const store = useStore();
	const employees = computed(() => store.state.employee.employees);
	const status = computed(() => store.state.employee.status);
	const user = computed(() => store.state.user.user);
	var unsubscribe: Unsubscribe;
	const response = inject(ResponseKey);

	onBeforeMount(async () => {
		await loadEmployee();
	});

	async function loadEmployee() {
		if (typeof unsubscribe == 'function') unsubscribe();
		unsubscribe = await store.dispatch(EmployeeActions.STREAM_EMPLOYEES, { user: cloneDeep(user.value), response });
	}

	onUnmounted(() => {
		unsubscribe();
	});

	return {
		employees,
		status
	};
};

const useEmployeeAction = (employeeId?: string) => {
	const store = useStore();
	const employee = computed(() => store.state.employee.employee);
	var unsubscribe: Unsubscribe;
	const response = inject(ResponseKey);
	const id = ref(employeeId);
	const currentSalary = computed(() => store.getters[EmployeeGetters.CURRENT_SALARY]);

	async function streamEmployee() {
		if (id.value) {
			unsubscribe = await store.dispatch(EmployeeActions.STREAM_EMPLOYEE, {
				employeeId: id.value,
				response
			});
		}
	}

	onBeforeMount(async () => {
		streamEmployee();
	});

	onUnmounted(() => {
		if (typeof unsubscribe == 'function') unsubscribe();
	});

	return {
		employee,
		id,
		streamEmployee,
		currentSalary
	};
};

export { useEmployee, useEmployeesAction, useEmployeeAction };
