import type { IConfigEncryption, PayfastKeysModel } from 'kunokhar-acc-services';
export const payfastConfig: PayfastKeysModel = {
	merchantId:
		process.env.VUE_APP_VARIANT == 'PROD'
			? process.env.VUE_APP_MERCHANT_ID || ''
			: process.env.VUE_APP_TEST_MERCHANT_ID || '',
	merchantKey:
		process.env.VUE_APP_VARIANT == 'PROD'
			? process.env.VUE_APP_MERCHANT_KEY || ''
			: process.env.VUE_APP_TEST_MERCHANT_KEY || '',
	passPhrase: process.env.VUE_APP_PASS_PHRASE || ''
};

export const encryptionConfig: IConfigEncryption = {
	algorithm: process.env.VUE_APP_ALGORITHM || '',
	encryptionKey: process.env.VUE_APP_ENCRYPTION_KEY || '',
	salt: process.env.VUE_APP_SALT || ''
};
