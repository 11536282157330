import { AddressType, MonthModel } from '@/types';

export * from './api';
export * from './file';
export * from './format';
export * from './helper';
export * from './time';
export * from './transform';
export * from './validate';
import months from '@/data/month_data.json';
export * from './upload';
let oldValue: any;

export const isNumber = (event: any) => {
	let value: any = event.target.value;
	let isValid: boolean = +value == +value;
	if (!isValid) {
		var resetEvent = document.createEvent('Event');
		resetEvent.initEvent(event.type, true, true);
		event.target.value = oldValue ? oldValue : '';
		event.target.dispatchEvent(resetEvent);
	}
	oldValue = event.target.value;
};

/**
 * Represents years
 * @function yearsFromNow
 * @param  {number} count - How many years you would like to get from current year
 * @returns {Array<number>} years
 */
export const yearsFromNow = (count = 5): number[] => {
	let years: number[] = [];
	for (let i = 0; i < count; i++) {
		years.push(new Date().getFullYear() - i);
	}
	return years;
};

export const getMonths = (): MonthModel[] => {
	return months;
};

export const stringToAddress = (address: string): AddressType => {
	return {};
};
