
import { computed, ref, defineComponent } from 'vue';
export default defineComponent({
	props: {
		routerLink: {
			type: String,
			default: null
		}
	}
});
