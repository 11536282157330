import { CreateClock } from './../types/index';
import { Enum } from '@/enums';
import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { ClockType, GroupedClockType } from '@/types';
import { Time } from '@/utils';
import { Timestamp, Unsubscribe } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { computed, inject, onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { ClockActions, ClockQueryConstraints } from '../types';

export default function useClock(queryConstraints: ClockQueryConstraints) {
	const store = useStore();
	const clocks = computed(() => store.state.clock.clocks);
	const clock = computed(() => store.state.clock.clock);
	const clockStatus = computed(() => store.state.clock.status);
	let unsubscribe: Unsubscribe;
	const constraints = ref<ClockQueryConstraints>(queryConstraints);
	const response = inject(ResponseKey);
	const form = reactive<CreateClock>({
		companyId: constraints.value.companyId,
		employeeId: constraints.value.employeeId,
		type: undefined
	});
	const groupedClocks = ref<GroupedClockType[]>([]);

	async function registerTime() {
		try {
			if (form.type) {
				response?.loading();
				await store.dispatch(ClockActions.REGISTER_TIME, cloneDeep(form));
				let c = cloneDeep(clocks.value);
				if (
					(form.type == Enum.ClockType.TEA && c[c.length - 1].duration?.end) ||
					(form.type == Enum.ClockType.ON_DUTY && !c[c.length - 1].duration?.end)
				) {
					form.type = undefined;
				}
				response?.success('Time clocked!!');
			} else {
				throw new Error('Please select a clocking type');
			}
		} catch (error) {
			response?.error(error as Error);
		}
	}

	async function setupAll() {
		try {
			if (
				constraints.value.companyId &&
				constraints.value.companyId &&
				constraints.value.dataStream != Enum.DataStream.SINGLE
			) {
				unsubscribe = await store.dispatch(ClockActions.STREAM_CLOCKS, cloneDeep(constraints.value));
			} else if (constraints.value.id) {
				unsubscribe = await store.dispatch(ClockActions.STREAM_CLOCK, constraints.value.id);
			}
		} catch (error) {
			console.log(error);
		}
	}

	function setupGroupedClocks(callBack?: Function) {
		groupedClocks.value = [];
		if (clocks.value.length > 0) {
			for (let index = 0; index < clocks.value.length; index++) {
				const clock = clocks.value[index];
				let groupedIndex = -1;
				if (groupedClocks.value.length > 0) {
					for (let i = 0; i < groupedClocks.value.length; i++) {
						const element = groupedClocks.value[i];
						if (Time.niceDate(clock.createdAt as Timestamp) == element.date) {
							groupedIndex = i;
						}
					}
					if (groupedIndex != -1) {
						groupedClocks.value[groupedIndex].clocks?.push(cloneDeep(clock));
					} else {
						groupedClocks.value.push({
							date: Time.niceDate(clock.createdAt as Timestamp),
							clocks: [cloneDeep(clock)]
						});
					}
				} else {
					groupedClocks.value.push({
						date: Time.niceDate(clock.createdAt as Timestamp),
						clocks: [cloneDeep(clock)]
					});
				}
			}
			if (callBack) callBack();
		}
	}

	watch(
		() => constraints.value,
		() => {
			setupAll();
		}
	);

	onBeforeMount(() => setupAll());
	onBeforeUnmount(() => {
		if (typeof unsubscribe == 'function') unsubscribe();
	});

	return {
		clocks,
		clock,
		clockStatus,
		constraints,
		form,
		groupedClocks,
		setupAll,
		registerTime,
		setupGroupedClocks
	};
}
