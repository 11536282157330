
import { ModalType } from '@/types';
import { Modal } from 'bootstrap';
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, watch } from 'vue';

export default defineComponent({
	props: {
		options: {
			type: Object as PropType<ModalType>,
			required: true
		}
	},
	setup(props) {
		const options = computed(() => props.options);
		let modal: Modal;

		onMounted(() => {
			let el: Element = document.getElementById(`${options.value.id}`) as Element;
			if (el) modal = new Modal(el, { keyboard: true });
		});

		onBeforeUnmount(() => {
			modal.dispose();
		});

		watch(
			() => options.value.show,
			() => {
				modal.toggle();
			}
		);
		return {
			options
		};
	}
});
