
import { TPlan } from '@/types';
import { currencyFormat } from '@/utils';
import { defineComponent, inject, PropType } from 'vue';
import { SelectedPlanKey, SelectPlanKey } from '../../symbols';
export default defineComponent({
	props: {
		plan: {
			type: Object as PropType<TPlan>,
			required: true
		}
	},
	setup() {
		const selectPlan = inject(SelectPlanKey) as Function;
		const selectedPlan = inject(SelectedPlanKey);

		return {
			currencyFormat,
			selectedPlan,
			selectPlan
		};
	}
});
