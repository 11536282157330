<template>
	<div>
		<VueGoogleAutocomplete
			id="map"
			:country="'za'"
			:placeholder="placeholder"
			v-on:placechanged="getAddress"
			v-model="controller"
		/>
	</div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { computed, ref, watch } from 'vue';

export default {
	components: { VueGoogleAutocomplete },
	props: {
		controller: {
			required: true
		},
		placeholder: {
			type: String,
			default: 'Company Address'
		}
	},

	setup(props) {
		const controller = ref(props.controller);
		const placeholder = ref(props.placeholder);
		watch(
			() => props.controller,
			() => {
				controller.value = props.controller;
			}
		);
		function getAddress(e) {
			controller.value = `${e.street_number} ${e.route}, ${e.locality}, ${e.country}`;
		}

		return { getAddress, controller, placeholder };
	}
};
</script>
