import { Enum } from '@/enums';
import { ClockType, ITime } from '@/types';

export enum ClockMutations {
	SET_CLOCKS = 'SET_CLOCKS',
	SET_CLOCK = 'SET_CLOCK'
}

export enum ClockActions {
	REGISTER_TIME = 'clock/registerTime',
	STREAM_CLOCKS = 'clock/streamClocks',
	STREAM_CLOCK = 'clock/streamClock'
}

export type TotalWorkedTime = {
	lunch: ITime;
	tea: ITime;
	onDuty: ITime;
	totalTime: ITime;
};

export type ClockQueryConstraints = {
	id?: string;
	employeeId?: string;
	companyId?: string;
	type?: Enum.ClockType;
	dataStream?: Enum.DataStream;
	createdAt?: Date;
};

export type CreateClock = Partial<ClockType>;
