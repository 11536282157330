import { LocalStorageKeys } from '@/enums';
import { UploadProfilePicModel, UploadStatusModel } from '@/models';
import { LocalStorageRepository } from '@/repositories/local-storage-repository';
import { OnDocumentObserver, OnQueryObserver, UserType } from '@/types';
import { UploadToCloudStore } from '@/utils';
import {
	Auth,
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword
} from 'firebase/auth';
import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	Firestore,
	getDoc,
	getFirestore,
	onSnapshot,
	serverTimestamp,
	setDoc,
	updateDoc
} from 'firebase/firestore';
import { ErrorUtil } from 'somecode-error-handling';
import User from '../models/user';
export default class UserRepository {
	auth: Auth;
	db: Firestore;
	usersRef: CollectionReference<DocumentData>;
	constructor() {
		this.auth = getAuth();
		this.db = getFirestore();
		this.usersRef = collection(this.db, 'users');
	}

	userDocRef = (id: string) => doc(this.db, 'users', id);

	async signIn(form: UserType) {
		try {
			const response = await signInWithEmailAndPassword(this.auth, form.email! as string, form.password! as string);
			const user = await this.getUser(response.user.uid);
			if (user.exists()) {
				new LocalStorageRepository().storeData(LocalStorageKeys.LOCAL_USER, {
					id: user.id,
					...user.data()
				});
			}
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}
	async signUp(form: UserType) {
		try {
			let user = new User(
				form.id,
				form.email,
				form.password,
				form.confirmPassword,
				form.name,
				form.surname,
				form.role,
				form.companyId,
				form.completion,
				form.idNumber,
				form.contactNumber,
				form.createdAt
			);

			const response = await createUserWithEmailAndPassword(
				this.auth,
				user.email! as string,
				user.password! as string
			);
			await setDoc(this.userDocRef(response.user.uid), user.toFirestore());
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}

	async confirmDetails() {
		try {
			return await updateDoc(this.userDocRef(this.auth?.currentUser!.uid), {
				completion: 75,
				updatedAt: serverTimestamp()
			});
		} catch (error) {
			throw error;
		}
	}

	async resetPassword(email: string) {
		try {
			return await sendPasswordResetEmail(this.auth, email.trim());
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}

	async mimiPayment() {
		try {
			return await updateDoc(this.userDocRef(this.auth?.currentUser!.uid), {
				completion: 100
			});
		} catch (error) {
			throw error;
		}
	}

	async updateCompanyInfo(companyId: string) {
		try {
			return await updateDoc(this.userDocRef(this.auth?.currentUser!.uid), {
				companyId,
				completion: 50,
				updatedAt: serverTimestamp()
			});
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}
	streamUsers = (observer: OnQueryObserver) => onSnapshot(this.usersRef, observer);

	async getUser(id: string) {
		try {
			return await getDoc(this.userDocRef(id));
		} catch (error) {
			throw ErrorUtil.throwError(error);
		}
	}

	streamUser = (userId: string, observer: OnDocumentObserver) => onSnapshot(this.userDocRef(userId), observer);

	async getCurrentUserCompanyId(): Promise<string> {
		let localStorageRepository: LocalStorageRepository = new LocalStorageRepository();
		const user: UserType | null = localStorageRepository.getData(LocalStorageKeys.LOCAL_USER);

		const companyId: string = user?.companyId!;
		return companyId;
	}

	async getContactPerson(contactPersonId: string) {
		try {
			return await getDoc(this.userDocRef(contactPersonId));
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}

	uploadProfilePic(payload: UploadProfilePicModel) {
		return new Promise((resolve, reject) => {
			const uploadUtil = new UploadToCloudStore({ path: `profile-pics/${payload.userId}` });
			return uploadUtil.upload(payload.file as File, async (response) => {
				if (response.state === 'success') {
					await updateDoc(this.userDocRef(payload.userId), {
						imageUrl: response.data
					});
					resolve(response);
				}

				if (response.state === 'error') {
					reject(response);
				}
			});
		});
	}

	async signOut() {
		try {
			await this.auth.signOut();
		} catch (error) {
			ErrorUtil.throwError(error);
		}
	}
}
