
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';

export default defineComponent({
	setup() {
		const store = useStore();
		const user = computed(() => store.state.user.contactPerson);
		return {
			user
		};
	}
});
