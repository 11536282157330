import { PayslipType, ProcessedPayslipType, Response } from '@/types';
import { PayslipRepository } from '../repositories/payslip-repository';
import { PayslipQueryConstrants } from '../types';
import { PayslipMutations } from '../types/store-type';
const payslipRepository = new PayslipRepository();

export const createPayslip = async (_: any, payslip: ProcessedPayslipType) => {
	try {
		await payslipRepository.createPayslip(payslip);
	} catch (error) {
		throw error;
	}
};

export const verifyPayslip = async (_: any, payslip: PayslipType) => {
	try {
		await payslipRepository.verifyPayslip(payslip);
	} catch (error) {
		throw error;
	}
};

export const streamPayslips = (
	{ commit }: any,
	{ constraints, response }: { constraints: PayslipQueryConstrants; response: Response }
) => {
	return payslipRepository.streamPayslips(constraints, {
		next: (querySnapshot) => {
			if (querySnapshot.docs.length == 0) {
				response.empty();
			} else {
				response.success();
			}
			commit(PayslipMutations.SET_PAYSLIPS, querySnapshot);
		},
		error: (error) => response.error(error)
	});
};

export const streamPayslip = (
	{ commit }: any,
	{ constraints, response }: { constraints: PayslipQueryConstrants; response: Response }
) => {
	return payslipRepository.streamPayslip(constraints, {
		next: (querySnapshot) => {
			if (querySnapshot.docs.length == 0) {
				response.empty();
			} else {
				response.success();
			}
			commit(PayslipMutations.SET_PAYSLIP, querySnapshot);
		},
		error: (error) => response.error(error)
	});
};
