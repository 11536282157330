import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "check-item-group" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      name: "duties",
      id: _ctx.task.id,
      hidden: "",
      checked: 
                                _ctx.selectedTasks.filter(t => t.id == _ctx.task.id)
                                        .length > 0
                        ,
      onChange: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.selectTaskHandler && _ctx.selectTaskHandler(...args)), ["prevent"]))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.task.id
    }, _toDisplayString(_ctx.task.name), 9, _hoisted_3)
  ]))
}