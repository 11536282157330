
import { computed, defineComponent, inject, reactive, watch } from '@vue/runtime-core';
import TextField from '@/components/TextField.vue';
import { useStore } from '@/store';
import { EmployeeType } from '@/types';
import { ResponseKey } from '@/symbols';
import { EmployeeActions } from '../../types/store-types';
import { cloneDeep } from 'lodash';
export default defineComponent({
	components: {
		TextField
	},
	setup() {
		const store = useStore();
		const employee = computed(() => store.state.employee.employee);
		const response = inject(ResponseKey);
		const form = reactive<EmployeeType>({
			id: employee.value?.id ?? '',
			jobTitle: employee.value?.jobTitle ?? '',
			jobDescription: employee.value?.jobDescription ?? ''
		});

		async function saveJobData() {
			try {
				if (!form.jobTitle || form.jobTitle.length < 3) throw new Error('Invalid job title');
				if (!form.jobDescription || form.jobDescription.length < 3) throw new Error('Invalid job description');
				if (form.jobTitle == employee.value?.jobTitle && form.jobDescription == employee.value.jobDescription)
					return;
				response?.loading();
				await store.dispatch(EmployeeActions.UPDATE_JOB_DATA, cloneDeep(form));
				setTimeout(() => {
					response?.success('Job Data updated!!');
				}, 2000);
			} catch (error) {
				response?.error(error as Error);
			}
		}

		watch(
			() => employee.value,
			() => {
				form.id = employee.value?.id;
				form.jobTitle = employee.value?.jobTitle;
				form.jobDescription = employee.value?.jobDescription;
			}
		);

		return {
			form,
			saveJobData
		};
	}
});
