import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueCropper = _resolveComponent("VueCropper")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!
  const _component_DefaultModal = _resolveComponent("DefaultModal")!

  return (_openBlock(), _createBlock(_component_DefaultModal, {
    options: { title: 'Image Processor', id: 'cropImage', show: !!_ctx.image, type: _ctx.DialogType.MODAL_FULLSCREEN }
  }, _createSlots({
    submit: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn btn-success",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleUpload && _ctx.handleUpload(...args)), ["prevent"]))
      }, "Upload")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_VueCropper, {
        class: "cropper",
        src: _ctx.image,
        "stencil-props": {
				handlers: {},
				movable: false,
				scalable: false,
				aspectRatio: 1
			},
        "resize-image": {
				adjustStencil: false
			},
        "image-restriction": "stencil",
        onChange: _ctx.change
      }, null, 8, ["src", "onChange"])
    ]),
    _: 2
  }, [
    (_ctx.croppedImage)
      ? {
          name: "preview-panel",
          fn: _withCtx(() => [
            _createVNode(_component_ImagePreview, { dataUrl: _ctx.croppedImage }, null, 8, ["dataUrl"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["options"]))
}