export enum PayslipActions {
	CREATE_PAYSLIP = 'payslip/createPayslip',
	VERIFY_PAYSLIP = 'payslip/verifyPayslip',
	STREAM_PAYSLIPS = 'payslip/streamPayslips',
	STREAM_PAYSLIP = 'payslip/streamPayslip'
}

export enum PayslipMutations {
	SET_PAYSLIP = 'SET_PAYSLIP',
	SET_PAYSLIPS = 'SET_PAYSLIPS'
}
