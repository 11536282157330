
import LoadingOrEmpty from '@/components/LoadingOrEmpty.vue';
import { CompanyKey, UserTypeKey } from '@/symbols';
import { LeaveType } from '@/types';
import { Time } from '@/utils';
import { computed, defineComponent, inject, provide, ref, watch } from 'vue';
import { useEmployeeAction } from '../../employee/composables/use-employee';
import LeaveHistoryCard from '../components/LeaveHistoryCard.vue';
import CreateLeave from '../components/modals/CreateLeave.vue';
import useLeave from '../composables/use-leave';
import { LeaveFormKey, RequestLeaveSubmitKey, SelectedEmployeeKey } from '../symbols';
export default defineComponent({
	components: {
		CreateLeave,
		LeaveHistoryCard,
		LoadingOrEmpty
	},
	setup() {
		const user = inject(UserTypeKey);
		const company = inject(CompanyKey);
		const upcomingLeave = ref<LeaveType>();
		const { employee } = useEmployeeAction(user?.value?.id);
		const {
			leaves,
			leavesLoadingStatus: status,
			requestLeave,
			form
		} = useLeave({
			companyId: company?.value ? company.value.id : undefined,
			employeeId: user?.value ? user.value.id : undefined
		});

		const totalUpcomingDays = computed(() =>
			upcomingLeave
				? Time.durationInDays(
						upcomingLeave.value?.duration?.start as Date,
						upcomingLeave.value?.duration?.end as Date
				  )
				: 0
		);

		function arrangeUpcomingLeave() {
			leaves.value.forEach((leave) => {
				if (Time.getTimeDifferenceFromNow(leave.duration?.start! as Date) >= 0) {
					upcomingLeave.value = leave;
					return;
				}
			});
		}

		provide(LeaveFormKey, form);
		provide(RequestLeaveSubmitKey, requestLeave);
		provide(SelectedEmployeeKey, employee);
		watch(
			() => leaves.value,
			() => arrangeUpcomingLeave()
		);

		return {
			leaves,
			status,
			upcomingLeave,
			totalUpcomingDays,
			...Time
		};
	}
});
