// import state from './state';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';
import {
    Store as VuexStore,
    Module
} from 'vuex'

import { RootState } from '@/store'
import { state } from './state'
import type { State } from './state'
export { State }

export const store: Module<State, RootState> ={
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
