import { IStateStatus, PayslipType, ProcessedPayslipType } from '@/types';

export type State = {
	payslip: ProcessedPayslipType | null;
	payslips: Array<ProcessedPayslipType>;
} & IStateStatus;

export const state: State = {
	status: 'LOADING',
	payslip: null,
	payslips: []
};
