import { CompanyType } from '@/types';
import { reactive } from 'vue';

export const useContract = () => {
        const form = reactive<CompanyType>({
                terminationOfContract: 'Testing'
        });

        return {
                form
        };
};
