<template>
	<div class="radial-card" @click.prevent="$props.goTo ? $router.push(goTo) : null">
		<div class="row h-100">
			<div class="col-2 my-auto">
				<i class="fa fa-2x fa-exclamation-triangle text-warning"></i>
			</div>
			<div class="col-10 my-auto">
				<h4>{{ $props.title }}</h4>
				<p>{{ $props.description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			required: true
		},
		goTo: {
			type: String,
			default: null
		}
	}
};
</script>
