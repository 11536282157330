import { billingRepo } from '@/di';
import { Unsubscribe } from 'firebase/firestore';
import { BillingActions } from '../enums';

export interface Actions {
	/**
	 * Stream billings
	 * @param {string} companyId
	 * @returns {Unsubscribe} Unsubscribe
	 */
	[BillingActions.STREAM_BILLINGS]: (companyId: string) => Unsubscribe;
	/**
	 * Stream Billing
	 * @param {string} billingId
	 * @returns {Unsubscribe} Unsubscribe
	 */
	[BillingActions.STREAM_BILLING]: (billingId: string) => Unsubscribe;
}

export const actions: Actions = {
	STREAM_BILLINGS: (companyId) => billingRepo.streamBillings(companyId),
	STREAM_BILLING: (billingId: string) => billingRepo.streamBilling(billingId)
};
