import { RequestStatus } from '@/enums';
import { LeaveType, OnDocumentObserver, OnQueryObserver } from '@/types';
import {
	addDoc,
	doc,
	onSnapshot,
	orderBy,
	query,
	QueryConstraint,
	serverTimestamp,
	Unsubscribe,
	updateDoc,
	where
} from 'firebase/firestore';
import { singleton } from 'tsyringe';
import { LeaveConstraints } from '../types';
import LeaveRepo from './leave-repo';

@singleton()
export default class LeaveRepoImpl extends LeaveRepo {
	leavesDocRef = (leaveId: string) => doc(this.db, 'leaves', leaveId);
	async requestLeave(leave: LeaveType): Promise<any> {
		try {
			return await addDoc(this.leavesRef, {
				companyId: leave.companyId,
				employeeId: leave.employeeId,
				status: RequestStatus.PENDING,
				duration: leave.duration,
				type: leave.type,
				createdAt: serverTimestamp()
			});
		} catch (error) {
			throw error;
		}
	}
	async createLeave(leave: LeaveType): Promise<any> {
		try {
			return await addDoc(this.leavesRef, {
				companyId: leave.companyId,
				employeeId: leave.employeeId,
				status: RequestStatus.APPROVED,
				duration: leave.duration,
				type: leave.type,
				comment: leave.comment,
				createdAt: serverTimestamp()
			});
		} catch (error) {
			throw error;
		}
	}
	respondToRequest(id: string, status: RequestStatus, comment?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}

	streamLeaves({ companyId, status, duration, employeeId }: LeaveConstraints): Unsubscribe {
		let constraints: Array<QueryConstraint> = [where('companyId', '==', companyId)];

		if (status) {
			constraints.push(where('status', '==', status));
		}

		if (employeeId) {
			constraints.push(where('employeeId', '==', employeeId));
		}

		if (duration) {
			constraints.push(where('duration.start', '>=', duration.start));
			constraints.push(where('duration.end', '<=', duration.end));
		}
		let q = query(this.leavesRef, ...constraints, orderBy('createdAt', 'desc'));
		return onSnapshot(q, this.queryObserver(employeeId));
	}

	streamLeave(id: string): Unsubscribe {
		return onSnapshot(this.leavesDocRef(id), this.documentObserver());
	}

	async approveLeave(id: string) {
		try {
			await updateDoc(this.leavesDocRef(id), { status: RequestStatus.APPROVED });
		} catch (error) {
			throw error;
		}
	}

	async declineLeave(id: string, comment: string) {
		try {
			await updateDoc(this.leavesDocRef(id), {
				status: RequestStatus.DECLINED,
				comment
			});
		} catch (error) {
			throw error;
		}
	}
}
