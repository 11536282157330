<template lang="">
    <div class="underconstruction">
        <div class="text-side">
            <h5>Coming Soon</h5>
            <p>
                Our developers are working tirelessly on cooking this page for you. You will be able to see whats
                popping soon.
            </p>
            <h1>
                You Will Be Notified When This Page Is Ready.
            </h1>
        </div>
        <div class="image-side">
            <img src="@/assets/img/design.svg" alt="UnderConstruction">
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="">

</style>