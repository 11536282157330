import LeaveRepo from '@/modules/payroll-accounting/modules/leave/repos/leave-repo';
import LeaveRepoImpl from '@/modules/payroll-accounting/modules/leave/repos/leave-repo-impl';
import { ReconciliationRepo } from '@/modules/payroll-accounting/modules/reconciliation/repos/reconciliation-repo';
import { ReconciliationRepoImpl } from '@/modules/payroll-accounting/modules/reconciliation/repos/reconciliation-repo-impl';
import { LocationRepo } from '@/repositories/abstract/location-repo';
import { LocationRepoImpl } from '@/repositories/location-repo-impl';
import BaseEmployeeRepository from '@/modules/payroll-accounting/modules/employee/repositories/base-employee-repository';
import { container } from 'tsyringe';
import EmployeeRepository from '@/modules/payroll-accounting/modules/employee/repositories/employee-repository';
import { BillingRepo } from '@/core/modules/billing/repos/billing-repo';
import { BillingRepoImpl } from '@/core/modules/billing/repos/billing-repo-impl';

export const reconciliationRepo = container.resolve<ReconciliationRepo>(ReconciliationRepoImpl);
export const locationRepo = container.resolve<LocationRepo>(LocationRepoImpl);
export const leaveRepo = container.resolve<LeaveRepo>(LeaveRepoImpl);
export const employeeRepo = container.resolve<BaseEmployeeRepository>(EmployeeRepository);
export const billingRepo = container.resolve<BillingRepo>(BillingRepoImpl);
