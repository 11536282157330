
import { Enum } from '@/enums';
import { TransformEnum } from '@/utils';
import { defineComponent } from 'vue';
import { useTeaTimes } from '../composables/use-company';
export default defineComponent({
	setup() {
		const groups = TransformEnum.getValues(Enum.Groups);
		return {
			...useTeaTimes(),
			groups
		};
	}
});
