
import { useStore } from '@/store';
import { computed, defineComponent, PropType } from 'vue';
import { PerfomanceTaskType } from '@/types';
import { PerfomanceContractActions } from '../../perfomance-contract/types/store-type';
import cloneDeep from 'lodash.clonedeep';

export default defineComponent({
        props: {
                task: {
                        type: Object as PropType<PerfomanceTaskType>,
                        required: true
                }
        },
        setup(props) {
                const store = useStore();
                const task = computed(() => props.task);
                const selectedTasks = computed(
                        () => store.state.perfomanceContract.selectedTasks
                );

                const selectTaskHandler = () => {
                        store.dispatch(
                                PerfomanceContractActions.selectPerfomanceTask,
                                cloneDeep(task.value)
                        );
                };
                return { task, selectedTasks, selectTaskHandler };
        }
});
