import { Enum } from '@/enums';
import { CompanyTimes } from '@/types';
import { dayFormat, TransformEnum } from '@/utils';

export const defaultWorkingHours = () => {
	let workingHours: CompanyTimes.WorkingHours[] = [];
	let days: string[] = TransformEnum.getValues(Enum.DaysOfTheWeek) as string[];

	for (let index = 0; index < days.length; index++) {
		let hours: CompanyTimes.WorkingHours = {
			key: days[index],
			isActive: false,
			duration: {
				start: undefined,
				end: undefined
			}
		};
		workingHours.push(hours);
	}
	return workingHours;
};

export const workingHourToJson = (workingHours: CompanyTimes.WorkingHours[]) => {
	let arr: Array<{}> = [];
	for (let index = 0; index < workingHours.length; index++) {
		const element = workingHours[index];
		arr.push({
			key: element.key,
			duration:
				element.duration?.start == undefined || element.duration?.end == undefined
					? { start: '', end: '' }
					: element.duration,
			isActive: element.isActive
		});
	}
	return arr;
};

export const payDaysGenerator = () => {
	let paydays: string[] = [];
	let MAX_DAYS = 30;
	for (let index = 1; index <= MAX_DAYS; index++) {
		if (index == 29) {
			paydays.push(Enum.PayDays.DAY_BEFORE_MONTH_END);
		} else if (index == 30 || index == 1 || index == 15 || index == 25) {
			continue;
		} else {
			paydays.push(index.toString());
		}
	}
	return paydays;
};

export const allPaydays = () => {
	let paydays: CompanyTimes.PaydayInOrder[] = [];
	let MAX_DAYS = 30;
	let paydayInOrder: CompanyTimes.PaydayInOrder = {};
	for (let index = 1; index <= MAX_DAYS; index++) {
		paydayInOrder.position = index;
		paydayInOrder.isActive = false;
		if (index == 29) {
			paydayInOrder.day = Enum.PayDays.DAY_BEFORE_MONTH_END;
		} else if (index == 30) {
			paydayInOrder.day = Enum.PayDays.MONTH_END;
		} else {
			paydayInOrder.day = index.toString();
		}

		paydays.push(paydayInOrder);
		paydayInOrder = {};
	}
	return paydays;
};

export const defaultPaydays = () => {
	let paydays: CompanyTimes.PayDay[] = [
		{ day: '1', isActive: false },
		{ day: '15', isActive: false },
		{ day: '25', isActive: false },
		{ day: Enum.PayDays.MONTH_END, isActive: false }
	];
	return paydays;
};

export const paydaysToJson = (paydays: CompanyTimes.PayDay[]) => paydays.map((payday) => ({ ...payday }));

export const dayText = (day?: string | number) => {
	let formatedDay: string = dayFormat(day!) as string;
	if (formatedDay.length < 5) return formatedDay + ' day of the month';
	return formatedDay;
};

export const orderPaydays = (paydays: CompanyTimes.PayDay[]) => {
	const defaultPaydays = allPaydays();

	let orderedPaydays: CompanyTimes.PaydayInOrder[] = [];
	for (let index = 0; index < paydays.length; index++) {
		const element = paydays[index];

		const payday = defaultPaydays.filter((p) => p.day == element.day);

		if (payday.length > 0) {
			payday[0].isActive = element.isActive;
			orderedPaydays.push(payday[0]);
		}
	}

	if (paydays.length == orderedPaydays.length) {
		orderedPaydays.sort((a, b) => a.position! - b.position!);
		const newPaydays: CompanyTimes.PayDay[] = [];

		for (let index = 0; index < orderedPaydays.length; index++) {
			const { position, ...other } = orderedPaydays[index];
			newPaydays.push(other);
		}
		return newPaydays;
	}
	return null;
};
