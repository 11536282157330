import { UploadFolder } from '@/enums';
import { Status } from '@/types';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import BaseUploadRepository from './abstract/base-upload-repository';
class UploadRepository extends BaseUploadRepository {
	private storage = getStorage();

	downloadFileFromFirebaseStorage(id: string, folder: UploadFolder): Promise<string | ArrayBuffer | null> {
		const fileRef = ref(this.storage, `${folder}${id}`);
		return new Promise((resolve) => {
			getDownloadURL(fileRef).then((url) => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = (event) => {
					const blob = xhr.response;
					const reader = new FileReader();
					reader.onloadend = () => {
						const base64String = reader.result;
						resolve(base64String);
					};
					reader.readAsDataURL(blob);
				};
				xhr.open('GET', url);
				xhr.send();
			});
		});
	}

	async uploadPdf(
		base64string: string,
		uploadProgress: Function,
		status: Function,
		id: string,
		folder: UploadFolder
	): Promise<void> {
		let contentType = 'application/pdf';
		let bytePdf = this.b64toBlob(base64string, contentType);
		let pdfRef = ref(this.storage, `${folder}${id}`);
		const uploadTask = uploadBytesResumable(pdfRef, bytePdf, { contentType });

		uploadTask.on('state_changed', {
			next: (snapshot) => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				uploadProgress(progress);
			},
			error: (error) => {
				status(false, error.message);
			},
			complete: () => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
					status(true, downloadUrl);
				});
			}
		});
	}

	async uploadAvatar(base64Image: string, uploadProgress: Function, status: Function, id: string): Promise<void> {
		let imageString = base64Image.split(',')[1];
		let contentType = base64Image.split(';')[0].split(':')[1];

		let byteImage = this.b64toBlob(imageString, contentType);
		let imageRef = ref(this.storage, `avatar/${id}`);

		const uploadTask = uploadBytesResumable(imageRef, byteImage, { contentType });

		uploadTask.on('state_changed', {
			next: (snapshot) => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				uploadProgress(progress);
			},
			error: (error) => {
				console.log(error);

				status(false, error.message);
			},
			complete: () => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
					status(true, downloadUrl);
				});
			}
		});
	}

	uploadProgress(progress: number) {
		return progress;
	}

	status = (success: boolean, data: string | null): Status => ({
		success,
		data
	});

	private b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	};
}

export default UploadRepository;
