import { EmploymentContractType } from '@/types';

export type State = {
    contract: EmploymentContractType | null;
    contracts: Array<EmploymentContractType>;
};

export const state: State = {
    contract: null,
    contracts: []
};
