import { Enum } from '@/enums';
import { ClockType } from '@/types';
import { Time } from '@/utils';

export function totalWorkTime(clocks: ClockType[]) {
	let lunchDuration = 0;
	let teaDuration = 0;
	let workDuration = 0;

	for (let index = 0; index < clocks.length; index++) {
		const element = clocks[index];
		switch (element.type) {
			case Enum.ClockType.LUNCH:
				lunchDuration = Time.calculateTotalMinutes(element.duration!);
				break;
			case Enum.ClockType.ON_DUTY:
				workDuration = Time.calculateTotalMinutes(element.duration!);
				break;
			case Enum.ClockType.TEA:
				teaDuration = Time.calculateTotalMinutes(element.duration!);
				break;
		}
	}

	return {
		lunch: Time.minuteToITime(lunchDuration),
		tea: Time.minuteToITime(teaDuration),
		onDuty: Time.minuteToITime(workDuration),
		totalTime: Time.minuteToITime(workDuration - (lunchDuration + teaDuration))
	};
}
