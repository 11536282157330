import { EmployeeType, PerfomanceTaskType } from '@/types';
import { ComputedRef, InjectionKey } from 'vue';

export const BasicInfoFormKey: InjectionKey<EmployeeType> = Symbol('basicInfoForm');

export const SubmitBasicInfoKey: InjectionKey<Function> = Symbol('submitBasicInfo');

export const EmploymentInfoKey: InjectionKey<EmployeeType> = Symbol('employmentInfo');

export const RemunerationFormKey: InjectionKey<EmployeeType> = Symbol('remunerationForm');

export const NextStepKey: InjectionKey<Function> = Symbol('nextStep');

export const PerfomanceTasksKey: InjectionKey<ComputedRef<Array<PerfomanceTaskType>>> = Symbol('perfomanceTasks');

export const EmployeeKey: InjectionKey<ComputedRef<EmployeeType | null>> = Symbol();
