export class UIF {
	rate: number;
	salary: number;
	private readonly ceiling: number = 17712;
	constructor(salary = 0) {
		this.rate = 1 / 100;
		this.salary = salary;
	}

	tax() {
		return (this.isHighSalary ? this.ceiling : this.salary) * this.rate;
	}

	get isHighSalary() {
		return this.salary > this.ceiling;
	}
}
