import { ClockType, IStateStatus } from '@/types';

export type State = {
	clock: ClockType | null;
	clocks: Array<ClockType>;
} & IStateStatus;

export const state: State = {
	status: 'LOADING',
	clock: null,
	clocks: []
};
