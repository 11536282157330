import { Endpoint } from '@/enums';
import axios from 'axios';

const instance = axios.create({
	baseURL: 'http://api.kunokhar.com/api/generate-pdf',
	headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
});

export const postRequest = async (endpoint: Endpoint, data: any) => {
	return await instance.post(endpoint, data);
};
