<template>
	<div id="employment_contract">
		<div class="em-side-bar">
			<BackButton />
			<div class="content">
				<button type="button">Cover Page</button>
				<button type="button">Commencement of employment</button>
				<button type="button">Job description of the employee</button>
				<button type="button">Termination Of Contract</button>
				<button type="button">Wage / Salary</button>
			</div>
		</div>
		<div class="main-side">
			<div class="paper">
				<div class="content">
					<h3>Create Employment Contract</h3>
					<form action="#" method="post">
						<div class="row">
							<div class="col-sm-8"></div>
							<div class="col-sm-4">
								Some buttons
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SecParagraph from '../components/SecParagraph.vue';
import SecTitle from '../components/Title.vue';
import SecList from '../components/List.vue';
import BackButton from '@/components/BackButton.vue';
import { defineComponent } from '@vue/runtime-core';
import useCompany from '@/core/modules/company/composables/use-company';
import { useContract } from '../composables/use-contract';
import { addressWithLineBreak } from '@/utils';
export default defineComponent({
	components: {
		SecParagraph,
		SecTitle,
		BackButton,
		SecList
	},
	setup() {
		const { company } = useCompany();
		const { form } = useContract();
		return {
			company,
			addressWithLineBreak,
			form
		};
	}
});
</script>
