<template>
	<div id="registration_page">
		<div class="content">
			<BackButton :routerLink="'/'" />
			<div class="registration-steps">
				<StepIndicator
					v-for="(s, index) in steps"
					:stepName="s.name"
					:stepNumber="s.step"
					:class="s.isActive ? 'active-step' : step > s.step && 'completed-step'"
					:key="index"
				/>
			</div>

			<div class="form-part">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import StepIndicator from '../components/StepIndicators.vue';
import BackButton from '../../../../components/BackButton.vue';
import BasicInfo from '../components/company-reg/BasicInfo.vue';
import CompanyInfo from '../components/company-reg/CompanyInfo.vue';
import ReviewReg from '../components/company-reg/ReviewReg.vue';
import Payment from '../components/company-reg/Payment.vue';
import useRegister from '../composables/use-register';
import { defineComponent, provide } from 'vue';
import { BasicInfoKey, CompanyInfoKey, SubmitBasicInfoKey, SubmitCompanyInfoKey } from '../symbols';
export default defineComponent({
	components: {
		StepIndicator,
		BackButton,
		BasicInfo,
		CompanyInfo,
		ReviewReg,
		Payment
	},
	setup() {
		const {
			step,
			steps,
			basicForm,
			companyForm,
			submitBasicInfo,
			submitCompanyInfo,
			navigateToPayment
		} = useRegister();
		provide(BasicInfoKey, basicForm);
		provide(SubmitBasicInfoKey, submitBasicInfo);
		provide(CompanyInfoKey, companyForm);
		provide(SubmitCompanyInfoKey, submitCompanyInfo);
		provide('navigateToPayment', navigateToPayment);
		return {
			step,
			steps,
			basicForm,
			companyForm,
			submitBasicInfo,
			submitCompanyInfo,
			navigateToPayment
		};
	}
});
</script>
