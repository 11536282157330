import type { Getters } from './getters';
import { type State, state } from './state';
import { type Actions, actions } from './actions';
import { defineStore } from 'pinia';

const leaveStore = defineStore<'leaves', State, Getters, Actions>('leaves', {
	state: () => state,
	actions
});

type LeaveStore = ReturnType<typeof leaveStore>;

export { leaveStore, type LeaveStore };
