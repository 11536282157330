
        import { computed, defineComponent, PropType } from 'vue';
        import { PerfomanceTaskType } from '@/types';
        import Duty from '../Duty.vue';
        export default defineComponent({
                components: { Duty },
                props: {
                        tasks: {
                                type: Array as PropType<Array<PerfomanceTaskType>>,
                                required: true
                        }
                },
                setup(props) {
                        const tasks = computed(() => props.tasks);
                        return {
                                tasks
                        };
                }
        });
