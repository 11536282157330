
import TextField from '@/components/TextField.vue';

import { defineComponent } from '@vue/runtime-core';
import usePerfomanceTask from '../../composables/use-perfomance-task';
export default defineComponent({
	components: {
		TextField
	},
	setup() {
		const { form, submitForm } = usePerfomanceTask();
		return {
			form,
			submitForm
		};
	}
});
