import { CreateEmployeeType, EmployeeType, UserType } from '@/types';
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import { State } from '.';
import { v4 } from 'uuid';
import { PaymentInterval } from '@/enums';
import EmployeeRepository from '../repositories/employee-repository';

export const SET_EMPLOYEES = (state: State, data: { docs: QueryDocumentSnapshot<DocumentData>[]; user: UserType }) => {
	let docs = data.docs.filter((doc) => doc.id != data.user.id);
	let employeesToUpdateSalary: CreateEmployeeType[] = [];
	state.employees = docs.map((doc) => {
		const data = doc.data();
		let employee: EmployeeType = {
			id: doc.id,
			...data
		};

		if (data['salary'] && !employee.salaries) {
			employee.salaries = [{ amount: data['salary'], id: v4(), createdAt: new Date() }];
			employeesToUpdateSalary.push({
				id: employee.id,
				salary: data['salary'],
				paymentInterval: employee.paymentInterval ?? PaymentInterval.MONTHLY
			});
		}
		return employee;
	});
	state.status = docs.length > 0 ? 'SUCCESS' : 'EMPTY';
	state.employees = state.employees.map((employee) => {
		employee.role = employee.role?.toLowerCase();
		if (employee.role?.includes('_')) employee.role = employee.role.split('_')[0];
		return employee;
	});

	if (employeesToUpdateSalary.length > 0) {
		employeesToUpdateSalary.forEach(async (employee) => {
			await new EmployeeRepository().addEmployeeRemunerationInfo(employee);
		});
	}
};
export const SET_EMPLOYEE = (state: State, doc: DocumentSnapshot) => {
	if (!doc.exists()) {
		state.employee = null;
		return;
	}
	let employeeToUpdateSalary: CreateEmployeeType | undefined;
	const data = doc.data();
	state.employee = {
		id: doc.id,
		...data
	};

	if (data['salary'] && state.employee && !state.employee.salaries) {
		state.employee.salaries = [{ amount: data['salary'], id: v4(), createdAt: new Date() }];
		employeeToUpdateSalary = {
			id: state.employee.id,
			salary: data['salary'],
			paymentInterval: state.employee.paymentInterval ?? PaymentInterval.MONTHLY
		};
	}

	state.employee.role = state.employee.role?.toLowerCase();
	if (state.employee.role?.includes('_')) state.employee.role = state.employee.role.split('_')[0];

	if (employeeToUpdateSalary) {
		new EmployeeRepository().addEmployeeRemunerationInfo(employeeToUpdateSalary);
	}
};
