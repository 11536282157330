import { LocationRepo } from './abstract/location-repo';
import { singleton } from 'tsyringe';
import axios from 'axios';
@singleton()
export class LocationRepoImpl extends LocationRepo {
	getCoordinates(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				return navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
					this.coordinate = position.coords;
					console.log(position.coords);
					resolve();
				});
			} else {
				reject(new Error('Geolocation is not supported by this browser.'));
			}
		});
	}
	async getAddress(): Promise<string> {
		if (this.coordinate) {
			const axiosInstance = axios.create({
				headers: { 'Content-Type': 'application/json' }
			});
			const data = await axiosInstance.get(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.coordinate.latitude},${this.coordinate.longitude}&key=${process.env.VUE_APP_GMAPS_KEY}`
			);
			console.log();

			return data.data.results[0].formatted_address;
		} else {
			throw new Error('No coordinates found');
		}
	}
	get gmaps(): Promise<string> {
		throw new Error('Method not implemented.');
	}
}
