import useCompany from '@/core/modules/company/composables/use-company';
import { useAuth } from '@/core/modules/user/composables/use-auth';
import { Enum, LocalStorageKeys } from '@/enums';
import { PAYE, UIF } from '@/modules/payroll-accounting/services';
import { LocalStorageRepository } from '@/repositories/local-storage-repository';
import router from '@/router';
import { useStore } from '@/store';
import { CompanyKey, ResponseKey } from '@/symbols';
import { DeductionType, EmployeeType, PayslipType, Response as ResponseType, SalaryType, StreamType } from '@/types';
import { Unsubscribe } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { computed, inject, onBeforeMount, onBeforeUnmount, onMounted, provide, reactive, ref, watch } from 'vue';
import { useEmployeeAction } from '../../employee/composables/use-employee';
import { PayslipFormKey } from '../symbols';
import { PayslipQueryConstrants } from '../types';
import { PayslipActions } from '../types/store-type';
import { preparePeriod } from '../utils';
import { ProcessedPayslipType } from '@/types';
import { EmployeeGetters } from '../../employee/types/store-types';
import { addressFormat, Time } from '@/utils';
import { convertToTwoDecimalPlace } from '@/modules/payroll-accounting/utils/helper';

const payslipTemplate: ProcessedPayslipType = {
	period: undefined,
	verified: false,
	company: undefined,
	employee: undefined,
	deductions: []
};
export const usePayslip = ({
	streamType = 'BULK',
	employeeId,
	verified
}: {
	streamType?: StreamType;
	employeeId?: string;
	verified?: boolean;
}) => {
	const store = useStore();
	const { company } = useCompany();
	const response = inject(ResponseKey);
	const { employee } = useEmployeeAction(employeeId);
	const payslips = computed(() => store.state.payslip.payslips);
	const payslip = computed(() => store.state.payslip.payslip);
	const status = computed(() => store.state.response.status);
	const payslipsLoadingStatus = computed(() => store.state.payslip.status);
	const form = reactive<ProcessedPayslipType>(payslipTemplate);
	const constraints = reactive<PayslipQueryConstrants>({
		employeeId: employeeId,
		companyId: '',
		period: '',
		verified: verified
	});
	const salary = ref<number>(0);
	const computations = calculations(constraints, response!);
	let unsubscribe: Unsubscribe | null;
	const currentSalary = computed(() => store.getters[EmployeeGetters.CURRENT_SALARY]);

	provide(PayslipFormKey, form);

	watch(
		() => company.value,
		() => {
			constraints.companyId = company.value?.id;
			form.company = {
				id: company.value?.id,
				name: company.value?.name,
				address: addressFormat(company.value?.address!),
				logoUrl: company.value?.logo
			};
			setup();
		}
	);

	watch(
		() => employee.value,
		() => {
			form.employee = {
				id: employee.value?.id,
				name: employee.value?.name + ' ' + employee.value?.surname,
				salary: currentSalary.value,
				employmentDate: employee.value?.employmentDate,
				idNumber: employee.value?.idNumber
			};
			let empDate = employee.value?.employmentDate! as string;
			const period = preparePeriod(empDate, employee.value?.payday!);
			constraints.period = router.currentRoute.value.params.period
				? (router.currentRoute.value.params.period as string)
				: period.length > 0
				? period[0]
				: '';
			const salaries = employee.value?.salaries;
			if (salaries) {
				salary.value = salaries[salaries.length - 1].amount;
			}
		}
	);

	watch(
		() => constraints.period,
		() => {
			setup();
		}
	);

	watch(
		() => constraints,
		() => {
			setup();
		}
	);

	async function createPayslip(deductions: Array<DeductionType>) {
		try {
			response?.loading();
			form.deductions = deductions;
			await store.dispatch(PayslipActions.CREATE_PAYSLIP, cloneDeep(form));
			Object.assign(form, payslipTemplate);
			response?.success('Payslip created successfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	async function requestPayslip(payslip: ProcessedPayslipType) {
		try {
			let payslipRequest: ProcessedPayslipType;
			if (payslip) {
				form.period = payslip.period ?? constraints.period;
				form.company = payslip.company;
				form.employee = payslip.employee;
				form.deductions = payslip.deductions;
				payslipRequest = cloneDeep(form);
			} else {
				const slip: ProcessedPayslipType = {
					period: constraints.period,
					verified: false,
					company: {
						id: company.value?.id,
						name: company.value?.name,
						logoUrl: company.value?.logo,
						address: addressFormat(company.value?.address!)
					},
					employee: {
						id: employee.value?.id,
						name: employee.value?.name + ' ' + employee.value?.surname,
						idNumber: employee.value?.idNumber,
						jobTitle: employee.value?.jobTitle,
						employmentDate: Time.toDefaultString(employee.value?.employmentDate!),
						salary: convertToTwoDecimalPlace(salary.value)
					},
					grossIncome: convertToTwoDecimalPlace(salary.value),
					deductions: [
						{
							name: 'UIF',
							amount: uifTaxFn()
						},
						{
							name: 'PAYE',
							amount: payAsYouEarnFn()
						}
					],
					totalDeductions: convertToTwoDecimalPlace(uifTaxFn() + payAsYouEarnFn()),
					netIncome: convertToTwoDecimalPlace(salary.value - (uifTaxFn() + payAsYouEarnFn()))
				};
				payslipRequest = slip;
			}
			form.verified = false;
			response?.loading();
			console.log(payslip);
			console.log(payslipRequest);

			await store.dispatch(PayslipActions.CREATE_PAYSLIP, payslipRequest);
			Object.assign(form, payslipTemplate);
			response?.success('Payslip requested!!');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	const payAsYouEarnFn = () => {
		const paye = new PAYE(
			salary.value,
			employee.value?.employmentDate?.toString(),
			employee.value?.idNumber!,
			constraints.period!
		);

		return paye.tax();
	};

	const uifTaxFn = () => {
		const uif = new UIF(salary.value);
		return uif.tax();
	};

	async function verifyPayslip(payslip: ProcessedPayslipType) {
		try {
			response?.loading();
			await store.dispatch(PayslipActions.VERIFY_PAYSLIP, cloneDeep(payslip));
			response?.success('Payslip verified successfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}
	async function downloadPayslip(payslipUrl: string) {
		window.open(payslipUrl, '__blank');
	}
	async function printPayslip(payslipUrl: string) {
		window.open(payslipUrl, '__blank');
	}

	async function setup() {
		if (!constraints.companyId || (!constraints.employeeId && streamType == 'EMPLOYEE_BULK')) return;
		form.id = payslip.value?.id;
		form.company = {
			id: company.value?.id,
			name: company.value?.name,
			address: addressFormat(company.value?.address!),
			logoUrl: company.value?.logo
		};
		form.employee = {
			id: employee.value?.id,
			name: employee.value?.name + ' ' + employee.value?.surname,
			salary: currentSalary.value,
			employmentDate: employee.value?.employmentDate,
			idNumber: employee.value?.idNumber
		};
		form.period = constraints.period;
		console.log(streamType);

		if (streamType != 'SINGLE') {
			unsubscribe = await store.dispatch(PayslipActions.STREAM_PAYSLIPS, {
				response,
				constraints: cloneDeep(constraints)
			});
			if (employeeId) computations.calculateTax();
		} else {
			unsubscribe = await store.dispatch(PayslipActions.STREAM_PAYSLIP, {
				response,
				constraints: cloneDeep(constraints)
			});
		}
	}

	onBeforeMount(() => {
		setup();
	});

	onBeforeUnmount(() => {
		if (unsubscribe != null) unsubscribe();
	});

	return {
		status,
		company,
		employee,
		payslip,
		payslips,
		payslipsLoadingStatus,
		createPayslip,
		requestPayslip,
		verifyPayslip,
		downloadPayslip,
		printPayslip,
		constraints,
		preparePeriod,
		salary,
		currentSalary,
		...computations
	};
};

export function useSelfPayslip() {
	const { user } = useAuth();
	const {
		payslips: remotePayslips,
		preparePeriod,
		constraints,
		downloadPayslip,
		requestPayslip
	} = usePayslip({
		streamType: 'EMPLOYEE_BULK',
		employeeId: user.value?.uid
	});
	const company = inject(CompanyKey);
	const payslips = ref<ProcessedPayslipType[]>([]);
	const employee = ref<EmployeeType>();
	const periods = ref<string[]>();
	const latestPayslip = ref<ProcessedPayslipType>();
	const store = useStore();
	const status = computed(() => store.state.payslip.status);
	const salary = ref<SalaryType>();

	watch(
		() => [remotePayslips.value, company?.value],
		() => loadPayslips()
	);

	function loadPayslips() {
		constraints.employeeId = user.value?.uid;

		employee.value = new LocalStorageRepository().getData(LocalStorageKeys.LOCAL_USER) as EmployeeType;
		payslips.value = [];
		if (employee.value && company?.value) {
			const salaries = employee.value.salaries;

			if (salaries?.length) {
				salary.value = salaries[salaries.length - 1];
			}
			periods.value = preparePeriod(employee.value.employmentDate as string, employee.value.payday!);
			if (periods.value.length > 0) {
				for (let index = 0; index < periods.value.length; index++) {
					const period = periods.value[index];
					let containsPayslip: boolean =
						remotePayslips.value &&
						remotePayslips.value.length > 0 &&
						remotePayslips.value.filter((p) => p.period == period).length > 0;
					try {
						if (remotePayslips.value && remotePayslips.value.length > 0 && containsPayslip) {
							payslips.value.push(remotePayslips.value.filter((p) => p.period == period)[0]);
						} else {
							payslips.value.push({
								period,
								verified: false,
								deductions: [
									{ name: 'PAYE', amount: payAsYouEarnFn(period) },
									{ name: 'UIF', amount: uifTaxFn() }
								],
								employee: {
									id: employee.value.id,
									salary: salary.value?.amount!,
									employmentDate: employee.value.employmentDate,
									idNumber: employee.value.idNumber,
									jobTitle: employee.value.jobTitle
								},
								company: {
									address: addressFormat(company.value.address!),
									id: company.value.id,
									name: company.value.name,
									logoUrl: company.value.logo
								},
								netIncome: convertToTwoDecimalPlace(
									salary.value?.amount! - (payAsYouEarnFn(period) + uifTaxFn())
								),
								grossIncome: convertToTwoDecimalPlace(salary.value?.amount ?? 0),
								totalDeductions: convertToTwoDecimalPlace(uifTaxFn() + payAsYouEarnFn(period))
							});
						}
					} catch (error) {
						continue;
					}
				}
			}

			if (payslips.value.length > 0) {
				latestPayslip.value = payslips.value[0];
				payslips.value.splice(0, 1);
			}
		}
	}

	const payAsYouEarnFn = (period: string) => {
		const paye = new PAYE(
			salary.value?.amount,
			employee.value?.employmentDate?.toString(),
			employee.value?.idNumber!,
			period
		);

		return paye.tax();
	};

	const uifTaxFn = () => {
		const uif = new UIF(salary.value?.amount);
		return uif.tax();
	};

	onMounted(() => {
		loadPayslips();
	});

	return {
		company,
		payslips,
		employee,
		periods,
		latestPayslip,
		store,
		status,
		downloadPayslip,
		requestPayslip
	};
}

const calculations = (constraints: PayslipQueryConstrants, response: ResponseType) => {
	const store = useStore();
	const employee = computed(() => store.state.employee.employee);
	const payslip = computed(() => store.state.payslip.payslip);
	const salary = ref<SalaryType>();
	const deductions = reactive<Array<DeductionType>>([
		{
			name: 'PAYE',
			amount: 0
		},
		{
			name: 'UIF',
			amount: 0
		}
	]);

	var netDeduction = ref(0);
	var nettPay = ref(0);
	watch(
		() => constraints.period,
		() => {
			const salaries = employee.value?.salaries;
			if (salaries && salaries.length > 0) {
				salary.value = salaries[salaries.length - 1];
				try {
					netDeduction.value = 0;
					calculateTax();
					netDeduction.value = deductions.reduce((total, current) => total + current.amount!, 0);
					nettPay.value = salary.value!.amount - netDeduction.value;
				} catch (e) {
					netDeduction.value = 0;
					deductions[0].amount = 0;
					deductions[1].amount = 0;
					nettPay.value = salary.value!.amount;
					let error: Error = e as Error;
					response.error(error);
				}
			} else {
				salary.value = undefined;
			}
		}
	);
	watch(
		() => [employee.value, payslip.value],
		() => {}
	);

	function calculateTax() {
		if (employee) {
			deductions[0].amount = payAsYouEarnFn();
			deductions[1].amount = uifTaxFn();
		} else {
			deductions[0].amount = 0;
			deductions[1].amount = 0;
		}
	}

	const payAsYouEarnFn = () => {
		const paye = new PAYE(
			salary.value?.amount,
			employee.value?.employmentDate?.toString(),
			employee.value?.idNumber!,
			constraints.period!
		);

		return paye.tax();
	};

	const uifTaxFn = () => {
		const uif = new UIF(salary.value?.amount);
		return uif.tax();
	};

	return {
		deductions,
		netDeduction,
		nettPay,
		calculateTax
	};
};
