export const nameError = (name: String) => {
  if (name.length < 2) {
    return "Name should be like John Doe";
  }
  let names = name.split(" ");
  if (names.length < 2) return "Please provide your  first and last name";
  if (names[0].length < 2 || names[1].length < 2)
    return "Either first or last name is too short";
  return null;
};

export const emailError = (email: String) => {
  if (email.length == 0) return "Please provide your email";
  return null;
};

export const passwordError = (password: String) => {
  if (password.length < 6)
    return "Password is too short, should be at least 6 characters";
  return null;
};
