import { Timestamp } from '@firebase/firestore';

export const convertToTwoDecimalPlace = (number: number) => {
    let formattedStr = number.toFixed(2);
    let strArray = formattedStr.split('.');
    if (strArray.length > 1)
        return parseInt(strArray[0]) + parseInt(strArray[1]) / 100;
    else return number;
};

export const convertTimestampToDate = (date: Date | Timestamp) =>
    date instanceof Timestamp ? date.toDate() : date;
