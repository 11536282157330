<template>
    <div id="welcome_page">
        <div class="navigation">
            <img src="@/assets/img/kunokhar-logo.png" alt="">
            <div class="nav-links">
                <div class="nav-btns">
                    <a href="#">About</a>
                    <a href="#">Pricing</a>
                    <a href="#">Contact Us</a>
                </div>
                <router-link to="/login" class="login-btn">Login</router-link>
            </div>
        </div>
        <div class="main-content">
            <div class="hero-page">
                <div class="hero-content">
                    <div class="description">
                        <h1>Take Control of your business with <span>Kunokhar Accounting</span> App</h1>
                        <p>
                            We live in a fast-moving world with all the compliance demands. There is really no time to monitor every business action, and the desire to have everything working out well is always there. Kunokhar is coming to your aid with Kunokhar Payroll!
                        </p>
                        <router-link to="/register" class="register-btn">Register Business</router-link> 
                        <router-link class="how-to-register" to="/">How to register</router-link>
                    </div>
                </div>
                <div class="image-side">
                    <img src="@/assets/img/features.png" alt="Features Image">
                </div>
            </div>

            <div class="about">
                <h4 class="mini-title">What is Kunokhar Payroll, what do they offer?</h4>
                <p>
                    We live in a fast-moving world with all the compliance demands. There is really no time to monitor every business action and the desire to have everything working out well is always there. Kunokhar Payroll comes at a time when compliance is at the core and every supporting document is important to support business decisions. It is the best payroll software designed to meet Department of Labour expectations by making employees be at the center of it. Management now is given an opportunity to understand its payroll better and to give its employees autonomy about their documents. This is an amazing initiative for both sparing times for the management to focus on other demanding areas and allowing employees access to their basic information without contacting or disturbing the employer, the feeling that is brought by this app is as good as the new happy feeling it will bring to your organization.
                </p>
                
                <p>
                    Kunokhar Payroll has two types of users <strong>Company Admin</strong> and <strong>Employee</strong> and they have different roles. Company admin adds employees in the system and they also add other information about the company. Its only the company admin has to sign up, employees do not.
                </p>

                <div class="about-kunokhar">
                    <div class="row">
                        <div class="row">
                            <div class="col-sm-5 text-center">
                                <img src="@/assets/img/about-kunokhar.png" alt="Kunokhar CPT Logo">
                            </div>
                            <div class="col-sm-7">
                                <h4 class="mini-title">What about Kunokhar?</h4>
                                <p>
                                    Kunokhar Chartered Accountants & Tax Practitioners (Pty) Ltd “Kunokhar CTP” is the biggest, most innovative and a leading accounting firm around Mthatha. We are a financial services company that provides financial consulting solutions tailored to your entity, irrespective of its size.
                                </p>
                                <p>
                                    We understand that it is our responsibility to look after our clients by providing strategic business advice and full accountancy services. Covid taught us that we have to do other thing remotely and from then we have been trying by all means to offer our services online, starting with our storage system called <a href="http://www.storage.kunokhar.com" target="_blank" rel="noopener noreferrer">Kunokhar Storage</a>. We are running a business easy for you.
                                </p>
                                <a href="http://www.kunokhar.com" target="_blank" rel="noopener noreferrer" class="border-btn">More About Kunokhar CTP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="features">
                <h4 class="mini-title">What are the system Features?</h4>
                <div class="row">
                    <div class="col-md-5">
                        <div class="col-sm-12">
                            <h5>Employee Management</h5>
                            <p>
                                Manage your employees working times (Clock in / Clock out, working days, tea time and lunch times).
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <h5>Clock In / Clock Out</h5>
                            <p>
                                Employees can clock in and clock out by just signing in and click the button. They can also tell when they are taking their lunch on their assigned times.
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <h5>Payslips</h5>
                            <p>
                                You can be to view and download your payslips as an employee. As admin/company you can view and edit payslips.
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <h5>Contracts</h5>
                            <p>
                                Employees can view and download their contracts and company can edit leave general terms.
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <h5>Leaves</h5>
                            <p>
                                You are able to keep track of your leaves and request leave and wait for approval from your company admin. Company admin can assign and approve leave request.
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <h5>Duties / Responsibilities</h5>
                            <p>
                                This makes it easy for employer to assign duties to employees, clearly shows what the employee will be doing.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="img-side-2">
                            <img src="@/assets/img/screens.png" alt="App Screens">
                        </div>
                    </div>
                </div>
            </div>

            <div class="pricing">
                <h4 class="mini-title">What is the pricing plan?</h4>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="package-text">
                            <h4>Save more</h4>
                            <h2>With Basic Plan</h2>
                            <p>This is a scalable system that saves your time. You don't don't need an administrator when you can do it yourself.</p>
                            <div class="fees-list">
                                <ul class="list-unstyled">
                                    <li>Annual activation fee of <span class="price">R2000.00</span></li>
                                    <li>Then <span class="price">R150.00</span>/employee per month</li>
                                    <li>Unlimited system access</li>
                                    <li>Unlimited payslip generator</li>
                                </ul>
                            </div>
                            <router-link to="/register" class="get-started">Get Started</router-link>
                        </div>
                    </div>
                    <div class="col-sm-8 text-center">
                        <div class="img-side-3">
                            <img src="@/assets/img/time-management.png" alt="Time Management Image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact">
                <h4 class="mini-title">Want to get in touch?</h4>
                <div class="row">
                    <div class="col-sm-5">
                        <h3 class="small-cap">Need more information?</h3>
                        <h1 class="main-cap">Get in touch, or try to visit our office.</h1>
                        <a href="http://www.kunokhar.com" target="_blank" rel="noopener noreferrer" class="border-btn">Read more about Kunokhar CTP</a>
                    </div>
                    <div class="col-sm-7">
                        <div class="contact-details">
                            <h4>Contact us</h4>
                            <ul class="list-unstyled">
                                <li><span><i class="fa fa-phone"></i></span> <a href="tel:+27876880700">(+27) 87 688 0700</a></li>
                                <li><span><i class="fa fa-mobile"></i></span> <a href="tel:+27839267072">(+27) 83 926 7072</a></li>
                                <li><span><i class="fa fa-envelope"></i></span> <a href="mailto:enquiriesctp@gmail.com">enquiriesctp@gmail.com</a></li>
                                <li><span><i class="fa fa-map-marker"></i></span> No. 23 Delville Road, P.O. Box 9137, Mthatha, 5099</li>
                            </ul>
                            <div class="working-hours">
                                <h4>Working Hours</h4>
                                <p>Monday &#8212; Friday <span>8am - 5pm</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-part">
            <div class="container">
                <ul class="list-unstyled">
                    <li>&copy;{{new Date().getFullYear()}} Kunokhar CTP Inc.</li>
                    <li>Designed by: <a href="http://www.somecode.co.za" target="_blank" rel="noopener noreferrer">Somecode</a></li>
                    <li>Support</li>
                    <li>How it works</li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        setup() {
            return {}
        }
    }
</script>
