
import { LeaveType } from '@/types';
import { Time } from '@/utils';
import { computed, defineComponent, inject, PropType } from '@vue/runtime-core';
import { ref } from 'vue';
import { ApproveLeaveKey, DeclineLeaveKey, ViewDeclineLeaveModalKey } from '../symbols';
import DeclineLeave from './modals/DeclineLeave.vue';
export default defineComponent({
	components: {
		DeclineLeave
	},
	props: {
		leave: {
			type: Object as PropType<LeaveType>,
			required: true
		}
	},
	setup(props) {
		const leave = computed(() => props.leave);
		const leaveDuration = ref(
			Time.durationInDays(leave.value.duration?.start as Date, leave.value.duration?.end as Date)
		);
		const approve = inject(ApproveLeaveKey);
		const decline = inject(DeclineLeaveKey);
		const viewDeclineModal = inject(ViewDeclineLeaveModalKey);
		const showComment = ref(false);

		return {
			leave,
			leaveDuration,
			...Time,
			showComment,
			approve,
			decline,
			viewDeclineModal
		};
	}
});
