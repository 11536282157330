import { VerifyPayslipFunction, DownloadPayslipFunction, CreatePayslipFunction, RequestPayslipFunction } from './types';
import { PayslipType, ProcessedPayslipType } from '@/types';
import { InjectionKey } from 'vue';

export const PayslipFormKey: InjectionKey<ProcessedPayslipType> = Symbol();
export const VerifyPayslipKey: InjectionKey<VerifyPayslipFunction> = Symbol();
export const DownloadPayslipKey: InjectionKey<DownloadPayslipFunction> = Symbol();
export const CreatePayslipKey: InjectionKey<CreatePayslipFunction> = Symbol();
export const RequestPayslipKey: InjectionKey<RequestPayslipFunction> = Symbol();
// export const ApprovePayslipKey: InjectionKey<Function> = Symbol();
