import { EventBus } from '@/config/event-bus';
import { RequestResponse } from '@/enums';
import { IStatus } from '@/types';
import { getCurrentInstance } from 'vue';
const STATUS_EVENT = 'status_event';
export default function useEvent() {
	const app = getCurrentInstance();
	const bus: EventBus = app?.appContext.config.globalProperties.$eventBus;

	function emitStatus(status: IStatus) {
		bus.dispatch<IStatus>(STATUS_EVENT, status);
	}

	function onStatus(callback: (status: IStatus) => void) {
		bus.register(STATUS_EVENT, callback);
	}
	return {
		bus,
		SHOW_LOCATIONS: 'show-locations',
		emitStatus,
		onStatus
	};
}
