import { OnQueryObserver, PayslipType, ProcessedPayslipType } from '@/types';
import { Unsubscribe } from 'firebase/auth';
import { collection, CollectionReference, DocumentReference, Firestore, getFirestore } from 'firebase/firestore';
import { PayslipQueryConstrants } from '../types';

abstract class BasePayslipRepository {
	protected payslipsRef: CollectionReference;
	protected db: Firestore;
	constructor() {
		this.db = getFirestore();
		this.payslipsRef = collection(this.db, 'payslips');
	}
	protected abstract payslipDocRef(id: string): DocumentReference;
	abstract createPayslip(payslip: ProcessedPayslipType): Promise<void>;
	abstract verifyPayslip(payslip: PayslipType): Promise<void>;
	abstract streamPayslips(queryConstraints: PayslipQueryConstrants, observer: OnQueryObserver): Unsubscribe;
	abstract getPayslip(companyId: string, userId: string, period: string): Promise<PayslipType | null>;
	abstract getPayslipById(id: string): Promise<PayslipType | null>;
}

export default BasePayslipRepository;
