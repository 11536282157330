import { type ProcessedPayslipType } from '@/types';
import { QuerySnapshot } from 'firebase/firestore';
import { State } from '.';

export const SET_PAYSLIPS = (state: State, querySnapshot: QuerySnapshot) => {
	state.status = querySnapshot.docs.length > 0 ? 'SUCCESS' : 'EMPTY';
	if (querySnapshot.docs.length == 0) {
		state.payslips = [];
	} else {
		state.payslips = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			const payslip: ProcessedPayslipType = { id: doc.id, ...doc.data() };
			if (!data['grossIncome'] && data['employee']['salary']) {
				payslip.grossIncome = data['employee']['salary'];
			}
			return payslip;
		});
	}
};
export const SET_PAYSLIP = (state: State, querySnapshot: QuerySnapshot) => {
	if (querySnapshot.docs.length == 0) {
		state.payslip = null;
	} else {
		let doc = querySnapshot.docs[0];
		const data = doc.data();
		const payslip: ProcessedPayslipType = { id: doc.id, ...doc.data() };
		if (!data['grossIncome'] && data['employee']['salary']) {
			payslip.grossIncome = data['employee']['salary'];
		}
		state.payslip = payslip;
		state.status = querySnapshot.docs.length > 0 ? 'SUCCESS' : 'EMPTY';
	}
};
