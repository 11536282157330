import { RouteRecordRaw } from 'vue-router';
import BasicInfo from '../modules/user/components/company-reg/BasicInfo.vue';
import CompanyInfo from '../modules/user/components/company-reg/CompanyInfo.vue';
import Payment from '../modules/user/components/company-reg/Payment.vue';
import ReviewReg from '../modules/user/components/company-reg/ReviewReg.vue';
import ProfileBasicInfo from '../modules/user/components/profile/BasicInfo.vue';
import ProfileChangePassword from '../modules/user/components/profile/ChangePassword.vue';
import ProfileDuties from '../modules/user/components/profile/Duties.vue';
import ProfileEmploymentInfo from '../modules/user/components/profile/EmploymentInfo.vue';
import ProfileMyStats from '../modules/user/components/profile/MyStats.vue';
import Login from '../modules/user/views/Login.vue';
import Profile from '../modules/user/views/Profile.vue';
import Register from '../modules/user/views/Register.vue';
import ResetPassword from '../modules/user/views/ResetPassword.vue';
import Welcome from '../modules/user/views/Welcome.vue';

export const router: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'Welcome',
		component: Welcome,
		meta: {
			requiresAuth: false,
			title: 'Welcome'
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresAuth: false,
			title: 'Login'
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		meta: {
			requiresAuth: false,
			title: 'Register'
		},
		children: <RouteRecordRaw[]>[
			{
				path: '',
				name: 'RegisterBasicInfo',
				component: BasicInfo,
				meta: {
					requiresAuth: false,
					title: 'Register / Basic Info'
				}
			},
			{
				path: 'company-info',
				name: 'RegisterCompanyInfo',
				component: CompanyInfo,

				meta: {
					requiresAuth: true,
					title: 'Register / Company Info'
				}
			},
			{
				path: 'review-reg',
				name: 'ReviewReg',
				component: ReviewReg,
				meta: {
					requiresAuth: true,
					title: 'Register / Review'
				}
			},
			{
				path: 'payment',
				name: 'RegisterPayment',
				component: Payment,
				meta: {
					requiresAuth: true,
					title: 'Register / Payment'
				}
			}
		]
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword,
		meta: {
			requiresAuth: false,
			title: 'Reset Password'
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
		meta: {
			requiresAuth: true,
			title: 'Profile'
		},
		children: <RouteRecordRaw[]>[
			{
				path: '',
				name: 'MyStats',
				component: ProfileMyStats,
				meta: {
					requiresAuth: true,
					title: 'User Stats'
				}
			},
			{
				path: 'basic-info',
				name: 'BasicInfo',
				component: ProfileBasicInfo,
				meta: {
					requiresAuth: true,
					title: 'Basic User Details'
				}
			},
			{
				path: 'employment-info',
				name: 'EmploymentInfo',
				component: ProfileEmploymentInfo,
				meta: {
					requiresAuth: true,
					title: 'User Employment Info'
				}
			},
			{
				path: 'duties',
				name: 'Duties',
				component: ProfileDuties,
				meta: {
					requiresAuth: true,
					title: 'User Duties'
				}
			},
			{
				path: 'change-password',
				name: 'ChangePassword',
				component: ProfileChangePassword,
				meta: {
					requiresAuth: true,
					title: 'Change Password'
				}
			}
		]
	}
];
