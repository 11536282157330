
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import routesData from '../data/routesData.json';
import { SidebarItem } from '@/types';
import { UserRole } from '@/enums';
export default defineComponent({
	setup() {
		const store = useStore();
		const user = computed(() => store.state.user.user);
		const sidebarItems = ref<SidebarItem[]>([]);

		onMounted(() => {
			sidebarItems.value =
				user.value?.role == UserRole.COMPANY_ADMIN ? routesData.COMPANY_ADMIN : routesData.EMPLOYEE;
		});
		return {
			sidebarItems,
			user,
			UserRole
		};
	}
});
