import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { scope: "row" }
const _hoisted_3 = { class: "edit-and-approve" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.payslip)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.payslip?.employee?.name), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.payslip?.period), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.currencyFormat(_ctx.payslip?.employee?.salary)), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.currencyFormat(parseFloat(_ctx.payslip?.employee?.salary) - _ctx.deductions)), 1),
        _createElementVNode("td", null, _toDisplayString(_ctx.currencyFormat(_ctx.deductions)), 1),
        _createElementVNode("td", _hoisted_3, [
          _createElementVNode("button", {
            type: "button",
            class: "edit-btn",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.viewPayslip()), ["prevent"]))
          }, " Edit "),
          _createElementVNode("button", {
            type: "button",
            class: "approve-btn",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.verifyPayslip(_ctx.payslip)), ["prevent"]))
          }, " Approve ")
        ])
      ]))
    : _createCommentVNode("", true)
}