import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
const app = initializeApp({
	apiKey: process.env.VUE_APP_API_KEY,
	authDomain: process.env.VUE_APP_AUTH_DOMAIN,
	projectId: process.env.VUE_APP_PROJECT_ID,
	storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APP_ID
});
// connectAuthEmulator(getAuth(), 'http://localhost:9099');
// connectFirestoreEmulator(getFirestore(), 'localhost', 8001);
// connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
// connectStorageEmulator(getStorage(), 'localhost', 9199);
const analytics = getAnalytics(app);
export default app;
