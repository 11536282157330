import { TReconcile } from '../types';
import {
	ComplianceStatuses,
	ReconciliationGetters,
	ReconciliationNames,
	ReconciliationStatus,
	ReconciliationTypes
} from '../enum';
import { _GettersTree } from 'pinia';
import { State } from './state';
import { Reconciliation } from '../types';
import { v4 as uuid } from 'uuid';
import { ShortMonths } from '@/enums';

type TotalOwing = Reconciliation.TotalOwing;
type OwingTable = Reconciliation.OwingTable;
export interface Getters extends _GettersTree<State> {
	[ReconciliationGetters.AMOUNT_OWING_SUMMARY]: (state: State) => (type: ReconciliationTypes) => TReconcile[];
	[ReconciliationGetters.AMOUNT_OWING_SUMMARY_BY_YEAR]: (state: State) => (year: string) => OwingTable;
	[ReconciliationGetters.NUMBER_OF_OWED_EMPLOYEES]: (state: State) => (employeeIds: string[]) => number;
	[ReconciliationGetters.EMPLOYEE_AMOUNT_OWING]: (state: State) => (employeeId: string) => number;
}

export const getters: Getters = {
	AMOUNT_OWING_SUMMARY: function (state: State): (type: ReconciliationTypes) => TReconcile[] {
		return (type: ReconciliationTypes = ReconciliationTypes.TAX_PAYMENT) => {
			const payments = state.reconciliation?.payments;
			let names = Object.values(ReconciliationNames);

			const sums = names.reduce(
				(o, key) =>
					Object.assign(o, {
						[key]: totalOwing(key, payments || [])
					}),
				{
					PAYE: 0,
					UIF: 0,
					SDL: 0,
					Salary: 0,
					COIDA: 0
				}
			);

			if (type == ReconciliationTypes.SALARY_PAYMENT) {
				names = names.filter((name) => name == ReconciliationNames.SALARY);
			}
			return names.map((key) => {
				return {
					id: uuid(),
					name: key.toString(),
					amount: sums[key]
				};
			});
		};
	},
	AMOUNT_OWING_SUMMARY_BY_YEAR: function (state: State) {
		return (year: string = new Date().getFullYear().toString()): OwingTable => {
			const payments = state.reconciliation?.payments?.filter((p) => p.duration.year == year) ?? [];
			const names = Object.values(ReconciliationNames);

			let months = Object.values(ShortMonths);
			months = months.filter((m) => payments?.filter((p) => p.duration.month == m).length > 0);

			const sums: OwingTable = {};
			months.forEach((month) => {
				names.forEach((key) => {
					let owes = payments?.filter(
						(p) => p.duration.month == month && p.name == key && p.status == ReconciliationStatus.OWING
					);

					let paid = payments?.filter(
						(p) => p.duration.month == month && p.name == key && p.status == ReconciliationStatus.PAID
					);
					const total = totalOwing(key, owes || []) - totalPayment(key, paid || []);
					sums[month] = {
						...sums[month],
						[key]: <TReconcile>{
							id: uuid(),
							amount: totalOwing(key, owes || []),
							payments: totalPayment(key, paid || []),
							complianceStatus: total > 0 ? ComplianceStatuses.NON_COMPLIANT : ComplianceStatuses.COMPLIANT,
							name: key
						}
					};
				});
			});

			return sums;
		};
	},
	NUMBER_OF_OWED_EMPLOYEES:
		(state: State) =>
		(employeeIds: string[]): number => {
			let sum = 0;
			if (employeeIds && employeeIds.length > 0) {
				for (let index = 0; index < employeeIds.length; index++) {
					const id = employeeIds[index];
					const payments = state.reconciliation?.payments?.filter((p) => p.employeeId && p.employeeId == id);
					const amountOwed = totalOwing(ReconciliationNames.SALARY, payments || []);
					if (amountOwed > 0) {
						sum++;
					}
				}
			}

			return sum;
		},
	EMPLOYEE_AMOUNT_OWING: function (state: State): (employeeId: string) => number {
		return (employeeId: string): number => {
			const payments = state.reconciliation?.payments?.filter((p) => p.employeeId && p.employeeId == employeeId);
			return totalOwing(ReconciliationNames.SALARY, payments || []);
		};
	}
};

function totalPayment(name: ReconciliationNames, payments: Reconciliation.Payment[]): number {
	return payments.reduce((prev, current) => {
		if (current.name === name) {
			return current.status === ReconciliationStatus.PAID
				? prev + parseFloat(current.amount.toString())
				: prev - parseFloat(current.amount.toString());
		}
		return prev;
	}, 0);
}

function totalOwing(name: ReconciliationNames, payments: Reconciliation.Payment[]): number {
	return payments.reduce((prev, current) => {
		if (current.name === name) {
			return current.status === ReconciliationStatus.OWING
				? prev + parseFloat(current.amount.toString())
				: prev - parseFloat(current.amount.toString());
		}
		return prev;
	}, 0);
}
