import { CompanyType, UserType } from '@/types';
import { ComputedRef, InjectionKey } from '@vue/runtime-core';
import type { LicenseModel, PlanModel } from 'kunokhar-acc-types';
import { PayfastRepo } from 'kunokhar-acc-services';

export const BasicInfoKey: InjectionKey<UserType> = Symbol('basicInfo');
export const SubmitBasicInfoKey: InjectionKey<Function> = Symbol('submitBasicInfo');
export const CompanyInfoKey: InjectionKey<CompanyType> = Symbol('CompanyInfo');
export const SubmitCompanyInfoKey: InjectionKey<Function> = Symbol('submitBasicInfo');

export const SelectedPlanKey: InjectionKey<ComputedRef<PlanModel | undefined>> = Symbol('selectedPlan');
export const SelectPlanKey: InjectionKey<Function> = Symbol('selectPlan');
export const LicenseKey: InjectionKey<ComputedRef<LicenseModel | undefined>> = Symbol('license');
export const PayfastRepoKey: InjectionKey<PayfastRepo> = Symbol('payfastRepo');
