
import TextField from '@/components/TextField.vue';
import { defineComponent, inject } from '@vue/runtime-core';
import { PaymentInterval } from '@/enums';
import { NextStepKey, RemunerationFormKey } from '../symbols';
export default defineComponent({
	components: {
		TextField
	},
	setup() {
		const form = inject(RemunerationFormKey, {});
		const nextStep = inject(NextStepKey);

		return {
			form,
			nextStep,
			PaymentInterval
		};
	}
});
