
import { defineComponent, inject, onMounted, reactive, watch } from 'vue';
import TextField from '@/components/TextField.vue';
import AddressTextField from '@/components/AddressTextField.vue';
import { CompanyType } from '@/types';
import { CompanyKey, ResponseKey } from '@/symbols';
import { useStore } from '@/store';
import { CompanyActions } from '../../types';
import { cloneDeep } from 'lodash';
export default defineComponent({
	components: {
		TextField,
		AddressTextField
	},
	setup() {
		const form = reactive<CompanyType>({
			id: '',
			name: '',
			address: '',
			emp201: '',
			uif: '',
			sdl: '',
			registrationNumber: '',
			vatNumber: ''
		});
		const company = inject(CompanyKey);
		const response = inject(ResponseKey);
		const store = useStore();
		watch(
			() => company?.value,
			() => loadData()
		);
		function loadData() {
			if (company && company.value) {
				form.id = company?.value.id;
				form.name = company?.value.name;
				form.address = company?.value.address;
				form.emp201 = company?.value.emp201;
				form.uif = company?.value.uif;
				form.sdl = company?.value.sdl;
				form.registrationNumber = company?.value.registrationNumber;
				form.vatNumber = company?.value.vatNumber;
			}
		}

		function mapCallback(address: string) {
			form.address = address;
		}

		async function saveChanges() {
			try {
				response?.loading();
				await store.dispatch(CompanyActions.UPDATE_COMPANY_INFO, cloneDeep(form));
				response?.success('Company info updated!');
			} catch (error) {
				response?.error(error as Error);
			}
		}
		onMounted(() => loadData());

		return {
			form,
			saveChanges,
			mapCallback
		};
	}
});
