import type { UploadStatusModel } from '@/models';
import type { Unsubscribe } from '@firebase/util';
import {
	getStorage,
	ref,
	type FirebaseStorage,
	type StorageReference,
	uploadBytesResumable,
	getDownloadURL
} from 'firebase/storage';
export class UploadToCloudStore {
	protected storageRef: StorageReference;
	protected storage: FirebaseStorage;
	protected filepath: string;

	constructor({ path }: { path: string }) {
		this.filepath = path;
		this.storage = getStorage();
		this.storageRef = ref(this.storage, this.filepath);
	}

	upload(file: File, callback: (response: UploadStatusModel) => void) {
		const uploadTask = uploadBytesResumable(this.storageRef, file);
		let progress = 0;
		return uploadTask.on(
			'state_changed',
			async (snapshot) => {
				if (snapshot.state == 'running') {
					progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

					callback({
						state: 'running',
						progress
					});
				}

				if (snapshot.state == 'paused') {
					callback({
						state: 'paused',
						progress
					});
				}
			},

			(error) => {
				callback({
					state: 'error',
					progress,
					data: error.message
				});
			},
			() => {
				getDownloadURL(this.storageRef)
					.then((url) => {
						callback({
							state: 'success',
							progress,
							data: url
						});
					})
					.catch((error) => {
						callback({
							state: 'error',
							progress,
							data: error.message
						});
					});
			}
		);
	}
}
