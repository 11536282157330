
import { useStore } from '@/store';
import { PerfomanceTaskType } from '@/types';
import { computed, defineComponent, PropType } from 'vue';
import { PerfomanceContractActions } from '../../perfomance-contract/types/store-type';
import cloneDeep from 'lodash.clonedeep';

export default defineComponent({
        props: {
                task: {
                        type: Object as PropType<PerfomanceTaskType>,
                        required: true
                }
        },
        setup(props) {
                const task = computed(() => props.task);
                const store = useStore();
                const handleClick = () => {
                        store.dispatch(
                                PerfomanceContractActions.selectPerfomanceTask,
                                cloneDeep(task.value)
                        );
                };
                return { task, handleClick };
        }
});
