import { useStore } from '@/store';
import { getAuth } from 'firebase/auth';
import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import UserRepository from '../repositories/user-repository';
const userRepository = new UserRepository();
export function useFetchUser(id = getAuth().currentUser?.uid!) {
	let unsubscribe: Unsubscribe;
	const store = useStore();
	const user = computed(() => store.state.user.user);
	const error = ref<String | null>(null);
	onMounted(() => {
		unsubscribe = onSnapshot(
			userRepository.userDocRef(id),
			(snapshot) => {
				store.dispatch('user/setUser', snapshot);
			},
			(e) => {
				error.value = e.message;
			}
		);
	});

	onUnmounted(() => {
		unsubscribe();
	});

	return {
		user
	};
}
