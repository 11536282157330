
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
	props: {
		placeholder: {
			type: String,
			required: true
		},
		controller: {
			required: true
		},
		type: {
			type: String,
			default: 'text'
		}
	},
	setup(props) {
		const placeholder = ref(props.placeholder);
		const type = ref(props.type);
		const controller = ref(props.controller);
		watch(
			() => props.controller,
			() => {
				controller.value = props.controller;
			}
		);
		return {
			placeholder,
			type,
			controller
		};
	}
});
