import { EmployeeType, Response, UserType } from '@/types';
import EmployeeRepository from '../repositories/employee-repository';
import { EmployeeMutations } from '../types/store-types';
const employeeRepository = new EmployeeRepository();

export const addEmployeeBasicInfo = async (_: any, form: EmployeeType) => {
	return await employeeRepository.addEmployeeBasicInfo(form);
};
export const addEmployeeEmploymentInfo = async (_: any, form: EmployeeType) => {
	return await employeeRepository.addEmployeeEmploymentInfo(form);
};
export const addEmployeeRemunerationInfo = async (_: any, form: EmployeeType) => {
	return await employeeRepository.addEmployeeRemunerationInfo(form);
};
export const getEmployees = ({ commit }: any, observer: any) => {};

export const updateJobData = (_: any, payload: EmployeeType) => employeeRepository.updateJobData(payload);

export const streamEmployees = ({ commit }: any, { user, response }: { user: UserType; response: Response }) => {
	response.loading();
	return employeeRepository.streamEmployees(user.companyId!, {
		next: (querySnapshot) => {
			if (querySnapshot.docs.length == 0) {
				response.empty();
			} else {
				response.success();
				commit(EmployeeMutations.SET_EMPLOYEES, {
					docs: querySnapshot.docs,
					user
				});
			}
		},
		error: (error) => {
			console.log(error);

			response.error(error);
		}
	});
};

export const streamEmployee = (
	{ commit }: any,
	{ employeeId, response }: { employeeId: string; response: Response }
) => {
	response.loading();
	console.log(employeeId);

	return employeeRepository.streamEmployee(employeeId, {
		next: (documentSnapshot) => {
			if (!documentSnapshot.exists) {
				response.empty();
			} else {
				response.success();
				commit(EmployeeMutations.SET_EMPLOYEE, documentSnapshot);
			}
		},
		error: (error) => {
			response.error(error);
		}
	});
};
