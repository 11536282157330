import useEvent from '@/composables/use-event';
import { RequestResponse } from '@/enums';
import { leaveStore, useStore } from '@/store';
import { CompanyKey, ResponseKey } from '@/symbols';
import { EmployeeType, LeaveType } from '@/types';
import { Modal } from 'bootstrap';
import { Unsubscribe } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { computed, inject, onBeforeMount, onBeforeUnmount, reactive, watch } from 'vue';
import { LeaveConstraints } from '../types';

export default function useLeave({ leaveId, employeeId, companyId, status, duration }: LeaveConstraints = {}) {
	const lStore = leaveStore();
	const store = useStore();
	const response = inject(ResponseKey);
	let unsubscribe: Unsubscribe;
	const leaves = computed(() => lStore.leaves);
	const leavesLoadingStatus = computed(() => lStore.response.status);
	const user = computed(() => store.state.user.user);
	const company = inject(CompanyKey);
	const { emitStatus } = useEvent();
	const constraints = reactive<LeaveConstraints>({
		employeeId: employeeId,
		companyId: ''
	});

	const form = reactive<LeaveType>({
		type: '',
		duration: {
			start: new Date().toISOString().slice(0, 10),
			end: new Date().toISOString().slice(0, 10)
		},
		companyId: '',
		employeeId: '',
		comment: ''
	});

	function hasError() {
		let error = null;
		if (!form.type) {
			error = 'Please select leave type';
		} else if (!form.duration?.start || !form.duration.end) {
			error = 'Please select the duration of the leave';
		}
		return error;
	}

	const handleSubmit = async (employee: EmployeeType) => {
		try {
			lStore.response = {
				status: RequestResponse.LOADING
			};
			form.employeeId = employee.id;
			if (hasError()) {
				throw new Error(hasError()!);
			}
			await lStore.CREATE_LEAVE(cloneDeep(form));
			lStore.response = {
				status: RequestResponse.SUCCESS,
				message: 'Leave created successfully'
			};

			setTimeout(() => {
				form.duration = {
					start: new Date().toISOString().slice(0, 10),
					end: new Date().toISOString().slice(0, 10)
				};
			}, 2000);
		} catch (error) {
			lStore.response = {
				status: RequestResponse.ERROR,
				message: error
			};
		}
	};

	const requestLeave = async (employee: EmployeeType) => {
		try {
			lStore.response = {
				status: RequestResponse.LOADING
			};
			form.employeeId = employee.id;
			form.companyId = company?.value.id;
			if (hasError()) {
				throw new Error(hasError()!);
			}
			await lStore.REQUEST_LEAVE(cloneDeep(form));
			lStore.response = {
				status: RequestResponse.SUCCESS,
				message: 'Leave requested successfully'
			};
		} catch (error) {
			lStore.response = {
				status: RequestResponse.ERROR,
				message: error
			};
		}
	};

	async function approve(id: string) {
		try {
			lStore.response = {
				status: RequestResponse.LOADING
			};
			await lStore.APPROVE_LEAVE(id);
			lStore.response = {
				status: RequestResponse.SUCCESS,
				message: 'Leave approved successfully'
			};
		} catch (error) {
			lStore.response = {
				status: RequestResponse.ERROR,
				message: error
			};
		}
	}

	async function decline(id: string, comment: string) {
		try {
			if (comment.length > 5) {
				lStore.response = {
					status: RequestResponse.LOADING
				};
				await lStore.DECLINE_LEAVE({ id, comment });
				lStore.response = {
					status: RequestResponse.SUCCESS,
					message: 'Leave declined successfully'
				};
				let el: Element = document.getElementById('decline_leave') as Element;
				new Modal(el, { keyboard: true }).hide();
			} else {
				throw new Error('Comment should be at least 5 characters ');
			}
		} catch (error) {
			lStore.response = {
				status: RequestResponse.ERROR,
				message: error
			};
		}
	}

	watch(
		() => [company?.value, constraints],
		() => {
			form.companyId = company?.value.id;
			constraints.companyId = company?.value.id;
			constraints.status = status;
			setTimeout(() => {
				setup();
			}, 1000);
		}
	);

	watch(
		() => lStore.response.status,
		() => {
			emitStatus(lStore.response);
		}
	);

	onBeforeMount(async () => {
		lStore.response = {
			status: RequestResponse.LOADING
		};

		if (company?.value != null && company?.value.id) {
			setup();
		}
	});

	async function setup() {
		if (typeof constraints != 'undefined') {
			constraints.companyId = company?.value.id;
			constraints.status = status;
			constraints.employeeId = employeeId;

			if (leaveId) {
				unsubscribe = lStore.STREAM_LEAVE(leaveId);
			} else {
				unsubscribe = lStore.STREAM_LEAVES(cloneDeep(constraints));
			}
		}
	}
	onBeforeUnmount(() => {
		if (typeof unsubscribe == 'function') unsubscribe();
	});

	return {
		leaves,
		leavesLoadingStatus,
		form,
		constraints,
		handleSubmit,
		approve,
		decline,
		requestLeave
	};
}
