<template lang="">
    <div class="change-password">
        <form action="#" method="POST">
            <input type="password" name="crr_password" id="crr_password" placeholder="Current Password">
            <h4>Create New Password</h4>
            <input type="password" name="new_password" id="new_password" placeholder="New Password">
            <input type="password" name="confirm_password" id="confirm_password" placeholder="Confirm Password">
            <button type="button" class="border-btn">Change Password</button>
        </form>
    </div>
</template>
<script>
    export default {

    }
</script>