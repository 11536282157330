
import { Enum } from '@/enums';
import { ClockType } from '@/types';
import { Time, TransformEnum } from '@/utils';
import { Timestamp } from '@firebase/firestore';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, inject, PropType, ref, watch } from 'vue';
import { ClockFormKey, RegisterTimeFunction } from '../../symbols';

export default defineComponent({
	props: {
		clocks: {
			type: Array as PropType<ClockType[]>,
			required: true
		}
	},
	setup(props) {
		const clocks = computed(() => props.clocks);
		const iAmBack = ref<boolean>(false);
		const goingHome = ref<boolean>(false);
		const clockingTypes = ref<string[]>(TransformEnum.getValues(Enum.ClockType));
		const form = inject(ClockFormKey) as ClockType;
		const registerTime = inject(RegisterTimeFunction) as Function;
		watch(
			() => clocks.value,
			() => {
				setupAll();
			}
		);

		function setupAll() {
			if (clocks.value.length > 0) {
				clocks.value.sort((a, b) => (a.createdAt as Timestamp).toMillis() - (b.createdAt as Timestamp).toMillis());
				let c = cloneDeep(clocks.value);
				c = c.filter((clock) => Time.timeStampIsToday(clock.createdAt as Timestamp));
				if (c.length > 0) {
					const lastEntry = c[c.length - 1];

					iAmBack.value =
						(lastEntry.type == Enum.ClockType.LUNCH || lastEntry.type == Enum.ClockType.TEA) &&
						!lastEntry.duration?.end;

					if (iAmBack.value) {
						form.type = lastEntry.type;
					}

					if (lastEntry.type == Enum.ClockType.LUNCH && lastEntry.duration?.end && lastEntry.duration.start) {
						goingHome.value = true;
						form.type = Enum.ClockType.ON_DUTY;
					}
				}
			}
		}

		return {
			iAmBack,
			goingHome,
			clockingTypes,
			clocks,
			form,
			registerTime
		};
	}
});
