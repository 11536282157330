import { PerfomanceTaskType } from '@/types';
import { Timestamp } from '@firebase/firestore';
import { convertTimestampToDate } from '../../../utils/helper';

export default class PerfomanceTask {
	id?: string;
	name?: string;
	createdAt?: Date | Timestamp;
	isCompleted?: boolean;

	constructor(id?: string, name?: string, createdAt?: Date | Timestamp, isCompleted: boolean = false) {
		this.id = id;
		this.name = name;
		this.createdAt = convertTimestampToDate(createdAt!);
		this.isCompleted = isCompleted;
	}

	fromFirestore(task: PerfomanceTaskType): PerfomanceTask {
		return new PerfomanceTask(
			(this.id = task.id),
			(this.name = task.name),
			(this.createdAt = task.createdAt),
			(this.isCompleted = task.isCompleted)
		);
	}

	toFirestore(task: PerfomanceTaskType) {
		return {
			id: new Date().valueOf(),
			name: task.name,
			createdAt: new Date(),
			isCompleted: false
		};
	}
}
