import UploadRepository from '@/repositories/upload-repository';
import { CompanyTimes, CompanyType, DurationTime } from '@/types';
import { DocumentSnapshot } from '@firebase/firestore';
import CompanyRepository from '../repositories/company-repository';
import { CompanyMutations } from '../types';
const companyRepository = new CompanyRepository();
var companyId: string | null;
export const addCompanyInfo = async (_: any, form: CompanyType) => await companyRepository.addCompany(form);

export const updateCompanyInfo = async (_: any, form: CompanyType) => await companyRepository.updateInfo(form);
export const setCompany = ({ commit }: any, snapshot: DocumentSnapshot) => {
	commit('SET_COMPANY', snapshot);
};

export const streamCompany = ({ commit }: any, companyId: string) =>
	companyRepository.streamCompany(companyId, {
		next: (snapshot) => {
			commit(CompanyMutations.SET_COMPANY, snapshot);
		},
		error: (error) => {}
	});

export const uploadLogo = async (
	_: any,
	payload: { imageString: string; companyId: string; progress: Function; status: Function; id: string }
) => {
	try {
		let uploadRepository = new UploadRepository();
		await uploadRepository.uploadAvatar(payload.imageString, payload.progress, payload.status, payload.id);
	} catch (error) {
		let e = error as Error;
		return payload.status(false, e.message);
	}
};

export const saveLogoDownloadLink = async (_: any, payload: { companyId: string; logo: string }) => {
	try {
		return await companyRepository.uploadCompanyLogo(payload.logo, payload.companyId);
	} catch (error) {
		throw error;
	}
};

export const saveWorkingHours = async (
	_: any,
	{ companyId, hoursOfWork }: { companyId: string; hoursOfWork: CompanyTimes.WorkingHours[] }
) => {
	return await companyRepository.saveHoursOfWork(companyId, hoursOfWork);
};

export const savePaydays = async (
	_: any,
	{ companyId, paydays }: { companyId: string; paydays: CompanyTimes.PayDay[] }
) => {
	return await companyRepository.savePaydays(companyId, paydays);
};

export const saveLunchTimes = async (
	_: any,
	{ companyId, lunchTimes }: { companyId: string; lunchTimes: DurationTime[] }
) => {
	return await companyRepository.saveLunchTimes(companyId, lunchTimes);
};

export const saveTeaTimes = async (
	_: any,
	{ companyId, teaTimes }: { companyId: string; teaTimes: DurationTime[] }
) => {
	return await companyRepository.saveTeaTimes(companyId, teaTimes);
};
