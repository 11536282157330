
import { CompanyKey, FunctionKey } from '@/symbols';
import { defineComponent, inject, watch } from 'vue';
import { SaveTimesKey, WorkingHoursFormKey } from '../symbols';
import { CompanyTimes } from '@/types';

export default defineComponent({
	setup() {
		const form = inject(WorkingHoursFormKey);
		const company = inject(CompanyKey);
		const saveTimes = inject(SaveTimesKey);

		function toggleCheck(index: number) {
			form![index].isActive = !form![index].isActive;
		}

		watch(
			() => company!.value,
			() => {
				if (company && company.value) {
					let workingHours: CompanyTimes.WorkingHours[] = company.value.hoursOfWork as CompanyTimes.WorkingHours[];
					for (let index = 0; index < workingHours.length; index++) {
						form![index] = workingHours[index];
					}
				}
			}
		);

		return {
			form,
			company,
			saveTimes,
			toggleCheck
		};
	}
});
