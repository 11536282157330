import { ResponseStatus } from '@/types';
import { Module } from 'vuex';
import { RootState } from '.';

type State = {
	status: ResponseStatus;
	data: any | null;
};

const state: State = {
	status: 'IDLE',
	data: null
};

export { State };

export const store: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations: {
		SET_RESPONSE: (state, data: State) => {
			state.status = data.status;
			state.data = data.data;
		}
	}
};
