
import { CompanyKey } from '@/symbols';
import { defineComponent, inject, watch } from 'vue';
import { useLunchTimes } from '../composables/use-company';
import { TransformEnum } from '@/utils';
import { Enum } from '@/enums';

export default defineComponent({
	setup() {
		const groups = TransformEnum.getValues(Enum.Groups);
		return {
			...useLunchTimes(),
			groups
		};
	}
});
