
import { LeaveType } from '@/types';
import { Time } from '@/utils';
import { v4 } from 'uuid';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
	props: {
		leave: {
			type: Object as PropType<LeaveType>,
			required: true
		}
	},
	setup(props) {
		const leave = computed(() => props.leave);
		const totalDays = computed(() =>
			leave ? Time.durationInDays(leave.value.duration?.start as Date, leave.value.duration?.end as Date) : 0
		);

		return {
			leave,
			totalDays,
			Time,
			collapseKey: v4()
		};
	}
});
