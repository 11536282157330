
import { EmployeeType } from '@/types';
import { computed, defineComponent, inject, PropType } from '@vue/runtime-core';
import { ViewLeaveModalKey } from '../symbols';

export default defineComponent({
	props: {
		employee: {
			type: Object as PropType<EmployeeType>,
			required: true
		}
	},
	setup(props) {
		const employee = computed(() => props.employee);
		const viewLeaveModal = inject(ViewLeaveModalKey);
		return {
			employee,
			viewLeaveModal
		};
	}
});
