
import { defineComponent, watch } from 'vue';
import { useEmployeesAction } from '../composables/use-employee';
import { avatarMaker } from '@/utils';

export default defineComponent({
	setup() {
		const { employees } = useEmployeesAction();

		return { employees, avatarMaker };
	}
});
