
import { useStore } from '@/store';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { UserActions } from '../../types/store-types';

export default defineComponent({
	setup() {
		const store = useStore();
		const router = useRouter();
		async function confirmDetails() {
			try {
				await store.dispatch(UserActions.CONFIRM_DETAILS);
				router.push('/register/payment');
			} catch (error) {
				console.log(error);
			}
		}
		return { confirmDetails };
	}
});
