
import { useStore } from '@/store';
import { CompanyKey } from '@/symbols';
import { cloneDeep } from 'lodash';
import { defineComponent, onBeforeMount, computed, inject, watch } from 'vue';
import { PerfomanceContractActions } from '../types/store-type';
import PerfomanceTask from './PerfomanceTask.vue';
import LoaderOrEmpty from '@/components/LoadingOrEmpty.vue';

export default defineComponent({
	components: { PerfomanceTask, LoaderOrEmpty },
	setup() {
		const store = useStore();
		const perfomanceTasks = computed(() => store.state.perfomanceContract.perfomanceTasks);
		const status = computed(() => store.state.perfomanceContract.status);
		const company = inject(CompanyKey);

		watch(
			() => company?.value,
			() => {
				store.dispatch(PerfomanceContractActions.getPerfomanceTasks, cloneDeep(company?.value));
			}
		);
		// onBeforeMount(() => {
		// 	store.dispatch(PerfomanceContractActions.getPerfomanceTasks);
		// });
		return {
			perfomanceTasks,
			status
		};
	}
});
