import { RequestResponse } from '@/enums'
import type { UserType } from '@/types'
export type State={
    response: RequestResponse| null,
   user: UserType|null,
    contactPerson: UserType| null,
    users: Array<UserType>
}

export const state: State={
    response: RequestResponse.IDLE,
   user: null,
   contactPerson: null,
    users: [],
}

