
import { ResponseKey } from '@/symbols';
import { defineComponent, inject, ref } from 'vue';
import { DeclineLeaveKey, SelectedEmployeeKey, SelectedLeaveKey } from '../../symbols';

export default defineComponent({
	setup() {
		const employee = inject(SelectedEmployeeKey);
		const leave = inject(SelectedLeaveKey);
		const decline = inject(DeclineLeaveKey);
		const comment = ref<string>('');
		const response = inject(ResponseKey);

		function handleSubmit() {
			decline?.call(leave?.value.id, comment.value);
		}
		return {
			employee,
			leave,
			decline,
			comment,
			handleSubmit
		};
	}
});
