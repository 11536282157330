import { DOB } from '@/types';
import { getDOBFromIDNumber } from '.';

export const validEmail = (email: string) => {
	try {
		let valid = String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		if (!valid) throw new Error('Invalid email');
		else return true;
	} catch (error) {
		throw error;
	}
};

export const validPassword = (password: string) => {
	try {
		if (password.length < 8) throw new Error('Password to short, it should be at least 8 alpha numerics');
		else {
			return true;
		}
	} catch (error) {
		throw error;
	}
};

export const passwordsMatch = (pass1: string, pass2: string) => {
	try {
		if (!pass1 || !pass2) throw new Error('Please provide a password');
		else if (pass1 != pass2) throw new Error('Password does not match');
		else return true;
	} catch (error) {
		throw error;
	}
};

export const validContactNumber = (contactNumber: string) => {
	try {
		if (contactNumber.length < 10) throw new Error('Contact number must be 10 numbers');
		else {
			let firstSegment: number = parseInt(contactNumber.charAt(0));
			let secondSegment: number = parseInt(contactNumber.charAt(1));
			if (firstSegment !== 0) throw new Error('Contact number must start with zero');
			else if (![8, 7, 6].includes(secondSegment)) throw new Error('Invalid contact number');
			else return true;
		}
	} catch (error) {
		throw error;
	}
};

export const validName = (name: string) => {
	try {
		if (name.length < 2) throw new Error('Invalid name');
		else {
			return true;
		}
	} catch (error) {
		throw error;
	}
};

export const validIDNumber = (idNumber: string) => {
	try {
		if (idNumber.length != 13) {
			throw new Error('Invalid ID Number');
		} else {
			let dob: DOB = getDOBFromIDNumber(idNumber);
			if (dob.day < 1 || dob.day > 31) throw new Error('Invalid Day of birth');
			else if (dob.month < 1 || dob.month > 12) throw new Error('Invalid Month of birth');
			else if (dob.year < 1923 || dob.year > new Date().getFullYear()) throw new Error('Invalid year of birth');
			else return true;
		}
	} catch (error) {
		throw error;
	}
};
