import { CommonState } from '@/types';
import { Reconciliation } from '../types';

export interface State extends CommonState {
	reconciliation: Reconciliation.ReconciliationType | null;
	reconciliations: Reconciliation.ReconciliationType[];
}

export const state: State = {
	reconciliation: null,
	reconciliations: [],
	response: {
		status: 'IDLE'
	}
};
