import { router as coreRoutes } from '@/core';
import { UserRole } from '@/enums';
import { beforeEachGuard, beforeResolveGuard } from '@/middlewares/auth-guard';
import { router as payrollAccountingRoutes } from '@/modules/payroll-accounting';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [...coreRoutes, ...payrollAccountingRoutes];
declare module 'vue-router' {
	interface RouteMeta {
		authorizedRoles?: UserRole[];
		requiresAuth?: boolean;
		title?: string;
	}
}
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(beforeEachGuard);

router.beforeResolve(beforeResolveGuard);

export default router;
