import useCompany from '@/core/modules/company/composables/use-company';
import { onMounted, computed, watch } from '@vue/runtime-core';
import { billingStore } from '../store';
import { useAuth } from '@/core/modules/user/composables/use-auth';
import { Unsubscribe } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export function useBillings() {
	const store = billingStore();
	const billings = computed(() => store.billings);
	const billing = computed(() => store.billing);
	const streamResponse = computed(() => store.streamResponse);
	const { company } = useCompany();
	let unsubscribe: Unsubscribe;
	const router = useRouter();
	const hasPayed = computed(
		() => streamResponse.value?.status === 'SUCCESS' && billings.value.filter((bill) => !bill.hasPayed).length == 0
	);

	watch(
		() => company.value,
		() => {
			if (company.value && router.currentRoute.value.name !== 'ViewingInvoice') {
				setupBillings(company.value.id!);
			}
		}
	);

	async function setupBillings(companyId: string) {
		unsubscribe = store.STREAM_BILLINGS(companyId);
	}

	async function setupBilling(billingId: string) {
		if (unsubscribe) unsubscribe();
		console.log(billingId);

		unsubscribe = store.STREAM_BILLING(billingId);
	}

	return {
		billings,
		billing,
		streamResponse,
		hasPayed,
		setupBillings,
		setupBilling
	};
}
