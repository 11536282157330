import { Endpoint, UploadFolder } from '@/enums';
import UploadRepository from '@/repositories/upload-repository';
import { OnQueryObserver, PayslipType, ProcessedPayslipType } from '@/types';
import { postRequest } from '@/utils';
import { Unsubscribe } from 'firebase/auth';
import {
	addDoc,
	doc,
	DocumentData,
	DocumentReference,
	getDocs,
	onSnapshot,
	query,
	QueryConstraint,
	serverTimestamp,
	Timestamp,
	updateDoc,
	where
} from 'firebase/firestore';
import moment from 'moment';
import { PayslipQueryConstrants } from '../types';
import { preparePayslip } from '../utils';
import BasePayslipRepository from './base-payslip-repository';

export class PayslipRepository extends BasePayslipRepository {
	payslipDocRef = (id: string): DocumentReference<DocumentData> => doc(this.db, 'payslips', id);

	async createPayslip(payslip: ProcessedPayslipType): Promise<void> {
		try {
			delete payslip.id;
			console.log(payslip);

			await addDoc(this.payslipsRef, { ...payslip, createdAt: serverTimestamp() });
		} catch (error) {
			throw error;
		}
	}
	async verifyPayslip(payslip: PayslipType): Promise<void> {
		try {
			const uploadRepository = new UploadRepository();
			let base64image = await uploadRepository.downloadFileFromFirebaseStorage(
				payslip.company?.id!,
				UploadFolder.AVATAR
			);
			payslip.company!.logoUrl = base64image as string;
			payslip.period = moment(payslip.period, 'YYYY-MM-DD').format('DD-MM-YYYY');
			payslip.createdAt = moment((payslip.createdAt as Timestamp).toDate()).format('DD-MM-YYYY');

			const { status, data } = await postRequest(Endpoint.PAYSLIP, payslip);

			if (status == 200) {
				await uploadRepository.uploadPdf(
					data,
					(progress: number) => {},
					async (success: boolean, data: any) => {
						if (success) {
							await updateDoc(this.payslipDocRef(payslip.id!), {
								verified: !payslip.verified,
								downloadUrl: data,
								updatedAt: serverTimestamp()
							});
						} else {
							throw new Error(data);
						}
					},
					payslip.id!,
					UploadFolder.PAYSLIP
				);
			}
		} catch (error) {
			throw error;
		}
	}

	streamPayslips(
		{ employeeId, companyId, period, verified }: PayslipQueryConstrants,
		observer: OnQueryObserver
	): Unsubscribe {
		let constraints: Array<QueryConstraint> = [where('company.id', '==', companyId)];

		if (verified != undefined) {
			constraints.push(where('verified', '==', verified));
		}

		if (employeeId) {
			constraints.push(where('employee.id', '==', employeeId));
		}

		if (period) {
			constraints.push(where('period', '==', period));
		}

		let q = query(this.payslipsRef, ...constraints);
		return onSnapshot(q, observer);
	}

	streamPayslip({ employeeId, companyId, period }: PayslipQueryConstrants, observer: OnQueryObserver): Unsubscribe {
		let constraints: Array<QueryConstraint> = [where('company.id', '==', companyId)];

		if (employeeId) {
			constraints.push(where('employee.id', '==', employeeId));
		}

		if (period) {
			constraints.push(where('period', '==', period));
		}

		let q = query(this.payslipsRef, ...constraints);
		return onSnapshot(q, observer);
	}

	async getPayslip(companyId: string, employeeId: string, period: string): Promise<PayslipType | null> {
		try {
			let q = query(
				this.payslipsRef,
				where('employeeId', '==', employeeId),
				where('companyId', '==', companyId),
				where('period', '==', period)
			);
			let { docs } = await getDocs(q);
			if (docs.length > 0) {
				let { id, data } = docs[0];
				return {
					id,
					...data()
				};
			}
			return null;
		} catch (error) {
			throw error;
		}
	}
	getPayslipById(id: string): Promise<PayslipType | null> {
		throw new Error('Method not implemented.');
	}
}
