import { leaveRepo } from '@/di';
import { EmployeeType, LeaveType, Response } from '@/types';
import { Unsubscribe } from 'firebase/firestore';
import { _ActionsTree } from 'pinia';
import EmployeeRepository from '../../employee/repositories/employee-repository';
import { LeaveActions } from '../enum';
import LeaveRepository from '../repos/leave-repo-impl';
import { LeaveConstraints, LeaveDecline } from '../types';

export interface Actions extends _ActionsTree {
	/**
	 * Create a leave
	 * @param {LeaveType} payload - Leave to create
	 * @returns {Promise<void>}
	 */
	[LeaveActions.CREATE_LEAVE]: (payload: LeaveType) => Promise<void>;
	/**
	 * Request a leave
	 * @param {LeaveType} paylod - Leave to request
	 * @returns {Promise<void>}
	 */
	[LeaveActions.REQUEST_LEAVE]: (payload: LeaveType) => Promise<void>;

	/**
	 *  Stream leaves
	 * @param {LeaveConstraints} payload - Leave constraints
	 * @returns {Unsubscribe} - Unsubscribe function
	 */
	[LeaveActions.STREAM_LEAVES]: (payload: LeaveConstraints) => Unsubscribe;

	/**
	 * Stream leave
	 * @param {string} id - Leave id
	 * @returns {Unsubscribe} - Unsubscribe function
	 */
	[LeaveActions.STREAM_LEAVE]: (id: string) => Unsubscribe;

	/**
	 * Approve leave
	 * @param {string} id - Leave id
	 * @returns {Promise<void>}
	 */
	[LeaveActions.APPROVE_LEAVE]: (id: string) => Promise<void>;

	/**
	 * Decline leave
	 * @param {LeaveDecline} payload - Leave decline
	 * @returns {Promise<void>}
	 */
	[LeaveActions.DECLINE_LEAVE]: (payload: LeaveDecline) => Promise<void>;
}

export const actions: Actions = {
	CREATE_LEAVE: async function (payload: LeaveType): Promise<void> {
		return await leaveRepo.createLeave(payload);
	},
	REQUEST_LEAVE: async function (payload: LeaveType): Promise<void> {
		return await leaveRepo.requestLeave(payload);
	},
	STREAM_LEAVES: function (constraints: LeaveConstraints): Unsubscribe {
		return leaveRepo.streamLeaves(constraints);
	},
	STREAM_LEAVE: function (id: string): Unsubscribe {
		return leaveRepo.streamLeave(id);
	},
	APPROVE_LEAVE: async function (id: string): Promise<void> {
		return await leaveRepo.approveLeave(id);
	},
	DECLINE_LEAVE: async function ({ id, comment }: LeaveDecline): Promise<void> {
		return await leaveRepo.declineLeave(id, comment);
	}
};
