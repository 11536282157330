
import { computed, defineComponent } from 'vue';

export default defineComponent({
	props: {
		title: {
			type: String,
			required: true
		},
		isLoaded: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const title = computed(() => props.title);
		const isLoaded = computed(() => props.isLoaded);
		return {
			title,
			isLoaded
		};
	}
});
