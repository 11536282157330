/**
 *  ======== General Enums =========
 */

export enum RequestStatus {
	APPROVED = 'Approved',
	PENDING = 'Pending',
	DECLINED = 'Declined'
}

export enum RequestResponse {
	LOADING = 'LOADING',
	IDLE = 'IDLE',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	EMPTY = 'EMPTY'
}

export enum LocalStorageKeys {
	LOCAL_USER = 'LOCAL_USER',
	LOCAL_EMPLOYEE = 'LOCAL_EMPLOYEE',
	LOCAL_EMPLOYEE_ID = 'LOCAL_EMPLOYEE_ID'
}

export enum CloudFunctionNames {
	ADD_STAFF = 'addStaff'
}
/**
 *  ======== Core Enums =========
 */

export enum UserRole {
	STAFF = 'STAFF',
	SUPERVISOR = 'SUPERVISOR',
	COMPANY_ADMIN = 'COMPANY_ADMIN',
	ADMIN = 'ADMIN',
	MANAGER = 'MANAGER'
}

export enum Gender {
	MALE = 'Male',
	FEMALE = 'Female',
	OTHER = 'Other'
}

export enum Title {
	MR = 'Mr.',
	DR = 'Dr.',
	MRS = 'Mrs.',
	MISS = 'Miss',
	MS = 'Ms.'
}

export enum MaritalStatus {
	SINGLE = 'Single',
	MARRIED = 'Married',
	DIVORCED = 'Divorced',
	WIDOW = 'Widow'
}

/**
 *  ======== Payroll Accounting Enums =========
 */

export namespace Enum {
	export enum ClockType {
		ON_DUTY = 'On duty',
		TEA = 'Tea',
		LUNCH = 'Lunch',
		OTHER = 'Other'
	}

	export enum LeaveType {
		ANNUAL = 'Annual',
		SICK = 'Sick',
		PATERNITY = 'Paternity',
		MARTENITY = 'Matenity',
		FAMILY_RESPONSIBILITY = 'Family Responsibility'
	}

	export enum DaysOfTheWeek {
		MONDAY_TO_THURSDAY = 'Monday - Thursday',
		FRIDAY = 'Friday',
		SATURDAY = 'Saturday',
		SUNDAY = 'Sundays'
	}

	export enum PayDays {
		DAY_BEFORE_MONTH_END = 'Day before month end',
		MONTH_END = 'Month end'
	}

	export enum Groups {
		FIRST_GROUP = 'First group',
		SECOND_GROUP = 'Second group',
		THIRD_GROUP = 'Third group',
		FORTH_GROUP = 'Forth group',
		FIFTH_GROUP = 'Fifth group',
		SIXTH_GROUP = 'Sixth group'
	}

	export enum DataStream {
		SINGLE = 'Single',
		BULK_COMPANY = 'Bulk company',
		BULK_EMPLOYEE = 'Bulk employee'
	}

	export enum PaymentStatus {
		COMPLETE = 'COMPLETE',
		CANCELLED = 'CANCELLED',
		INCOMPLETE = 'INCOMPLETE'
	}

	export enum RouteNames {
		REGISTER = 'Register',
		LOGIN = 'Login',
		REGISTER_COMPANY_INFO = 'RegisterCompanyInfo',
		REGISTER_BASIC_INFO = 'RegisterBasicInfo',
		REVIEW_REG = 'ReviewReg',
		REGISTER_PAYMENT = 'RegisterPayment',
		DASHBOARD = 'HomeScreen',
		EMPLOYEE_DASHBOARD = 'EmployeeDashboard'
	}

	export enum PayrollRoutes {
		COMPANY_TIMES = '/payroll-accounting/company-times',
		COMPANY_INFO = '/payroll-accounting/company/info',
		CONTRACTS = '/payroll-accounting/contracts'
	}

	export enum PaymentType {
		SUBSCRIPTION = 'SUBSCRIPTION',
		LICENCE = 'LICENCE',
		ALL = 'ALL'
	}

	export enum PaymentReturnURL {
		REGISTRATION_CANCEL = 'https://acc.kunokhar.com/register/review-reg',
		REGISTRATION_SUCCESS = 'https://acc.kunokhar.com/payroll-accounting',
		PAYMENT_CANCEL = 'https://acc.kunokhar.com/billing'
	}
}

export enum PaymentInterval {
	MONTHLY = 'Monthly',
	WEEKLY = 'Weekly',
	DAILY = 'Daily'
}

export enum DialogType {
	MODAL_FULLSCREEN = 'modal-fullscreen',
	MODAL_FULLSCREEN_SM_DOWN = 'modal-fullscreen-sm-down'
}

export enum ResponseMutations {
	SET_RESPONSE = 'response/SET_RESPONSE'
}

export enum Endpoint {
	PAYSLIP = '/payslip',
	CONTRACT = '/contract'
}

export enum UploadFolder {
	PAYSLIP = 'uploads/payslip/',
	CONTRACT = 'uploads/contract/',
	AVATAR = 'avatar/'
}

export enum FullMonths {
	JANUARY = 'January',
	FEBRUARY = 'February',
	MARCH = 'March',
	APRIL = 'April',
	MAY = 'May',
	JUNE = 'June',
	JULY = 'July',
	AUGUST = 'August',
	SEPTEMBER = 'September',
	OCTOBER = 'October',
	NOVEMBER = 'November',
	DECEMBER = 'December'
}

export enum ShortMonths {
	JANUARY = 'Jan',
	FEBRUARY = 'Feb',
	MARCH = 'Mar',
	APRIL = 'Apr',
	MAY = 'May',
	JUNE = 'Jun',
	JULY = 'Jul',
	AUGUST = 'Aug',
	SEPTEMBER = 'Sep',
	OCTOBER = 'Oct',
	NOVEMBER = 'Nov',
	DECEMBER = 'Dec'
}
