import { ClockType } from '@/types';
import { ClockRepository } from '../repositories/clock-repository';
import { ClockMutations, ClockQueryConstraints } from '../types';

const clockRepository = new ClockRepository();

export const registerTime = async (_: any, clock: ClockType) => await clockRepository.registerTime(clock);

export const streamClocks = async ({ commit }: any, constraints: ClockQueryConstraints) =>
	clockRepository.streamClockEntries(constraints, {
		next: (snapshot) => {
			commit(ClockMutations.SET_CLOCKS, snapshot.docs);
		},
		error(error) {
			console.log(error);
			throw error;
		}
	});

export const streamClock = async ({ commit }: any, id: string) =>
	clockRepository.streamClockEntryById(id, {
		next: (snapshot) => {
			commit(ClockMutations.SET_CLOCK, snapshot);
		},
		error(error) {
			console.log(error);
			throw error;
		}
	});
