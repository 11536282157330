export enum CompanyActions {
	STREAM_COMPANY = 'company/streamCompany',
	UPDATE_COMPANY_INFO = 'company/updateCompanyInfo',
	ADD_COMPANY_INFO = 'company/addCompanyInfo',
	UPLOAD_LOGO = 'company/uploadLogo',
	SAVE_LOGO_DOWNLOAD_LINK = 'company/saveLogoDownloadLink',
	SAVE_WORKING_HOURS = 'company/saveWorkingHours',
	SAVE_PAYDAYS = 'company/savePaydays',
	SAVE_LUNCH_TIMES = 'company/saveLunchTimes',
	SAVE_TEA_TIMES = 'company/saveTeaTimes'
}

export enum CompanyMutations {
	SET_COMPANY = 'SET_COMPANY',
	SET_COMPANIES = 'SET_COMPANIES'
}

export enum CompanyGetters {
	ACTIVE_PAYDAYS = 'company/activePaydays',
	BILLS = 'company/bills',
	BILL = 'company/bill'
}
