<template>
	<div class="company-info">
		<form @submit.prevent="submitCompanyInfo" @keypress.enter="submitCompanyInfo($event)">
			<div class="row">
				<div class="col-sm-12">
					<TextField :placeholder="'Company Name'" :controller="companyForm.name" v-model="companyForm.name" />
				</div>
				<div class="col-sm-12">
					<VueGoogleAutocomplete
						id="map"
						:country="['za']"
						placeholder="Company Address"
						v-on:placechanged="getAddress"
						v-model="companyForm.address"
					/>
				</div>
				<div class="col-sm-6">
					<TextField
						:placeholder="'Company EMP 201'"
						:controller="companyForm.emp201"
						v-model="companyForm.emp201"
					/>
				</div>
				<div class="col-sm-6">
					<TextField :placeholder="'Company UIF'" :controller="companyForm.uif" v-model="companyForm.uif" />
				</div>
				<div class="col-sm-6">
					<TextField :placeholder="'Company SDL'" :controller="companyForm.sdl" v-model="companyForm.sdl" />
				</div>
				<div class="col-sm-6">
					<TextField
						:placeholder="'Company Registration Number'"
						:controller="companyForm.registrationNumber"
						v-model="companyForm.registrationNumber"
					/>
				</div>
				<div class="col-sm-6">
					<TextField
						:placeholder="'Company Vat Number'"
						:controller="companyForm.vatNumber"
						v-model="companyForm.vatNumber"
					/>
				</div>
				<div class="col-sm-12 mt-4">
					<button type="submit" class="main-btn w3-right">Next Step</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import TextField from '@/components/TextField.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { inject } from '@vue/runtime-core';
import { CompanyInfoKey, SubmitCompanyInfoKey } from '../../symbols';
export default {
	components: {
		TextField,
		VueGoogleAutocomplete
	},
	setup() {
		const companyForm = inject(CompanyInfoKey, {});
		const submitCompanyInfo = inject(SubmitCompanyInfoKey);
		function getAddress(e) {
			companyForm.address = `${e.street_number} ${e.route}, ${e.locality}, ${e.country}`;
		}
		return {
			companyForm,
			submitCompanyInfo,
			getAddress
		};
	}
};
</script>
