import { vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    placeholder: _ctx.placeholder,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.controller) = $event)),
    autocomplete: "none"
  }, null, 8, _hoisted_1)), [
    [_vModelDynamic, _ctx.controller]
  ])
}