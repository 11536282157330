
import { PayslipType } from '@/types';
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import { currencyFormat } from '@/utils';
import { VerifyPayslipKey } from '../modules/payslip/symbols';
import router from '@/router';

export default defineComponent({
	props: {
		payslip: {
			type: Object as PropType<PayslipType>,
			require: true
		}
	},
	setup(props) {
		const payslip = computed(() => props.payslip);
		const deductions = ref(payslip.value?.deductions?.reduce((prev, curr) => prev + curr.amount!, 0));
		const verifyPayslip = inject(VerifyPayslipKey);
		function viewPayslip() {
			router.push({
				name: 'EmployeePayslip',
				params: {
					employeeId: payslip.value?.employee?.id,
					period: payslip.value?.period,
					companyId: payslip.value?.company?.id
				}
			});
		}

		return { payslip, currencyFormat, deductions, verifyPayslip, viewPayslip };
	}
});
