
import LoadingOrEmpty from '@/components/LoadingOrEmpty.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';
import { Enum } from '@/enums';
import router from '@/router';
import { CompanyKey, UserTypeKey } from '@/symbols';
import { ClockType, DeductionType, GroupedClockType, LeaveType } from '@/types';
import { currencyFormat, Time } from '@/utils';
import ApexCharts from 'apexcharts';
import { Timestamp } from 'firebase/firestore';
import { defineComponent, inject, onMounted, provide, ref, watch } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import Clocking from '../modules/clock/components/modal/Clocking.vue';
import useClock from '../modules/clock/composable/use-clock';
import { ClockFormKey, RegisterTimeFunction } from '../modules/clock/symbols';
import { totalWorkTime } from '../modules/clock/utils';
import useLeave from '../modules/leave/composables/use-leave';
import { useSelfPayslip } from '../modules/payslip/composables/use-payslip';
export default defineComponent({
	components: {
		UnderConstruction,
		Clocking,
		VueApexCharts,
		LoadingOrEmpty
	},
	setup() {
		const company = inject(CompanyKey);
		const user = inject(UserTypeKey);
		let chart: ApexCharts;

		const { clocks, clockStatus, constraints, setupAll, form, registerTime, setupGroupedClocks, groupedClocks } =
			useClock({
				companyId: company?.value ? company?.value.id : '',
				employeeId: user?.value ? user?.value.id : '',
				dataStream: Enum.DataStream.BULK_EMPLOYEE
			});

		const { status: payslipStatus, latestPayslip, requestPayslip, downloadPayslip } = useSelfPayslip();
		const latestClock = ref<GroupedClockType>();
		const upcomingLeaves = ref<LeaveType[]>([]);
		const { leaves, leavesLoadingStatus } = useLeave({
			employeeId: user?.value ? user?.value?.id : undefined,
			companyId: company?.value ? company.value.id : undefined
		});

		provide(ClockFormKey, form);
		provide(RegisterTimeFunction, registerTime);

		function nettPay() {
			return currencyFormat(
				latestPayslip.value && latestPayslip.value.employee && latestPayslip.value.employee.salary
					? latestPayslip.value.employee?.salary! -
							latestPayslip.value.deductions?.reduce(
								(prev: number, current: DeductionType) => prev + current.amount!,
								0
							)!
					: 0
			);
		}

		function viewPayslip() {
			router.push({
				name: 'EmployeePayslip',
				params: {
					employeeId: latestPayslip.value?.employee?.id,
					period: latestPayslip.value?.period,
					companyId: latestPayslip.value?.company?.id
				}
			});
		}
		watch(
			() => company?.value,
			() => {
				constraints.value.companyId = company?.value ? company?.value.id : '';
				form.employeeId = user?.value?.id;
				form.companyId = company?.value.id;
				setupAll();
			}
		);

		watch(
			() => clocks.value,
			() =>
				setupGroupedClocks(() => {
					groupedClocks.value[0].clocks?.sort(
						(a, b) => (a.createdAt as Timestamp).toMillis() - (b.createdAt as Timestamp).toMillis()
					);
					latestClock.value = groupedClocks.value[0] as GroupedClockType;

					let xy: { x: string; y: number }[] = [];
					for (let i = 0; i < groupedClocks.value.length; i++) {
						const element = groupedClocks.value[i];
						let t = totalWorkTime(element.clocks as ClockType[]).totalTime;
						xy.push({ x: element.date as string, y: t.hours });
					}

					xy.reverse();

					if (chart) {
						chart.updateOptions({ series: [{ data: xy }] }, true, true, true);
					}
				})
		);

		watch(
			() => leaves.value,
			() => {
				upcomingLeaves.value = [];
				leaves.value.forEach((l: LeaveType) => {
					if (Time.getTimeDifferenceFromNow(l.duration?.start! as Date) >= 0) {
						upcomingLeaves.value.push(l);
					}
				});
			}
		);

		onMounted(async () => {
			chart = new ApexCharts(document.querySelector('#apex-charts'), {
				chart: {
					id: 'mychart',
					height: 300,
					witdth: 500,
					type: 'area'
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'smooth'
				},
				xaxis: {
					categories: []
				},
				series: [
					{
						name: 'Attendence Graph',
						data: []
					}
				]
			});

			await chart.render();
		});

		return {
			clockStatus,
			clocks,
			upcomingLeaves,
			form,
			registerTime,
			groupedClocks,
			latestClock,
			latestPayslip,
			payslipStatus,
			currencyFormat,
			Time,
			nettPay,
			downloadPayslip,
			requestPayslip,
			viewPayslip,
			leavesLoadingStatus
		};
	}
});
