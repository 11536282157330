
import { computed, defineComponent, PropType } from 'vue';
import { PerfomanceTaskType } from '@/types';
import TextField from '@/components/TextField.vue';
import { useAuth } from '../../composables/use-auth';
export default defineComponent({
	components: {
		TextField
	},
	setup() {
		const { email, submitResetPassword } = useAuth();

		return {
			email,
			submitResetPassword
		};
	}
});
