<template>
	<div class="inner-navigation">
		<ul class="left-list list-unstyled">
			<li class="search-form">
				<input type="search" name="find_emp" placeholder="Search" />
				<button type="button" class="search-btn">
					<i class="fa fa-search"></i>
				</button>
			</li>
		</ul>
		<ul class="right-list list-unstyled">
			<li>
				<router-link to="/add-employee/basic-info" class="main-btn">Add Employee</router-link>
			</li>
		</ul>
	</div>
</template>
<script>
export default {};
</script>
