import { decrementCounter } from '@/utils';
import { reconciliationStore } from '@/store';
import { addDoc, getDoc, serverTimestamp, setDoc, Unsubscribe, updateDoc, onSnapshot } from 'firebase/firestore';
import { ReconciliationRepo } from './reconciliation-repo';
import { singleton } from 'tsyringe';
import { Reconciliation } from '../types';
import { v4 as uuidV4 } from 'uuid';
import { addToArray, incrementCounter } from '@/utils';
import { ReconciliationStatus, ReconciliationTypes } from '../enum';
@singleton()
export class ReconciliationRepoImpl extends ReconciliationRepo {
	async addReconciliation(payload: Reconciliation.CreatePayment): Promise<void> {
		const store = reconciliationStore();
		try {
			this.docId = payload.companyId;
			const payments = store.reconciliation?.payments || [];
			const payment: Reconciliation.Payment = {
				id: uuidV4(),
				createdAt: new Date(),
				...payload
			};
			const { taxAmountOwing, taxAmountPaid, salaryAmountOwing, salaryAmountPaid } = this.calculateSummary(payment);
			if (store.reconciliation != null) {
				await updateDoc(this.reconciliationDoc, {
					taxAmountOwing: incrementCounter(taxAmountOwing!),
					taxAmountPaid: incrementCounter(taxAmountPaid!),
					salaryAmountOwing: incrementCounter(salaryAmountOwing!),
					salaryAmountPaid: incrementCounter(salaryAmountPaid!),
					payments: payments.length > 0 ? addToArray(payment) : [payment],
					updatedAt: serverTimestamp()
				});
			} else {
				await setDoc(this.reconciliationDoc, {
					...this.calculateSummary(payment),
					payments: [payment],
					createdAt: serverTimestamp()
				});
			}
		} catch (error) {
			console.log(error);
			throw error;
		}
	}

	private calculateSummary(
		payment: Reconciliation.Payment
	): Partial<
		Pick<
			Reconciliation.ReconciliationType,
			'salaryAmountOwing' | 'salaryAmountPaid' | 'taxAmountOwing' | 'taxAmountPaid'
		>
	> {
		let { status, amount, type } = payment;
		amount = parseFloat(amount.toString());

		if (payment.type === ReconciliationTypes.SALARY_PAYMENT) {
			return {
				salaryAmountOwing: status === ReconciliationStatus.OWING ? amount : amount * -1,
				salaryAmountPaid: status === ReconciliationStatus.PAID ? amount : 0,
				taxAmountOwing: 0,
				taxAmountPaid: 0
			};
		} else {
			return {
				taxAmountOwing: status === ReconciliationStatus.OWING ? amount : amount * -1,
				taxAmountPaid: status === ReconciliationStatus.PAID ? amount : 0,
				salaryAmountOwing: 0,
				salaryAmountPaid: 0
			};
		}
	}

	updateReconciliation(id: string, payload: Reconciliation.UpdatePayment): Promise<void> {
		this.docId = id;
		throw new Error('Method not implemented.');
	}

	async getReconciliation(id: string): Promise<Reconciliation.ReconciliationType | null> {
		try {
			const store = reconciliationStore();
			this.docId = id;
			let snapshot = await getDoc(this.reconciliationDoc);
			console.log(snapshot.data());

			if (!snapshot.exists()) return null;

			store.reconciliation = snapshot.data() as Reconciliation.ReconciliationType;
			return snapshot.data() as Reconciliation.ReconciliationType;
		} catch (e) {
			let error: Error = e as Error;
			console.log(error);
			throw new Error(error.message);
		}
	}
	getReconciliations(companyId: string): Promise<Reconciliation.ReconciliationType[]> {
		throw new Error('Method not implemented.');
	}
	streamReconciliation(payload: Reconciliation.StreamPayload): Unsubscribe | undefined {
		this.docId = payload.companyId;
		return onSnapshot(this.reconciliationDoc, {
			next(snapshot) {
				const store = reconciliationStore();
				if (snapshot.exists()) {
					store.reconciliation = snapshot.data() as Reconciliation.ReconciliationType;
					store.response = {
						status: 'SUCCESS'
					};
				} else {
					store.response = {
						status: 'EMPTY',
						message: 'No reconciliation found!'
					};
				}
			}
		});
	}
}
