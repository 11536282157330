import { accountingYearAge } from '@/utils';
import moment from 'moment';
import taxRebates from '../data/sars/tax-rebates.json';
import taxThresholds from '../data/sars/tax-thresholds.json';
import taxableIncome from '../data/sars/taxable-income.json';
import SarsIncome from '../types/sars-income';
import SarsTaxableIncome from '../types/sars-taxable-income';
import { convertToTwoDecimalPlace } from '../utils/helper';

type ageGroups = 'primary' | 'secondary' | 'tertiary';
export class PAYE {
	taxYearIncome?: SarsTaxableIncome;
	salary: number = 0;
	dateOfEmployment: string = new Date().toDateString();
	age: number = 0;
	months: Array<number> = [];
	period: string = '';
	taxYearErrorText: string = 'Oops, looks like we do not have this tax year data';

	constructor(salary = 0, dateOfEmployment = new Date().toDateString(), idNumber: string, period: string) {
		if (idNumber && period) {
			try {
				let currentDate = moment(period, 'YYYY-MM-DD');

				for (let index = 0; index < taxableIncome.length; index++) {
					const income = taxableIncome[index];
					if (
						currentDate.isBetween(income.dateStart, income.dateEnd) ||
						currentDate.diff(income.dateEnd, 'days') == 0
					) {
						let { dateStart, dateEnd, year, incomes } = income;
						this.taxYearIncome = {
							dateStart,
							dateEnd,
							year,
							incomes: incomes as Array<SarsIncome>
						};
					}
				}
				if (!this.taxYearIncome) throw new Error('Oops, looks like we do not have this tax year data');
				this.salary = salary;
				this.dateOfEmployment = dateOfEmployment;
				this.age = accountingYearAge(idNumber);
				this.months = [];
				this.taxMonths();
				this.period = period;
			} catch (error) {
				throw error;
			}
		} else throw new Error('You have not worked yet');
	}
	tax() {
		try {
			if (!this.taxYearIncome) throw new Error(this.taxYearErrorText);
			if (!this.period) throw new Error('You have not worked this far year');
			if (this.taxYearIncome) {
				if (this.annualSalary() >= this.getTaxableThreshold()) {
					let rawTax = 0;

					if (this.getTaxPercentage().lowerPrice == 1) {
						rawTax = this.annualSalary() * (this.getTaxPercentage().taxRate / 100);
					} else {
						rawTax = this.annualSalary() - (this.getTaxPercentage().lowerPrice - 1);
						rawTax *= this.getTaxPercentage().taxRate / 100;
						rawTax += this.getTaxPercentage().additionalFee;
					}
					return convertToTwoDecimalPlace(this.actualMonthlyTax(rawTax));
				} else {
					return 0;
				}
			} else throw new Error('Oops, looks like we do not have this tax year data');
		} catch (error) {
			throw error;
		}
	}

	getTaxableThreshold() {
		return taxThresholds.filter((threshold) => threshold.year == this.taxYearIncome!.year)[0].ageGroup[
			this.getAgeGroup
		].amount;
	}

	actualMonthlyTax(rawTax: number) {
		let rebate = taxRebates.filter((element) => element.year == this.taxYearIncome!.year)[0];

		switch (this.getAgeGroup) {
			case 'primary':
				return (rawTax - rebate.ageGroup.primary.amount) / this.dateMultiplier();
			case 'secondary':
				return (rawTax - rebate.ageGroup.primary.amount - rebate.ageGroup.secondary.amount) / this.dateMultiplier();

			case 'tertiary':
				return (
					(rawTax -
						rebate.ageGroup.primary.amount -
						rebate.ageGroup.secondary.amount -
						rebate.ageGroup.tertiary.amount) /
					this.dateMultiplier()
				);
		}
	}

	getTaxPercentage() {
		return this.taxYearIncome!.incomes.filter((income: SarsIncome) => {
			if (
				this.annualSalary() >= income.lowerPrice &&
				(this.annualSalary() <= income.upperPrice || income.upperPrice == 0)
			) {
				return income;
			}
		})[0];
	}

	annualSalary() {
		let employmentDate = moment(this.dateOfEmployment);
		let employmentYear = employmentDate.year();
		let employmentMonth = employmentDate.month();

		if (
			employmentDate.isBetween(this.taxYearIncome?.dateStart, this.taxYearIncome?.dateStart) ||
			employmentDate.diff(this.taxYearIncome?.dateEnd, 'days') == 0
		) {
			let index = this.months.indexOf(employmentMonth);

			if (index > 0) return (this.salary / (12 - index)) * 12;
			else return this.salary * 12;
		}
		return this.salary * 12;
	}

	get getAgeGroup(): ageGroups {
		if (this.age < 65) return 'primary';
		else if (this.age > 65 && this.age < 75) return 'secondary';
		return 'tertiary';
	}

	dateMultiplier() {
		let employmentDate = moment(this.dateOfEmployment);
		let employmentMonth = employmentDate.month();

		if (
			employmentDate.isBetween(this.taxYearIncome?.dateStart, this.taxYearIncome?.dateStart) ||
			employmentDate.diff(this.taxYearIncome?.dateEnd, 'days') == 0
		) {
			let index = this.months.indexOf(employmentMonth);

			return 12 / (12 - index);
		}
		return 12;
	}

	taxMonths() {
		let count = 2;
		let totalMonths = 12;
		for (let index = 0; index < totalMonths; index++) {
			this.months.push(count);
			if (count == 11) count = 0;
			else count++;
		}
	}
}
