import { AddressType } from '@/types';

export const currencyFormat = (amount: any) =>
	new Intl.NumberFormat('en-ZA', {
		style: 'currency',
		currency: 'ZAR'
	}).format(amount);

export const addressFormat = (address: string): AddressType => {
	let addressStr = address.split(',');
	return {
		street: addressStr[0] ? addressStr[0] : '',
		city: addressStr[1] ? addressStr[1] : '',
		country: addressStr[2] ? addressStr[2] : '',
		postalCode: addressStr[3] ? addressStr[3] : ''
	};
};

export const dayFormat = (day: string | number) => {
	let number: number = 0;
	if (typeof day == 'number') {
		number = day;
	} else if (typeof day == 'string' && !isNaN(parseInt(day))) {
		number = parseInt(day);
	}

	if (number > 0) {
		if (number == 1) {
			return number + 'st';
		} else if (number == 2) {
			return number + 'nd';
		} else if (number == 3) {
			return number + 'rd';
		} else {
			return number + 'th';
		}
	}

	return day;
};
