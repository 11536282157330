import PerfomanceTask from '@/modules/payroll-accounting/modules/perfomance-contract/models/perfomance-task';
import { CompanyTimes, CompanyType, DurationTime, OnDocumentObserver, PerfomanceTaskType } from '@/types';
import { addToArray } from '@/utils';
import { Auth, getAuth } from 'firebase/auth';
import {
	addDoc,
	collection,
	CollectionReference,
	doc,
	Firestore,
	getDoc,
	getFirestore,
	onSnapshot,
	serverTimestamp,
	updateDoc
} from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import UserRepository from '../../user/repositories/user-repository';
import Company from '../models/company';
import { workingHourToJson } from '../utils';
export default class CompanyRepository {
	db: Firestore;
	auth: Auth;
	companiesRef: CollectionReference;
	constructor() {
		this.db = getFirestore();
		this.auth = getAuth();
		this.companiesRef = collection(this.db, 'companies');
	}

	companyDocRef = (id: string) => doc(this.db, 'companies', id);

	async addCompany(form: CompanyType) {
		form = cloneDeep(form);
		try {
			const company = new Company(
				form.id,
				form.name,
				form.address,
				form.emp201,
				form.uif,
				form.sdl,
				form.registrationNumber,
				form.vatNumber,
				form.numberOfEmployees,
				form.createdAt,
				form.createdBy
			);

			const { id } = await addDoc(this.companiesRef, company.toFirestore());
			const userRepository = new UserRepository();
			return await userRepository.updateCompanyInfo(id);
		} catch (error) {
			throw error;
		}
	}

	async updateInfo(form: CompanyType) {
		try {
			await updateDoc(this.companyDocRef(form.id!), {
				name: form.name,
				address: form.address,
				empl201: form.emp201,
				uif: form.uif,
				registrationNumber: form.registrationNumber,
				vatNumber: form.vatNumber,
				updatedAt: serverTimestamp()
			});
		} catch (error) {}
	}

	streamCompany = (id: string, observer: OnDocumentObserver) => onSnapshot(this.companyDocRef(id), observer);

	async getCompany(id: string) {
		try {
			return await getDoc(this.companyDocRef(id));
		} catch (error) {
			throw error;
		}
	}

	async addPerfomanceTask(companyId: string, task: PerfomanceTaskType) {
		try {
			let perfomanceTask: PerfomanceTask = new PerfomanceTask();
			let company = await this.getCompany(companyId);
			let perfomanceTasks: Array<PerfomanceTask> = Array();
			if (company.exists()) {
				let data = company.data();

				if (data['perfomanceTasks']) perfomanceTasks = [...data['perfomanceTasks']];
			}
			await updateDoc(this.companyDocRef(companyId), {
				perfomanceTasks:
					perfomanceTasks.length > 0
						? addToArray(perfomanceTask.toFirestore(task))
						: [perfomanceTask.toFirestore(task)]
			});
		} catch (error) {
			throw error;
		}
	}

	async getPerfomanceTasksOfCompany(companyId: string) {
		let company = await this.getCompany(companyId);
		let perfomanceTasks: Array<PerfomanceTask> = Array();
		if (company.exists()) {
			let data = company.data();

			if (data['perfomanceTasks']) perfomanceTasks = [...data['perfomanceTasks']];
		}
		return perfomanceTasks;
	}

	async uploadCompanyLogo(logo: string, companyId: string) {
		try {
			await updateDoc(this.companyDocRef(companyId), {
				logo
			});
		} catch (error) {
			throw error;
		}
	}

	async saveHoursOfWork(companyId: string, workingHours: CompanyTimes.WorkingHours[]) {
		try {
			await updateDoc(this.companyDocRef(companyId), {
				hoursOfWork: workingHourToJson(workingHours)
			});
		} catch (error) {
			throw error;
		}
	}

	async savePaydays(companyId: string, paydays: CompanyTimes.PayDay[]) {
		try {
			await updateDoc(this.companyDocRef(companyId), { paydays });
		} catch (error) {
			throw error;
		}
	}
	async saveLunchTimes(companyId: string, lunchTimes: DurationTime[]) {
		try {
			await updateDoc(this.companyDocRef(companyId), { lunchTimes });
		} catch (error) {
			throw error;
		}
	}
	async saveTeaTimes(companyId: string, teaTimes: DurationTime[]) {
		try {
			await updateDoc(this.companyDocRef(companyId), { teaTimes });
		} catch (error) {
			throw error;
		}
	}
}
