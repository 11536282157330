import { convertToTwoDecimalPlace } from '@/modules/payroll-accounting/utils/helper';
import { PayslipType, ProcessedPayslipType } from '@/types';
import { addressFormat, Time } from '@/utils';
import moment from 'moment';

export const preparePayslip = (payslip: PayslipType) => {
	const { company, period, employee, deductions } = payslip;
	const salaries = employee?.salaries;
	if (salaries && salaries?.length > 0) {
		const salary = salaries[salaries.length - 1];
		const totalDeductions = deductions?.reduce((acc, curr) => acc + curr.amount!, 0);
		let netIncome = 0;
		if (totalDeductions && totalDeductions > 0) {
			netIncome = salary.amount - totalDeductions;
		}

		const slip: ProcessedPayslipType = {
			period,
			verified: false,
			company: {
				id: company?.id,
				name: company?.name,
				logoUrl: company?.logo,
				address: addressFormat(company?.address!)
			},
			employee: {
				id: employee?.id,
				name: employee?.name + ' ' + employee?.surname,
				idNumber: employee?.idNumber,
				jobTitle: employee?.jobTitle,
				employmentDate: Time.toDefaultString(employee?.employmentDate!),
				salary: convertToTwoDecimalPlace(salary?.amount)
			},
			grossIncome: convertToTwoDecimalPlace(salary?.amount),
			deductions: deductions,
			totalDeductions: convertToTwoDecimalPlace(totalDeductions!),
			netIncome: convertToTwoDecimalPlace(netIncome)
		};
		return slip;
	} else {
		throw new Error('<No salary found>');
	}
};

export const preparePeriod = (employmentDate: string, payday: string) => {
	let temp = moment(employmentDate, 'YYYY-MM-DD');

	let now = getSplitDate(moment().toDate());
	var dates: string[] = [];

	if (!isNaN(parseInt(payday))) {
		temp = temp.add(1, 'month').set('date', parseInt(payday));

		let tempDate = getSplitDate(temp.toDate());
		if (validDate(now, tempDate)) {
			dates.push(temp.format('YYYY-MM-DD'));
			while (validDate(now, tempDate)) {
				temp = temp.add(1, 'month');

				tempDate = getSplitDate(temp.toDate());
				dates.push(temp.format('YYYY-MM-DD'));
			}
			dates.pop();
		}
	} else if ((payday = 'MONTH_END')) {
		temp = temp.add(1, 'month');
		let day = temp.clone().endOf('M').format('D');
		temp = temp.set('date', parseInt(day));

		let tempDate = getSplitDate(temp.toDate());
		if (validDate(now, tempDate)) {
			dates.push(temp.format('YYYY-MM-DD'));
			while (validDate(now, tempDate)) {
				temp = temp.add(1, 'month');
				temp.set('date', parseInt(temp.clone().endOf('M').format('D')));
				tempDate = getSplitDate(temp.toDate());
				dates.push(temp.format('YYYY-MM-DD'));
			}
			dates.pop();
		}
	}
	if (dates.length > 0) {
		dates = dates.reverse();
	}
	return dates;
};

type SplitDate = {
	day: number;
	month: number;
	year: number;
};

function validDate(now: SplitDate, tempDate: SplitDate) {
	return (
		(now.year == tempDate.year && now.month > tempDate.month) ||
		now.year > tempDate.year ||
		(now.year == tempDate.year && now.month == tempDate.month && now.day >= tempDate.day)
	);
}

function getSplitDate(date: Date) {
	return {
		day: date.getDate(),
		month: date.getMonth() + 1,
		year: date.getFullYear()
	};
}
