<template lang="">
    <div class="my-stats">
        <div class="leave-history">

        </div>
        <div class="salary-stats">
            <div id="chart">
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                series: [{
                    name: "Thousands amount in Rands",
                    data: [30, 30, 30, 51, 49, 62, 69]
                }],
                chartOptions: {
                    chart: {
                        height: 300,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Salary Over The Years',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"],
                    }
                },

            }
        }
    }
</script>