
import TextField from '@/components/TextField.vue';
import { PaymentInterval } from '@/enums';
import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { CreateEmployeeType } from '@/types';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, inject, reactive, ref, watch } from 'vue';
import { EmployeeActions } from '../../types/store-types';
export default defineComponent({
	components: {
		TextField
	},
	setup() {
		const store = useStore();
		const employee = computed(() => store.state.employee.employee);
		const response = inject(ResponseKey);
		const salary = ref<number>(0);
		const form = reactive<CreateEmployeeType>({
			id: employee.value?.id,
			salary: salary.toString(),
			paymentInterval: employee.value?.paymentInterval
		});

		watch(
			() => employee.value,
			() => {
				const salaries = employee.value?.salaries;
				if (salaries) {
					salary.value = salaries[salaries.length - 1]?.amount;
				}
				form.id = employee.value?.id;
				form.salary = salary.value.toString();
				form.paymentInterval = employee.value?.paymentInterval;
			}
		);

		async function submitForm() {
			try {
				response?.loading();
				await store.dispatch(EmployeeActions.ADD_EMPLOYEE_REMUNERATION_INFO, cloneDeep(form));
				response?.success('Remuneration info updated');
			} catch (error) {
				response?.error(error as Error);
			}
		}
		return {
			form,
			submitForm,
			PaymentInterval
		};
	}
});
