import { CompanyType } from '@/types';

export type State = {
    company: CompanyType | null;
    companies: Array<CompanyType>;
};

export const state: State = {
    company: null,
    companies: []
};
