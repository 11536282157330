import { CommonState } from '@/types';
import { BillingModel } from 'kunokhar-acc-types';

export interface State extends CommonState {
	billings: Array<BillingModel>;
	billing: BillingModel | null;
}

export const state: State = {
	billings: [],
	billing: null,
	response: {
		status: 'IDLE'
	},
	streamResponse: {
		status: 'IDLE'
	}
};
