
import LoadingOrEmpty from '@/components/LoadingOrEmpty.vue';
import { Enum } from '@/enums';
import { CompanyKey, UserTypeKey } from '@/symbols';
import { GroupedClockType } from '@/types';
import { Time } from '@/utils';
import { Timestamp } from '@firebase/firestore';
import { cloneDeep } from 'lodash';
import { defineComponent, inject, provide, ref, watch } from 'vue';
import { useEmployeeAction } from '../../employee/composables/use-employee';
import { EmployeeKey } from '../../employee/symbols';
import ClockHistoryCard from '../components/ClockHistoryCard.vue';
import useClock from '../composable/use-clock';
export default defineComponent({
	components: {
		ClockHistoryCard,
		LoadingOrEmpty
	},
	setup() {
		const company = inject(CompanyKey);
		const user = inject(UserTypeKey);
		const { employee } = useEmployeeAction(user?.value?.id);
		const { clocks, clockStatus, constraints, setupAll, setupGroupedClocks, groupedClocks } = useClock({
			companyId: company?.value ? company?.value.id : '',
			employeeId: user?.value ? user?.value.id : '',
			dataStream: Enum.DataStream.BULK_EMPLOYEE
		});

		provide(EmployeeKey, employee);
		watch(
			() => company?.value,
			() => {
				constraints.value.companyId = company?.value ? company?.value.id : '';
				setupAll();
			}
		);

		watch(
			() => clocks.value,
			() => setupGroupedClocks()
		);
		return {
			groupedClocks,
			clockStatus
		};
	}
});
