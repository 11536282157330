import { state, type State } from './state';
import { type Getters } from './getters';
import { type Actions, actions } from './actions';
import { defineStore } from 'pinia';

const billingStore = defineStore<'billing', State, Getters, Actions>({
	id: 'billing',
	state: () => state,
	actions
});

type BillingStore = ReturnType<typeof billingStore>;

export { billingStore, BillingStore };
