export const enum PerfomanceContractActions {
	addPerfomanceTask = 'perfomanceContract/addPerfomanceTask',
	selectPerfomanceTask = 'perfomanceContract/selectPerfomanceTask',
	getPerfomanceTasks = 'perfomanceContract/getPerfomanceTasks',
	updateTasks = 'perfomanceContract/updateTasks'
}

export const enum PerfomanceContractMutations {
	SET_PERFOMANCE_TASKS = 'SET_PERFOMANCE_TASKS',
	SELECT_PERFOMANCE_TASK = 'SELECT_PERFOMANCE_TASK'
}
