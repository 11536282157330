import { reconciliationStore, type ReconciliationStore } from '@/store';
import { CollectionReference, Firestore, getFirestore, collection, Unsubscribe, doc } from 'firebase/firestore';
import { Reconciliation } from './../types';

export abstract class ReconciliationRepo {
	reconciliationRef: CollectionReference<Partial<Reconciliation.ReconciliationType>>;
	protected id?: string;

	db: Firestore;
	constructor() {
		this.db = getFirestore();
		this.reconciliationRef = collection(this.db, 'reconciliations');
	}
	set docId(id: string) {
		this.id = id;
	}
	get reconciliationDoc() {
		return doc(this.db, `reconciliations/${this.id}`);
	}
	abstract addReconciliation(payload: Reconciliation.CreatePayment): Promise<void>;
	abstract updateReconciliation(id: string, payload: Reconciliation.UpdatePayment): Promise<void>;
	abstract getReconciliation(id: string): Promise<Reconciliation.ReconciliationType | null>;
	abstract streamReconciliation(payload: Reconciliation.StreamPayload): Unsubscribe | undefined;
}
