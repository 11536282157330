
import TextField from '@/components/TextField.vue';
import { defineComponent, inject, watch } from '@vue/runtime-core';
import VueGoogleAutocomplete from 'vue-google-autocomplete/src/VueGoogleAutocomplete.vue';
import { BasicInfoFormKey, NextStepKey } from '../symbols';
import { isNumber } from '@/utils';
import cloneDeep from 'lodash.clonedeep';
import { UserRole, Title, MaritalStatus, Gender } from '@/enums';
export default defineComponent({
	components: {
		TextField,
		VueGoogleAutocomplete
	},
	setup() {
		const form = inject(BasicInfoFormKey, {
			name: '',
			surname: '',
			staffNumber: '',
			gender: '',
			title: '',
			email: '',
			address: '',
			maritalStatus: '',
			idNumber: ''
		});

		const nextStep = inject(NextStepKey);
		function getAddress(e: any) {
			form!.address = `${e.street_number} ${e.route}, ${e.locality}, ${e.country}`;
		}

		return {
			form,
			nextStep,
			getAddress,
			UserRole,
			Title,
			MaritalStatus,
			Gender,
			isNumber
		};
	}
});
