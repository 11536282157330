import 'reflect-metadata';
import '@/assets/sass/main.scss';
import '@/config/firebase';
import '@popperjs/core';
import 'bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import 'jquery';
import { App as VueApp, createApp } from 'vue';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueApexCharts from 'vue3-apexcharts';
import 'w3-css';
import App from './App.vue';
import './config/firebase';
// import './registerServiceWorker';
import router from './router';
import { key, store } from './store';
import { EventBus } from './config/event-bus';
import { createPinia } from 'pinia';

const options: PluginOptions = {
	position: POSITION.TOP_RIGHT,
	maxToasts: 2,
	pauseOnHover: true,
	containerClassName: 'toast-container',
	bodyClassName: 'toast-body'
};
let app: VueApp<Element> | null;

onAuthStateChanged(getAuth(), (user) => {
	if (!app) {
		app = createApp(App).use(store, key).use(router).use(VueApexCharts).use(createPinia()).use(Toast, options);
		app.config.globalProperties.$eventBus = EventBus.getInstance;
		app.mount('#app');
	}
});
