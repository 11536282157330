import { UploadProfilePicModel } from '@/models';
import { useStore } from '@/store';
import { ResponseKey } from '@/symbols';
import { validEmail } from '@/utils';
import { getAuth, onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import { UserActions } from '../types/store-types';

export const getUserState = () =>
	new Promise<User | null>((resolve, reject) => onAuthStateChanged(getAuth(), resolve, reject));

export function useAuth() {
	const user = ref<User | null>(null);
	const error = ref<Error | null>(null);

	const auth = getAuth();
	let unsubscribe: Unsubscribe;
	onMounted(() => {
		unsubscribe = onAuthStateChanged(
			auth,
			(u) => (user.value = u),
			(e) => (error.value = e)
		);
	});
	onUnmounted(() => unsubscribe());
	const isAuthenticated = computed(() => user.value != null);

	return {
		user,
		error,
		isAuthenticated,
		...resetPassword()
	};
}

export async function reAuthenticate() {
	const user: User | null = getAuth().currentUser;
	await user?.reload();
}

export function resetPassword() {
	const email = ref<string>('');
	const store = useStore();
	const response = inject(ResponseKey);

	async function submitResetPassword() {
		try {
			if (validEmail(email.value)) {
				response?.loading();
				await store.dispatch(UserActions.RESET_PASSWORD, email.value);
				response?.success('A password reset email has been sent to your email!!');
				email.value = '';
			}
		} catch (error) {
			response?.error(error as Error);
		}
	}

	return {
		email,
		submitResetPassword
	};
}

export function useUpdateProfile() {
	const store = useStore();
	const response = inject(ResponseKey);

	async function uploadProfilePic(profilePic: UploadProfilePicModel) {
		try {
			response?.loading();
			await store.dispatch(UserActions.UPLOAD_PROFILE_PIC, profilePic);
			response?.success('Profile pic updated successfully');
		} catch (error) {
			response?.error(error as Error);
		}
	}

	return {
		uploadProfilePic
	};
}
