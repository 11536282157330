export const enum EmployeeActions {
	ADD_EMPLOYEE_BASIC_INFO = 'employee/addEmployeeBasicInfo',
	ADD_EMPLOYEE_EMPLOYMENT_INFO = 'employee/addEmployeeEmploymentInfo',
	ADD_EMPLOYEE_REMUNERATION_INFO = 'employee/addEmployeeRemunerationInfo',
	STREAM_EMPLOYEES = 'employee/streamEmployees',
	STREAM_EMPLOYEE = 'employee/streamEmployee',
	REGISTER_EMPLOYEE = 'employee/registerEmployee',
	UPDATE_JOB_DATA = 'employee/updateJobData'
}

export const enum EmployeeGetters {
	CURRENT_SALARY = 'employee/currentSalary'
}

export const enum EmployeeMutations {
	SET_EMPLOYEE = 'SET_EMPLOYEE',
	SET_EMPLOYEES = 'SET_EMPLOYEES'
}
