
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default defineComponent({
	components: {
		VueLoading
	},
	setup() {
		const store = useStore();
		const status = computed(() => store.state.response.status);
		return { status };
	}
});
