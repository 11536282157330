import { UserRole } from '@/enums';
import {
        DocumentSnapshot,
        FieldValue,
        serverTimestamp,
        Timestamp
} from 'firebase/firestore';
import { UserType } from '@/types';
export default class User {
        id?: string;
        email?: string;
        password?: string;
        verifyPassword?: string;
        name?: string;
        surname?: string;
        role: string;
        companyId?: string;
        completion?: number;
        idNumber?: string;
        contactNumber?: string;
        createdAt?: Date | Timestamp | FieldValue;

        constructor(
                id?: string,
                email?: string,
                password?: string,
                verifyPassword?: string,
                name?: string,
                surname?: string,
                role: string = UserRole.COMPANY_ADMIN,
                companyId?: string,
                completion?: number,
                idNumber?: string,
                contactNumber?: string,
                createdAt?: Date | Timestamp | FieldValue
        ) {
                this.id = id;
                this.email = email;
                this.password = password;
                this.verifyPassword = verifyPassword;
                this.name = name;
                this.surname = surname;
                this.role = role;
                this.companyId = companyId;
                this.completion = completion;
                this.idNumber = idNumber;
                this.contactNumber = contactNumber;
                this.createdAt = createdAt;
        }

        toFirestore(): UserType {
                return {
                        email: this.email,
                        name: this.name,
                        surname: this.surname,
                        role: this.role,
                        createdAt: serverTimestamp(),
                        completion: 25
                };
        }

        fromFirestore(snapshot: DocumentSnapshot): User {
                this.id = snapshot.id;
                let data = snapshot.data();
                return new User(
                        snapshot.id,
                        data?.email,
                        undefined,
                        undefined,
                        data?.name,
                        data?.surname,
                        data?.role,
                        data?.companyId,
                        data?.completion,
                        data?.idNumber,
                        data?.contactNumber,
                        data?.createdAt
                );
        }
}
