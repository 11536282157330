import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "small-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "loader-empty",
    style: _normalizeStyle({ height: _ctx.$props.parentHeight })
  }, [
    (_ctx.$props.status == 'LOADING')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_ctx.$props.status == 'EMPTY')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "empty",
            style: _normalizeStyle({ 'justify-self': _ctx.justifyPosition })
          }, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$props.message), 1)
          ], 4))
        : _createCommentVNode("", true)
  ], 4))
}