export enum ReconciliationActions {
	ADD_RECONCILIATION = 'ADD_RECONCILIATION',
	DELETE_RECONCILIATION = 'DELETE_RECONCILIATION',
	UPDATE_RECONCILIATION = 'UPDATE_RECONCILIATION',
	GET_RECONCILIATION = 'GET_RECONCILIATION',
	GET_RECONCILIATIONS = 'GET_RECONCILIATIONS',
	STREAM_RECONCILIATION = 'STREAM_RECONCILIATION'
}

export enum ReconciliationGetters {
	AMOUNT_OWING_SUMMARY = 'AMOUNT_OWING_SUMMARY',
	AMOUNT_OWING_SUMMARY_BY_YEAR = 'AMOUNT_OWING_SUMMARY_BY_YEAR',
	NUMBER_OF_OWED_EMPLOYEES = 'NUMBER_OF_OWED_EMPLOYEES',
	EMPLOYEE_AMOUNT_OWING = 'EMPLOYEE_AMOUNT_OWING'
}
export enum ReconciliationTypes {
	TAX_PAYMENT = 'Tax',
	SALARY_PAYMENT = 'Salary'
}

export enum ReconciliationStatus {
	PAID = 'Paid',
	OWING = 'Owing'
}

export enum ReconciliationNames {
	PAYE = 'PAYE',
	UIF = 'UIF',
	SDL = 'SDL',
	SALARY = 'Salary',
	COIDA = 'COIDA'
}

export enum ComplianceStatuses {
	COMPLIANT = 'Compliant',
	NON_COMPLIANT = 'Non Compliant'
}
