export const enum UserActions {
	SIGN_IN = 'user/signIn',
	SIGN_UP = 'user/signUp',
	CONFIRM_DETAILS = 'user/confirmDetails',
	SIGN_OUT = 'user/signOut',
	STREAM_USERS = 'user/streamUsers',
	STREAM_USER = 'user/streamUser',
	GET_CONTACT_PERSON = 'user/getContactPerson',
	STORE_USER_LOCAL = 'user/storeUserLocal',
	CLEAR_LOCAL_USER = 'user/clearLocalUser',
	GET_USER_LOCAL = 'user/getUserLocal',
	RESET_PASSWORD = 'user/resetPassword',
	UPLOAD_PROFILE_PIC = 'user/uploadProfilePic'
}

export const enum UserMutations {
	SET_USER = 'SET_USER',
	SET_USERS = 'SET_USERS',
	SET_CONTACT_PERSON = 'SET_CONTACT_PERSON',
	SET_USER_OF_LOCAL = 'SET_USER_OF_LOCAL'
}
