import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "profile-duties"
}
const _hoisted_2 = {
  key: 0,
  class: "list-unstyled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOrEmpty = _resolveComponent("LoadingOrEmpty")!

  return (_ctx.employee)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.employee.tasks && _ctx.employee.tasks.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employee.tasks, (task) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: task.id
                }, _toDisplayString(task.name), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_LoadingOrEmpty, {
          message: 'No duties assigned to you',
          status: 'EMPTY'
        })
      ]))
    : _createCommentVNode("", true)
}